import React, { useCallback, useContext, useMemo } from 'react';
import useSWR from 'swr';
import { baseAuth } from 'logic/api';
import { Context as AuthContext } from 'data/authContext';
import { MINUTE } from 'logic/defaults';

const fetcher = endpoint => baseAuth.get(endpoint).then(res => res.data);

export const useTickets = ({page, event, search}) => {
  const { state: { selectedRestaurantId, selectedDate, selectedDateTo } } = useContext(AuthContext);

  const searchParams = new URLSearchParams ({
    date_from: selectedDate?.toISOString(),
    date_to: selectedDateTo?.toISOString(),
    page,
  });

  if (event) {
    searchParams.append('event', event.value);
  };

  if (search?.length) {
    search?.forEach(param => searchParams.append(param.attr.replace('#', ''), param.value))
  };

  const { data, isLoading, isValidating, mutate } = useSWR(
    `event/get_purchase_list/${selectedRestaurantId}?${searchParams.toString()}`,
    fetcher,
    {
      refreshInterval: 5 * MINUTE,
      refreshWhenHidden: false,
      revalidateOnFocus: true,
      revalidateOnMount: true,
    },
  );

  const getSuggestions = useCallback(async value => {
    if (!value) return [];

    try {
      const res = await baseAuth.get(
        `event/get_search_suggestions/${selectedRestaurantId}?value=${value}`
      );
  
      return Object.entries(res.data).map(([attr, values]) =>
        values.map(value => ({
          label: `${attr}: ${value}`,
          value,
          attr,
        }))
      ).flat();
    } catch (error) {
      console.log(`🐉 > file: functions.js:41 > getSuggestions > error:`, error);
      return []
    }
  }, [selectedRestaurantId]);

  const tableData = useMemo(() => {
    if (!data?.tickets_sold) return [];

    const result = {};
    const tickets = []
  
    const soldTicketsData = data.tickets_sold;
  
    soldTicketsData.tickets.forEach(ticket => {
      if (ticket.date && ticket.time) {
        const date = ticket.date;
        const dateInDatetime = new Date(date);
        const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayIndex = dateInDatetime.getDay();
        const time = ticket.time;
        const weekday = weekdays[dayIndex];
        const ticketName = ticket.ticket_name;
        const availableTickets = ticket.stock;  // 0 means not available, null means unlimited stock
        const eventTicketStockId = ticket.event_ticket_stock_id;
        const eventRecurringDateId = ticket.event_recurring_date_id;
        const eventDateId = ticket.event_date_id;
        const eventTicketId = ticket.event_ticket_id

        // Find if a row exists for the same date and time
        const existingRow = tickets.find(row => row.date === date && row.time === time);

        if (existingRow) {
          // If a row exists, find if there's already an entry for this eventTicket
          const eventTicketEntry = existingRow.ticket_type.find(entry => entry.ticketName === ticketName);

          if (eventTicketEntry) {
            // If the eventTicket entry exists, just increment the soldTickets
            eventTicketEntry.soldTickets += 1;
          } else {
            // If no entry exists for this eventTicket, add a new entry
            existingRow.ticket_type.push({
              ticketName,
              soldTickets: 1,
              availableTickets,
              eventTicketStockId,
              eventRecurringDateId,
              eventDateId,
              eventTicketId
            });
          }
        } else {
          // If no row exists for this date and time, create a new row with ticket_type entries
          tickets.push({
            date,
            weekday,
            time,
            ticket_type: [{
              ticketName,
              soldTickets: 1,
              availableTickets,
              eventTicketStockId,
              eventRecurringDateId,
              eventDateId,
              eventTicketId
            }]
          });
        }
      }
    });
    

    result["count"] = tickets.length || 0;
    result["total_pages"] = soldTicketsData.total_pages || 0;
    result["page"] = soldTicketsData.page || 1;
    result["tickets"] = tickets
  
    return result;
  }, [data]);

  return {
    eventTickets: data?.event_tickets || [],
    purchaseList: data?.purchase_list || [],
    soldTickets: tableData, 
    eventOptions: data?.event_list?.map(event => ({label: event.name, value: event.id})) || [],
    eventsList: data?.event_list,
    ticketsData: data?.tickets_data || {validated: 0, total: 0},
    ticketsStock: data?.tickets_stock || [],
    pages: data?.pages || {num_pages: 0, num_entries: 0},
    isLoading,
    isValidating,
    mutate,
    getSuggestions,
  };
};
