import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "common/Tooltip";
import { ToggleSwitch } from "common/ToggleSwitch";
import { Icon, IconType } from "common/Icon";
import { stringifySchedule } from "logic/functions";
import { parseSchedule } from "../ProductHighlightComponents/productHighlightFunctions";

export const getPosition = (custom_group, catalog=null) => {
  if (custom_group.restaurant) {
    return ["restaurant", "Inicio de la carta"]
  } else if (custom_group.l1) {
    let obj = catalog?.supergroups.find(s_g => s_g.id == custom_group.l1)
    let name = (catalog && obj) ? `Inicio de categoría principal (${obj.name ? obj.name : obj.tpv_name})` : "Inicio de categoría principal"
    return ["l1", name]
  } else if (custom_group.l2) {
    let obj = catalog?.category_groups.find(c_g => c_g.id == custom_group.l2)
    let name = (catalog && obj) ? `Inicio de categoría secundaria (${obj.name ? obj.name : obj.tpv_name})` : "Inicio de categoría secundaria"
    return ["l2", name]
  } else {
    let obj = catalog?.product_categories.find(p_c => p_c.id == custom_group.l3)
    let name = (catalog && obj) ? `Inicio de categoría de producto (${obj.name ? obj.name : obj.tpv_name})` : "Inicio de categoría de producto" 
    return ["l3", name]
  }
};

const ProductHighlightRow = ({ custom_group, isValidating, setCustomGroupToRemove, setCustomGroupToEdit, toggleActive }) => {
  const { t } = useTranslation();

  return (
    <>
      <td className='col_name' align="center">
        {custom_group.name}
      </td>
      <td className='col_content' align="center">
          {custom_group.custom_group_type === 'commercial' &&
            t('image')
          }
          {custom_group.custom_group_type !== 'commercial' &&
            `${custom_group.products_info.length} ${t('product', {count: custom_group.products_info.length})}`
          }
      </td>
      <td className='col_type' align="center">
        <span className={'type ' + custom_group.custom_group_type}>
          {t('custom_group_' + custom_group.custom_group_type)}
        </span>
      </td>
      <td className='col_schedules' align="center">
        {!custom_group.visibility &&
          <p>{t('not_visible')}</p>
        }
        {!!custom_group.visibility && !custom_group.visibility_schedules.length &&
          <p>{t('all_day')}</p>
        }
        {!!custom_group.visibility && custom_group.visibility_schedules.map(schedule =>
          <p key={schedule.schedule.id}>
            {stringifySchedule(parseSchedule(schedule), t)}
          </p>
        )}
      </td>
      <td className='col_status' align="center">
      <ToggleSwitch
        name='active_custom_group'
        checked={custom_group.active}
        onChange={() => toggleActive(custom_group)}
        loading={isValidating}
      />
      </td>
      <td className='col_icon' align="center">
        <Icon
          type={IconType.TRASH}
          size={20}
          onClick={() => setCustomGroupToRemove(custom_group)}
          disabled={isValidating}
        />
      </td>
      <td className='col_icon' align="center">
        <Icon
          type={IconType.PENCIL}
          size={20}
          onClick={() => setCustomGroupToEdit(custom_group)}
          disabled={isValidating}
        />
      </td>
      <td className='col_icon' align="center">
      {custom_group.products_warning &&
        <>
          <Icon type={IconType.WARNING_TRIANGLE} size={20}/>
          <Tooltip position="left">
            <p>
              Los productos seleccionados <b>no</b> son visibles.<br/>El destacado no se mostrará a los clientes.
            </p>
          </Tooltip>
        </>
      }
      </td>
    </>
  );
};

export default ProductHighlightRow