import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import { InputContainer } from './InputContainers';
import { getContrastRatio } from 'styles/colors';
import { InfoLabel } from 'components/yumminn/InfoLabel';

const HEX_COLOR_REGEX = /^#(([0-9a-fA-F]{2}){3})$/;

const MIN_CONTRAST = 4;
const RECOMMENDED_CONTRAST = 4.5;

const getContrastInfo = (color, color2) => {
  if (!HEX_COLOR_REGEX.test(color) || !HEX_COLOR_REGEX.test(color2)) {
    return null;
  };
  
  const contrast = getContrastRatio(color, color2);

  if (contrast >= RECOMMENDED_CONTRAST) {
    return {
      type: 'info',
      message: 'contrast_good',
      isVisible: true,
    };
  }

  if (contrast >= MIN_CONTRAST) {
    return {
      type: 'warning',
      message: 'contrast_minimum',
      isVisible: true,
    };
  }

  return {
    type: 'error',
    message: 'contrast_bad',
    isVisible: true,
  };
};

export const ColorInput = forwardRef(({ label, value, contrastColor, onChange, ...inputProps }, ref) => {
  const { t } = useTranslation();

  const contrastInfo = getContrastInfo(value, contrastColor);

  const parseColor = event => {
    let value = event.target.value.replaceAll(/[^#0-9a-fA-F]/g, '').toUpperCase();

    if (value && !value.startsWith('#')) {
      value = '#' + value
    }

    event.target.value = value;

    onChange?.(event);
  };

  return (
  <InputContainer>
    <label>
      {typeof label === 'string' ? <h6>{t(label)}</h6> : label}
      <div className='color_input_wrapper'>
        <div className='input_wrapper'>
          <input
            type='text'
            value={value?.toUpperCase()}
            placeholder='#000000'
            maxLength={7}
            onChange={parseColor}
            disabled={inputProps?.disabled}
            readOnly={inputProps?.readOnly}
            ref={ref}
          />
          <label className='color_swatch' style={{backgroundColor: value || '#000000'}}>
            <input
              type='color'
              defaultValue={value}
              {...inputProps}
              onInput={parseColor}
            />
          </label>
        </div>
        {contrastColor &&
          <InfoLabel {...contrastInfo}/>
        }
      </div>
    </label>
  </InputContainer>
  );
});
