import React, { useContext, useState } from "react";
import { PlusIcon } from '@heroicons/react/outline';
import { Draggable } from 'react-beautiful-dnd';
import { centsToAmountString } from "logic/functions";
import { useTranslation } from "react-i18next";
import { ObjectTypes, patchObject } from "../api-OrderAndPay";
import { Tooltip } from "common/Tooltip";
import { ToggleSwitch } from "common/ToggleSwitch";
import { CatalogDNDContext } from "data/catalogDnDContext";
import { BUTTON_SHAPES, BUTTON_SIZES, BUTTON_VARIANTS, Button } from "common/Button";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { DroppableModifierCategory } from "./DroppableModifierCategory";
import { Icon, IconType } from "common/Icon";
import { DroppableModifierList } from "./DroppableModifierList";

const DraggableProductRow = ({
  type,
  item,
  parentList,
  parentId,
  parentName,
  level,
  index,
  hasSiblings,
  isTpvYumminn,
  highlightedRow,
  editProduct,
  setSourceList,
  setModifierToEdit,
  setModifierCategoryToEdit,
  setModifierCategoryToDelete,
  setHighlightedRow,
  hasPricePerSector
}) => {
  const { state: { draggingItem } } = useContext(CatalogDNDContext);

  const { t } = useTranslation();

  const hasChildren = !!(item.modifiers || (item.modifier_category && item.modifier_category.modifiers) || item.modifier_categories_count > 0);
  const isCategory = type.includes('MODIFIER_CATEGORY');
  
  const elementId = `${type}-${item.id.toString()}`;
  const itemName = item.visible_name || item.name || item.tpv_name || '';
  const itemTpvName = (isCategory ? item.modifier_category__tpv_name : item.tpv_name) || '';

  const [showChildren, setShowChildren] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleChildren = (e) => {
    e.stopPropagation()
    setShowChildren(!showChildren)
  };

  const toggleVisibility = async () => {
    if (loading) return;

    const hasSchedules = item?.visibility_schedules?.length > 0;
    const newVisibility = item.visibility === 0 ? (hasSchedules ? 2 : 1) : 0;
    
    setLoading(true);
    
    const object = {
      id: item.id,
      visibility: newVisibility,
    };
    
    try {
      const res = await patchObject({ objectType: ObjectTypes[type], object, t: t });
      if (res.status === 200) {
        item.visibility = newVisibility;
      } else {
        throw new Error(`
          "toggleVisibility" exception:
          Item: ${JSON.stringify(item)}
          Response: ${JSON.stringify(res)}
        `)
      };
    } catch (error) {
      console.log("🚀 ~ file: DraggableProductRow.jsx:69 ~ toggleVisibility ~ error:", error)
    }
    setLoading(false);
  };

  const handleEdit = () => {
    if (type === 'PRODUCT') {
      editProduct(item);
    } else if (isCategory) {
      const parentName = type.split('_MODIFIER_CATEGORY')[0].toLowerCase();
      
      setModifierCategoryToEdit({
        id: item.id,
        [parentName]: parentId,
        modifier_category: item.modifier_category_id,
        min_selectable_products: item.min_selectable_products,
        max_selectable_products: item.max_selectable_products,
        individual_max_selectable_products: item.individual_max_selectable_products,
      });
    } else {
      setModifierToEdit(item);
    }
  };

  const handleRowClick = event => {
    event.preventDefault();
    setHighlightedRow(elementId);
  };

  const deleteCategory = () => setModifierCategoryToDelete({ type, id: item.id, name: itemName, parentName });

  return (
    <Draggable
      isDragDisabled={showChildren}
      draggableId={elementId}
      index={index}
    >
      {(provided, snapshot) => (
        <>
          <tr
            {...provided.draggableProps}
            id={elementId}
            ref={provided.innerRef}
            className={`${type} ${snapshot.isDragging ? 'dragging' : ''} ${highlightedRow === elementId ? 'highlighted' : ''}`}
            onMouseMove={() => highlightedRow !== elementId && snapshot.isDragging && setHighlightedRow(elementId)}
            onClick={handleRowClick}
          >
            <td
              className={`col_drag ${(hasSiblings && showChildren) ? 'disabled' : ''}`}
              style={{paddingLeft: (level * 1.5) + 'rem'}}
              onMouseEnter={() => !draggingItem && setSourceList(parentList)}
              {...(hasSiblings ? provided.dragHandleProps : {})}
            >
              {hasSiblings &&
                <Icon type={IconType.DRAG}/>
              }
            </td>
            <td className={`col_arrow ${showChildren ? 'open' : ''}`}>
              {hasChildren &&
                <Icon type={IconType.CHEVRON_DOWN} onClick={toggleChildren} size={12}/>
              }
            </td>
            <td
              onClick={handleEdit}
              className={`col_name ${snapshot.isDragging ? 'whitespace-nowrap p-2' : ''}`}
            >
              <span>
                {item?.visibility === 2 &&
                  <Icon type={IconType.CLOCK}/>
                }
                {itemName}
                {itemTpvName &&
                  <Tooltip>
                    <span>{`${t('tpv_name')}: `}<b>{itemTpvName}</b></span>
                  </Tooltip>
                }
              </span>
            </td>
            <td className="col_visibility">
              {'visibility' in item &&
                <ToggleSwitch
                  name={`item_${item.id}_visibility`}
                  checked={item.visibility !== 0}
                  disabled={loading}
                  onChange={(e) => toggleVisibility(e)}
                />
              }
            </td>
            <td className="col_photo">
              {!isCategory &&
                <Button
                  leftIcon={!item.image && <PlusCircleIcon/>}
                  label={!item.image ? t("add_image") : t("see_image")}
                  variant={BUTTON_VARIANTS[!item.image ? 'PRIMARY' : 'SECONDARY']}
                  size={BUTTON_SIZES.SM}
                  onClick={handleEdit}
                />
              }
            </td>
            <td className="col_modifiers">
              <div className={item.modifier_categories_count ? 'font-semibold' : ''}>
                {level < 4 && !isCategory && (isTpvYumminn || !!item.modifier_categories_count) &&
                  <>
                    <span>({item.modifier_categories_count || 0})</span>
                    {isTpvYumminn &&
                      <Button
                        leftIcon={<PlusIcon/>}
                        shape={BUTTON_SHAPES.CIRCLE}
                        size={BUTTON_SIZES.SM}
                        onClick={() => setModifierCategoryToEdit({[type.toLowerCase()]: item.id})}
                      />
                    }
                  </>
                }
              </div>
            </td>
            {!isCategory &&
              <td className={`col_price ${hasPricePerSector ? 'hasPricePerSector' : ''}`}>
                {item.hasOwnProperty('modifiers_of_modifiers') ? "" : !item.hasOwnProperty('max_selectable_products') ? centsToAmountString(parseFloat(item.price_cents)) + ' €' : ""}
              </td>
            }
            {isCategory &&
              <td className="col_delete">
                {isTpvYumminn &&
                  <Icon type={IconType.CLOSE} onClick={deleteCategory} size={16}/>
                }
              </td>
            }
            <td className="col_edit">
              <Icon type={IconType.PENCIL} onClick={handleEdit} size={18}/>
            </td>
          </tr>
          {showChildren && !isCategory && item.modifier_categories_count &&
            <DroppableModifierCategory
              parent={item}
              parentType={type}
              parentName={itemName}
              level={level + 1}
              highlightedRow={highlightedRow}
              isTpvYumminn={isTpvYumminn}
              setSourceList={setSourceList}
              setModifierToEdit={setModifierToEdit}
              setModifierCategoryToEdit={setModifierCategoryToEdit}
              setModifierCategoryToDelete={setModifierCategoryToDelete}
              setHighlightedRow={setHighlightedRow}
            />
          }
          {showChildren && isCategory &&
            <DroppableModifierList
              parent={item}
              parentName={itemName}
              level={level + 1}
              highlightedRow={highlightedRow}
              isTpvYumminn={isTpvYumminn}
              setSourceList={setSourceList}
              setModifierToEdit={setModifierToEdit}
              setModifierCategoryToEdit={setModifierCategoryToEdit}
              setModifierCategoryToDelete={setModifierCategoryToDelete}
              setHighlightedRow={setHighlightedRow}
            />
          }
        </>
      )}
    </Draggable>
  );
};

export default DraggableProductRow