import React from 'react';
import { useTranslation } from 'react-i18next';
import './../../styles/animations.css';

const icon = {
  info: '✔️',
  warning: '⚠️',
  error: '❌',
};

const color = {
  info: '#E1F3EC',
  warning: '#FFF3D1',
  error: '#FCEBE7',
};

export const InfoLabel = ({type, message, isVisible}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex items-center gap-1 text-xs text-[#404040] rounded py-1 px-2 transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'} ${type === 'error' && isVisible ? 'shake' : ''}`}
      style={{backgroundColor: color[type]}}
    >
      {typeof message === 'string' ?
        icon[type] + ' ' + t(message)
      :
        <>
          {icon[type]}
          {message}
        </>
      }
    </div>
  );
};