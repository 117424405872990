import styled from "styled-components";

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #FFFFFF;
  border: 1px solid #40404026;
  border-radius: 1rem;
  gap: 16px;
  padding: 24px;
  margin-bottom: 32px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const TableContainer = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #40404026;
  border-radius: 1rem;
  padding: 8px;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 400;
  }

  & > div:has(table) {
    border: 1px solid #40404026;
    border-radius: 1rem;
    padding: 8px;
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-spacing: 0 8px;
    border-collapse: separate;
    margin: -8px 0;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th {
    background-color: #FCFCFC;
    border-bottom: 1px solid #40404026;
    height: 4.125rem;
    padding: 0 0.5rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.3rem;
    
    &:first-child {
      border-top-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
    }
  }

  tr {
    height: 3.875rem;
    background-color: var(--bg_color);

    &.dragging, &.highlighted {
      outline: 1px solid #094553;
      border-radius: 0.5rem;

      td {
        background-color: var(--bg_color);
      }
    }
    
    &.dragging {
      display: table;
      overflow: hidden;
      background-color: var(--bg_color);
      width: 100%;
      box-shadow: 0.5rem 0.5rem 0.5rem #09455320;
    }

    &:last-child {
      td:first-child {
        border-bottom-left-radius: 0.5rem;
      }

      td:last-child {
        border-bottom-right-radius: 0.5rem;
      }
    }
  }
  
  td {
    height: 100%;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
    line-height: 1.3;
    
    &:not(:has(table)) {
      background-color: var(--bg_color);
      padding: 0 0.5rem;

      & > * {
        margin: auto;
        width: fit-content;
      }
    }
  }

  .PRODUCT {
    --bg_color: #FCFCFC;

    &.dragging, &.highlighted {
      --bg_color: #F7FFFC;
    }
  }

  .PRODUCT_MODIFIER_CATEGORY, .MODIFIER_MODIFIER_CATEGORY {
    --bg_color: #F5F5F5;
  }

  .MODIFIER {
    --bg_color: #F8F8F8;
  }

  .col_drag {
    padding-right: 0.25rem;
    width: 1.5rem;
    transition: opacity 0.15s ease-in-out;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.25;
    }
  }
  
  .col_arrow {
    padding-left: 0.25rem;
    width: 1.5rem;
    
    button {
      transition: rotate 0.15s ease-in-out;
    }

    &.open > button{
      rotate: -180deg;
    }
  }

  .col_name {
    text-align: start;

    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 0;
    }
  }

  .col_visibility {
    width: 8rem;
  }

  .col_photo {
    width: 12rem;
    padding: 0 1rem;

    button {
      width: 100%;
    }
  }

  .col_modifiers {
    width: 10rem;

    div {
      display: flex;
      align-items: center;
      gap: 16px;
      width: min-content;
    }
  }

  .col_price {
    width: 8rem;
    text-align: end;
    padding-right: 32px;
  }

  .hasPricePerSector{
    padding-right: 60px;
  }

  .col_delete {
    width: 8rem;
    text-align: end;
  }

  .col_edit {
    width: 3rem;
    overflow: visible;
  }
`;