import React,{ useState } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { deleteEventOrTicket, ObjectTypes } from './EventApi';
import { Icon, IconType } from 'common/Icon';
import { ActiveSwitch } from "../../pages/admin/pages/OrderAndPay/ActiveSwitch";
import { saveEventOrTicket } from './EventApi';
import { cloneDeep } from 'lodash';
import { DeleteEventPopup } from './DeleteEventPopup';
import { NotRemovablePopup } from './NotRemovablePopup';
import { getImageUrl } from 'logic/functions';
import { InfoTooltip } from "common/InfoTooltip";
import { TABS } from "./CreateEvents";

export const EventRow = ({event, editEvent, onToggleOpen, open, setEventDraft, mutate, restaurant, setTab }) => {

  const { t } = useTranslation();
  const imageUrl = event.image;
  const [isLoading, setIsLoading] = useState(false)
  const [active, setActive] = useState(event?.active)
  const [deletePopup, setDeletePopup] = useState(false)
  const [notRemovableEventPopup, setNotRemovableEventPopup] = useState(false)
  const notRemovable = event.not_removable;

  const toggleActiveEvent = async state => {
    const newEvent = cloneDeep(event)

    if (event) {
      setActive(state)
    };
    newEvent.active = state

    setIsLoading(true);

    try {
      await saveEventOrTicket({
        restaurant: restaurant,
        eventOrTicket: 'EVENT',
        eventToEdit: newEvent,
        ticketToEdit: null,
        object: newEvent, 
        t: t
      })

      mutate();
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);

    } catch (error) {
      setIsLoading(false);
      mutate();
      console.log("🚀 toggleActiveEvent error:", error);
    } 
  
  };

  const handleEdit = () => {
    setTab(TABS[0])
    editEvent(event)
    setEventDraft(event)
  }

  const openImage = () => {
    if(imageUrl){
      window.open(getImageUrl(imageUrl), '_blank');
    }
  };

  const handleDelete = () => {
    if(notRemovable) {
      setNotRemovableEventPopup(true)
    } else {
        setDeletePopup(true);
    }
  }

  const confirmDelete = () => {
    deleteEventOrTicket({objectType: ObjectTypes['EVENT'], object: event, mutate, t:t})
    setDeletePopup(false)
  }

  const dateToShow = () => {
    const totalDates = event?.event_dates?.length + event?.recurring_event_dates?.length

    if(totalDates === 1){
      const datePart = event?.event_dates.length > 0 ? event?.event_dates[0]?.date : event?.recurring_event_dates[0]?.date;
      const timePart = event?.event_dates.length > 0 ? event?.event_dates[0]?.time : event?.recurring_event_dates[0].time;
      return (
        <span className='datetime'>
          <span style={{fontWeight: 600}}>{t('Date and hour')}:</span> 
          {` ${datePart} ${timePart}`}
        </span>
      )
    }
    
    let recurringAndNonRecurringDates = []
    
    if(event?.event_dates?.length > 0 && event?.recurring_event_dates?.length > 0){

      const filteredEventDates = event.event_dates.filter(
        (ev_date) => !event.recurring_event_dates.some((recurring_date) => recurring_date.event_date === ev_date.id)
      );

      recurringAndNonRecurringDates = [...filteredEventDates , ...event.recurring_event_dates];
    } else if(event?.event_dates.length > 0){
      recurringAndNonRecurringDates = [...event?.event_dates]
    } else {
      recurringAndNonRecurringDates = [...event?.recurring_event_dates]
    }

    const smallestEvent = recurringAndNonRecurringDates?.reduce((smallest, current) => {
      const currentDatePart = current?.date;
      const currentTimePart = current?.time;
  
      if (!currentDatePart || !currentTimePart) return smallest;
  
      const currentDate = new Date(`${currentDatePart} ${currentTimePart}`);
      const smallestDatePart = smallest?.date;
      const smallestTimePart = smallest?.time;
  
      const smallestDate = (smallestDatePart && smallestTimePart)
        ? new Date(`${smallestDatePart} ${smallestTimePart}`)
        : currentDate;
  
      return currentDate < smallestDate ? current : smallest;
    }, recurringAndNonRecurringDates[0]);
    
    const allDates = recurringAndNonRecurringDates.map(ev_date => `${ev_date?.date} ${ev_date?.time}`);
    
    return (
      <>
        <span style={{ marginRight: "5px" }} className='datetime'>
          <span style={{fontWeight: 600}}>{`${allDates.length} ${t('dates')} `}</span>
          {t('starting')} {`${smallestEvent?.date} ${smallestEvent?.time}`}
        </span>
        <InfoTooltip position='bottom'>
          <ul>
            {allDates.map((date, index) => 
              <li key={index} style={{ whiteSpace: 'nowrap' }}>{date}</li>
            )}
          </ul>
        </InfoTooltip>
      </>
    )
  }

  return (
    <tr className={`${open ? 'selected' : ''} ${!event?.active ? 'disabled' : ''}`} >
      <td onClick={onToggleOpen}>
        <span className='flex items-center gap-4'> 
          {open ? <ChevronUpIcon className='w-6 h-5 text-[#094553]'/> : <ChevronDownIcon className=' w-6 h-5 text-[#094553]' /> }
          <span 
            style={{overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} 
            className='px-2 py-0.5 min-w-20'
          >
            {event.name}
          </span>
        </span>
      </td>
      <td onClick={onToggleOpen}>
        <span>
        </span>
      </td>
      <td onClick={onToggleOpen}>
        {dateToShow()}
      </td>
      <td onClick={onToggleOpen}>
        <span className='flex justify-center items-center'>
          {imageUrl ? 
            <Button 
              size={BUTTON_SIZES.SM}
              variant={BUTTON_VARIANTS.SECONDARY}
              onClick={() => openImage()}
              label='see_image'
              className='px-2'
            />
            :
            <Button 
              size={BUTTON_SIZES.SM}
              variant={BUTTON_VARIANTS.PRIMARY}
              onClick={() => handleEdit()}
              label='add_image'
              leftIcon={<Icon type={IconType.ADD}/>}
            />
          }
        </span>
      </td>
      <td style={{ maxWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden' }} onClick={onToggleOpen}>
        <span style={{whiteSpace: 'nowrap', maxWidth: '150px', minWidth: '213px', textOverflow: 'ellipsis', overflow: 'hidden'}} className='address'>
          <span style={{fontWeight: 600}}>{t('Address')}:</span> {event.location}
        </span>
      </td>
      <td style={{width: "20px"}}> 
        <ActiveSwitch
          noMessage={true}
          noTitle={true}
          disabled={isLoading}
          loading={isLoading}
          checked={active}
          onChange={toggleActiveEvent}
        />
      </td>
      <td style={{width: "20px"}}>
        <TrashIcon
          onClick={() => handleDelete()}
          className={`w-[20px] ${notRemovable ? 'text-gray-300' : 'text-gray-700'} cursor-pointer transition-all animate-fade-in-display delay-75 `}
        />
      </td>
      <td style={{width: "20px"}}>
        <PencilIcon
          onClick={() => handleEdit()}
          className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
        />  
      </td>
      {!notRemovable && <DeleteEventPopup isOpen={deletePopup} confirm={confirmDelete} cancel={() => setDeletePopup(false)} type='EVENT'/>}
      {notRemovable && <NotRemovablePopup isOpen={notRemovableEventPopup} confirm={()=> setNotRemovableEventPopup(false)} type='EVENT'/>}
    </tr>
  );
};
