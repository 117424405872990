import { CheckIcon } from '@heroicons/react/outline';
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Checkbox = forwardRef(({ label, className = '', ...inputProps }, ref) => {
  const { t } = useTranslation();

  return (
    <Label className={className}>
      <div className='checkbox_wrapper'>
        <input
          type='checkbox'
          ref={ref}
          {...inputProps}
        />
        <CheckIcon height={18}/>
      </div>
      {label && typeof label === 'string' ? <h6>{t(label)}</h6> : label}
    </Label>
  );
});

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  
  .checkbox_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: #094553;
    background-color: #FFFFFF;
    border: 2px solid #626262;
    border-radius: 3px;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    transition: 0.15s ease;

    &::after {
      content: '';
      width: 0;
      height: 0;
      background-color: #094553;
      border-radius: 10px;
      transition: 0.3s ease-in-out;
    }

    &:has(:checked) {
      color: #FFF;

      &::after {
        width: 200%;
        height: 200%;
      }

      & > svg {
        opacity: 1;
      }
    }

    & > svg {
      position: absolute;
      opacity: 0;
      transition-property: color, opacity;
      transition: 0.15s ease;
    }
  
    & > input {
      opacity: 0;
      width: 0;
      position: absolute;
    }
  }

  &:hover:not(:has(:disabled)) .checkbox_wrapper {
    border-color: #6EB296;

    &:not(:has(:checked)) {
      svg {
        opacity: 0.25;
      }
    }
  }

  &:has(:focus-visible) .checkbox_wrapper {
    outline: 2px solid #094553;
    outline-offset: 2px;
  }

  &:has(:disabled) {
    opacity: 0.5;
    cursor: not-allowed;
  }

  h6 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    display: inline-block;

    &::first-letter {
      text-transform: capitalize;
    }
  }
`;