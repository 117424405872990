import React, { useState } from 'react';
import { CustomGroupCustomizationContainer } from './ProductHighlightContainers';
import { HalfMobileMockup } from 'components/previews/HalfMobileMockup';
import { CustomGroupPreview } from 'components/previews';
import { ColorInput, ImageInput, SelectInput, TextInput } from 'common/Input';
import { Trans, useTranslation } from 'react-i18next';


export const CustomGroupCustomization = ({ customGroupDraft, catalog, editCustomGroup }) => {
  const { t } = useTranslation();
  
  const isMulti = customGroupDraft.custom_group_type === 'multi';

  const [hasTwoColors, setHasTwoColors] = useState(true);

  const backgroundOptions = [
    { label: t('one_color'), value: false },
    { label: t('two_colors'), value: true }
  ];

  const primary_label = isMulti ? 'top_color' : 'main_color';
  const secondary_label = !hasTwoColors ? 'background_color' : isMulti ? 'bottom_color' : 'extra_color';

  const selectBackground = ({value}) => {
    setHasTwoColors(value);

    if (!value) editCustomGroup({ primary_color: customGroupDraft.secondary_color });
  }

  const editBackgroundColor = e => {
    const colors = { secondary_color: e.target.value};
    if (!hasTwoColors) colors.primary_color = e.target.value;
    
    editCustomGroup(colors);
  };

  return (
    <CustomGroupCustomizationContainer>
      <HalfMobileMockup>
        <CustomGroupPreview customGroupDraft={customGroupDraft} catalog={catalog}/>
      </HalfMobileMockup>
      <div>
        {isMulti &&
          <TextInput
            label='highlighted_title'
            placeholder='highlighted_title_placeholder'
            value={customGroupDraft.name}
            onChange={e => editCustomGroup({ name: e.target.value })}
            maxlength={50}
          />
        }
        <ColorInput
          label='title_color'
          value={customGroupDraft.title_color}
          onChange={e => editCustomGroup({ title_color: e.target.value})}
          contrastColor={customGroupDraft.primary_color}
        />
        <div className='background_colors'>
          <SelectInput
            label='background'
            value={backgroundOptions[hasTwoColors ? 1 : 0]}
            options={backgroundOptions}
            onChange={selectBackground}
          />
          {hasTwoColors &&
            <ColorInput
              label={primary_label}
              value={customGroupDraft.primary_color}
              onChange={e => editCustomGroup({ primary_color: e.target.value})}
            />
          }
          <ColorInput
            label={secondary_label}
            value={customGroupDraft.secondary_color}
            onChange={editBackgroundColor}
          />
        </div>
        <h6>
          <Trans
            i18nkey='default_product_image_title'
            defaults='¿Hay productos sin fotos? <b>Añade una imagen por defecto</b>'
            components={{ b: <b/> }}
          />
        </h6>
        <ImageInput
          image={customGroupDraft.default_product_image.url}
          onUpload={image => editCustomGroup({ default_product_image: image })}
          onDelete={() => editCustomGroup({ default_product_image: { url: null } })}
          info='image_info'
        />
        {!isMulti &&
        <ImageInput
          image={customGroupDraft.image.url}
          onUpload={image => editCustomGroup({ image: image })}
          onDelete={() => editCustomGroup({ image: { url: null } })}
          labels={['add_logo', 'change_logo']}
        />
        }
      </div>
    </CustomGroupCustomizationContainer>
  );
};
