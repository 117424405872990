import React from 'react';

export const FooterMockup = ({ theme }) => {
  return (
    <div style={{backgroundColor: theme?.header_bg || '#404040'}} className='h-3 w-full text-white flex justify-center items-center gap-1'>
      <span className='text-[5px] leading-[1em]'>Powered by</span>
      <svg width="34" height="7" viewBox="0 0 83 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.65516 16.7485H12.8424C14.8182 16.7485 16.4482 15.0888 16.4976 13.0769V3.72189C16.4976 1.65976 14.8676 0 12.8424 0H3.65516C1.63 0 0 1.65976 0 3.72189V13.0769C0 15.0888 1.63 16.7485 3.65516 16.7485ZM8.29794 4.42687C8.84353 4.42687 9.28582 3.99902 9.28582 3.47125C9.28582 2.94347 8.84353 2.51562 8.29794 2.51562C7.75235 2.51562 7.31006 2.94347 7.31006 3.47125C7.31006 3.99902 7.75235 4.42687 8.29794 4.42687ZM7.45825 14.4334C6.76674 14.6346 6.02583 14.2825 5.77886 13.6286L4.05007 8.54876C3.8031 7.89492 4.19825 7.19077 4.93916 6.93929C5.55504 6.76011 6.21011 7.01979 6.52051 7.54065V7.14171C6.52051 6.0855 7.36021 5.23047 8.39748 5.23047C9.43476 5.23047 10.2745 6.0855 10.2745 7.14171V7.51208C10.5585 7.01035 11.2007 6.76342 11.8053 6.93929C12.4968 7.19077 12.8425 7.89492 12.645 8.54876L10.9162 13.6286C10.7186 14.2825 9.97769 14.6346 9.28617 14.4334C9.21638 14.408 9.1496 14.378 9.0862 14.3439C8.86988 14.4348 8.63658 14.4849 8.39748 14.4849C8.13997 14.4849 7.89465 14.4322 7.67132 14.3368C7.60399 14.3739 7.53285 14.4063 7.45825 14.4334ZM12.843 5.18119C12.843 5.5423 12.5334 5.83504 12.1515 5.83504C11.7696 5.83504 11.46 5.5423 11.46 5.18119C11.46 4.82008 11.7696 4.52734 12.1515 4.52734C12.5334 4.52734 12.843 4.82008 12.843 5.18119ZM4.59337 5.83504C4.97529 5.83504 5.28489 5.5423 5.28489 5.18119C5.28489 4.82008 4.97529 4.52734 4.59337 4.52734C4.21146 4.52734 3.90186 4.82008 3.90186 5.18119C3.90186 5.5423 4.21146 5.83504 4.59337 5.83504Z" fill="currentColor"/>
        <path d="M23.3638 16.0946V14.8372C23.3638 14.4349 23.5614 12.2722 22.8699 12.071C22.6723 12.0207 22.2278 11.7692 22.0302 11.568C21.635 11.2662 21.3387 10.9142 21.1411 10.4615C20.9435 10.0088 20.8447 9.55618 20.8447 9.05322V5.78399C20.8447 5.28103 21.2399 4.87866 21.7338 4.87866H21.882C22.3759 4.87866 22.7711 5.28103 22.7711 5.78399V9.05322C22.7711 9.35499 22.8205 9.60647 22.9687 9.85795C23.1169 10.1094 23.3144 10.3106 23.512 10.4112C23.759 10.5621 24.0059 10.6124 24.3023 10.6124C24.5987 10.6124 24.8456 10.5621 25.0926 10.4112C25.3396 10.2603 25.5372 10.0591 25.6359 9.85795C25.7841 9.60647 25.8335 9.35499 25.8335 9.05322V5.78399C25.8335 5.28103 26.2287 4.87866 26.7226 4.87866H26.8708C27.3647 4.87866 27.7599 5.28103 27.7599 5.78399V9.05322C27.7599 9.55618 27.6611 10.0088 27.4635 10.4615C27.2659 10.9142 26.9696 11.2662 26.6238 11.568C26.3769 11.7692 26.0805 11.9201 25.7841 12.071C25.0432 12.3727 25.2408 14.4852 25.2408 14.8372V16.0946C25.2408 16.5976 24.8456 17 24.3517 17H24.2035C23.759 17 23.3638 16.5976 23.3638 16.0946Z" fill="currentColor"/>
        <path d="M32.5504 12.3225C31.8094 12.3225 31.2167 12.1716 30.6734 11.8698C30.13 11.568 29.7349 11.1656 29.4879 10.6627C29.1916 10.1597 29.0928 9.60648 29.0928 9.00293V5.78399C29.0928 5.23074 29.5373 4.82837 30.0313 4.82837C30.5746 4.82837 30.9697 5.28103 30.9697 5.78399V9.05322C30.9697 9.355 31.0191 9.60648 31.1673 9.85796C31.3155 10.1094 31.5131 10.3106 31.7107 10.4112C31.9576 10.5621 32.2046 10.6124 32.501 10.6124C32.7973 10.6124 33.0443 10.5621 33.2913 10.4112C33.5382 10.2603 33.7358 10.0591 33.8346 9.85796C33.9828 9.60648 34.0322 9.355 34.0322 9.05322V5.83429C34.0322 5.28103 34.4767 4.87866 34.9707 4.87866C35.514 4.87866 35.9091 5.33133 35.9091 5.83429V9.05322C35.9091 9.65677 35.761 10.21 35.514 10.713C35.2176 11.2159 34.8225 11.6183 34.3285 11.9201C33.884 12.1716 33.2913 12.3225 32.5504 12.3225Z" fill="currentColor"/>
        <path d="M38.2315 12.1213C37.6881 12.1213 37.293 11.6686 37.293 11.1657V7.79583C37.293 7.24258 37.4412 6.68932 37.6881 6.23666C37.9351 5.784 38.3302 5.38163 38.8736 5.07986C39.4169 4.77808 40.0096 4.67749 40.7012 4.67749C41.0469 4.67749 41.3433 4.72779 41.6396 4.77808C41.936 4.87867 42.183 4.97927 42.4793 5.13015C42.7263 5.28104 42.9733 5.48222 43.1215 5.68341C43.319 5.48222 43.566 5.28104 43.813 5.13015C44.0599 4.97927 44.3563 4.87867 44.6527 4.77808C44.949 4.67749 45.2948 4.67749 45.6406 4.67749C46.3815 4.67749 46.9742 4.82838 47.4681 5.07986C47.9621 5.38163 48.3572 5.7337 48.6536 6.23666C48.9006 6.68932 49.0487 7.24258 49.0487 7.79583V11.1657C49.0487 11.7189 48.6042 12.1213 48.1103 12.1213C47.5669 12.1213 47.1718 11.6686 47.1718 11.1657V7.79583C47.1718 7.54435 47.1224 7.29288 46.9742 7.09169C46.826 6.89051 46.6778 6.68932 46.4309 6.53844C46.1839 6.38755 45.9369 6.33725 45.69 6.33725C45.443 6.33725 45.1466 6.38755 44.949 6.53844C44.7021 6.68932 44.5539 6.84021 44.4057 7.09169C44.2575 7.29288 44.2081 7.54435 44.2081 7.79583V11.1657C44.2081 11.7189 43.7636 12.1213 43.2696 12.1213C42.7263 12.1213 42.3312 11.6686 42.3312 11.1657V7.79583C42.3312 7.54435 42.2818 7.29288 42.1336 7.09169C41.9854 6.89051 41.8372 6.68932 41.5902 6.53844C41.3433 6.38755 41.0963 6.33725 40.8493 6.33725C40.6024 6.33725 40.306 6.38755 40.1084 6.53844C39.8615 6.68932 39.7133 6.84021 39.5651 7.09169C39.4169 7.29288 39.3675 7.54435 39.3675 7.79583V11.1657C39.1699 11.7189 38.7254 12.1213 38.2315 12.1213Z" fill="currentColor"/>
        <path d="M51.2217 12.1213C50.6784 12.1213 50.2832 11.6686 50.2832 11.1657V7.79583C50.2832 7.24258 50.4314 6.68932 50.6784 6.23666C50.9253 5.784 51.3205 5.38163 51.8638 5.07986C52.4071 4.77808 52.9999 4.67749 53.6914 4.67749C54.0371 4.67749 54.3335 4.72779 54.6299 4.77808C54.9262 4.87867 55.1732 4.97927 55.4696 5.13015C55.7165 5.28104 55.9635 5.48222 56.1117 5.68341C56.3093 5.48222 56.5562 5.28104 56.8032 5.13015C57.0502 4.97927 57.3465 4.87867 57.6429 4.77808C57.9393 4.67749 58.285 4.67749 58.6308 4.67749C59.3717 4.67749 59.9644 4.82838 60.4584 5.07986C60.9523 5.38163 61.3475 5.7337 61.6438 6.23666C61.8908 6.68932 62.039 7.24258 62.039 7.79583V11.1657C62.039 11.7189 61.5944 12.1213 61.1005 12.1213C60.5572 12.1213 60.162 11.6686 60.162 11.1657V7.79583C60.162 7.54435 60.1126 7.29288 59.9644 7.09169C59.8162 6.89051 59.6681 6.68932 59.4211 6.53844C59.1741 6.38755 58.9272 6.33725 58.6802 6.33725C58.4332 6.33725 58.1369 6.38755 57.9393 6.53844C57.6923 6.68932 57.5441 6.84021 57.3959 7.09169C57.2478 7.29288 57.1984 7.54435 57.1984 7.79583V11.1657C57.1984 11.7189 56.7538 12.1213 56.2599 12.1213C55.7165 12.1213 55.3214 11.6686 55.3214 11.1657V7.79583C55.3214 7.54435 55.272 7.29288 55.1238 7.09169C54.9756 6.89051 54.8275 6.68932 54.5805 6.53844C54.3335 6.38755 54.0865 6.33725 53.8396 6.33725C53.5926 6.33725 53.2962 6.38755 53.0987 6.53844C52.8517 6.68932 52.7035 6.84021 52.5553 7.09169C52.4071 7.29288 52.3578 7.54435 52.3578 7.79583V11.1657C52.1602 11.7189 51.7156 12.1213 51.2217 12.1213Z" fill="currentColor"/>
        <path d="M63.4717 11.1656V5.43192C63.4717 5.13014 63.7187 4.87866 64.015 4.87866H64.8547C65.1511 4.87866 65.398 5.13014 65.398 5.43192V11.1656C65.398 11.7189 64.9535 12.1213 64.4596 12.1213C63.9162 12.1213 63.4717 11.7189 63.4717 11.1656Z" fill="currentColor"/>
        <path d="M67.8184 12.1213C67.275 12.1213 66.8799 11.6686 66.8799 11.1657V7.94672C66.8799 7.34317 67.0281 6.78992 67.275 6.28696C67.5714 5.784 67.9666 5.38164 68.4605 5.07986C69.0038 4.77809 69.5966 4.6272 70.3375 4.6272C71.0784 4.6272 71.7205 4.77809 72.2144 5.07986C72.7578 5.38164 73.1529 5.784 73.3999 6.28696C73.6963 6.78992 73.795 7.34317 73.795 7.94672V11.1657C73.795 11.7189 73.3505 12.1213 72.8566 12.1213C72.3132 12.1213 71.9181 11.6686 71.9181 11.1657V7.94672C71.9181 7.64495 71.8687 7.39347 71.7205 7.14199C71.5723 6.89051 71.3747 6.68933 71.1772 6.58874C70.9302 6.43785 70.6832 6.38755 70.3869 6.38755C70.0905 6.38755 69.8435 6.43785 69.5966 6.58874C69.3496 6.73962 69.152 6.94081 69.0532 7.14199C68.905 7.39347 68.8556 7.64495 68.8556 7.94672V11.1657C68.8063 11.7189 68.3617 12.1213 67.8184 12.1213Z" fill="currentColor"/>
        <path d="M76.0674 12.2219C75.5241 12.2219 75.1289 11.7692 75.1289 11.2662V8.04731C75.1289 7.44376 75.2771 6.89051 75.5241 6.38755C75.8204 5.88459 76.2156 5.48222 76.7095 5.18045C77.2528 4.87867 77.8456 4.72778 78.5865 4.72778C79.3274 4.72778 79.9695 4.87867 80.4635 5.18045C81.0068 5.48222 81.4019 5.88459 81.6489 6.38755C81.9453 6.89051 82.0441 7.44376 82.0441 8.04731V11.2662C82.0441 11.8195 81.5995 12.2219 81.1056 12.2219C80.5622 12.2219 80.1671 11.7692 80.1671 11.2662V8.04731C80.1671 7.74553 80.1177 7.49406 79.9695 7.24258C79.8213 6.9911 79.6238 6.78991 79.4262 6.68932C79.1792 6.53843 78.9322 6.48814 78.6359 6.48814C78.3395 6.48814 78.0925 6.53843 77.8456 6.68932C77.5986 6.84021 77.401 7.04139 77.3022 7.24258C77.1541 7.49406 77.1047 7.74553 77.1047 8.04731V11.2662C77.0059 11.7692 76.5613 12.2219 76.0674 12.2219Z" fill="currentColor"/>
        <path d="M64.4599 2.51479C64.1141 2.51479 63.8671 2.4142 63.6202 2.16272C63.3732 1.91124 63.2744 1.60946 63.2744 1.30769C63.2744 0.955615 63.3732 0.704136 63.6202 0.452657C63.8671 0.201178 64.1635 0.100586 64.4599 0.100586C64.8056 0.100586 65.0526 0.201178 65.2996 0.452657C65.5465 0.704136 65.6453 1.00591 65.6453 1.30769C65.6453 1.65976 65.5465 1.91124 65.2996 2.16272C65.0526 2.4142 64.7562 2.51479 64.4599 2.51479Z" fill="currentColor"/>
      </svg>
    </div>
  );
};
