import React, { useState, useEffect, useContext } from 'react';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { Popup } from 'common/Popup';
import { useTranslation } from 'react-i18next';
import { ButtonsFooter, ExplanationPricePerSector, ColumnContainer } from './styles/SectorsAndTablesContainers';
import { ActiveSwitch } from 'pages/admin/pages/OrderAndPay/ActiveSwitch';
import { apiAuth } from 'logic/api';
import { toast } from 'react-toastify';
import { Context } from 'data/authContext';
import { NumberInput } from 'common/Input';

const PricePerSectorPopup = ({open, close, sectorsAndPrice, handleSubmitSector}) => {

    const { t } = useTranslation();
    const {
		state: { selectedRestaurant, selectedRestaurantId },
        setSelectedRestaurant
	} = useContext(Context);
    const [loading, setLoading] = useState(false)
    const [useSectorPricing, setUseSectorPricing] = useState(); 
    const [sectors, setSectors] = useState(sectorsAndPrice);

    useEffect(() => {
        setSectors(sectorsAndPrice);
      }, [sectorsAndPrice]);

    useEffect(() => {
        if(selectedRestaurant){
            setUseSectorPricing(selectedRestaurant[0]?.use_sector_pricing); 
        }
    }, [selectedRestaurantId, selectedRestaurant])

    const handleSubmit = () => {
        // Call handleSubmitSector to update the sectors and prices
        handleSubmitSector(null, sectors);
        close(); // Close the popup after submission
    };

    const handlePercentageChange = (index, value) => {
        const updatedSectors = [...sectors];
        updatedSectors[index].fare_change_percentage = value;
        setSectors(updatedSectors);
    };

    const changeUsePricePerSector = async() => {
        setLoading(true)
        setUseSectorPricing((prevState) => !prevState)
        selectedRestaurant[0].use_sector_pricing = !useSectorPricing;
        setSelectedRestaurant(selectedRestaurant)
    
        apiAuth.patch(`/restaurants-admin-new/${selectedRestaurantId}`, {'use_sector_pricing': !useSectorPricing}).then(() => {
            toast.success(`${t('update_ok')}`);
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <Popup isOpen={open} close={close}>
            <ColumnContainer style={{alignItems: 'center'}}>
                <header>
                    <h3>{t('manage_price_per_sector')}</h3>
                    <ActiveSwitch
                        checked={useSectorPricing}
                        disabled={loading}
                        onChange={changeUsePricePerSector}
                        fontSize='1rem'
                    />
                </header>
                <hr></hr>
                <ExplanationPricePerSector>
                    {t('sector_price_explanation')}
                </ExplanationPricePerSector>
            </ColumnContainer>

            <div className='flex ml-8 text-[1.125rem]'>
                <ColumnContainer>
                    {t('Sector')}
                </ColumnContainer>

                <ColumnContainer>
                    {t('price')}
                </ColumnContainer>
            </div>

            <hr></hr>

            <div className='sectors-list'>
            {sectorsAndPrice && sectorsAndPrice.length > 0 && sectorsAndPrice.map((sector, index) => (
                <div key={index} className='flex ml-8 py-4 text-[1.125rem] items-center'>
                    <ColumnContainer>
                        {sector.name} 
                    </ColumnContainer>

                    <ColumnContainer>
                        <NumberInput
                            placeholder={'0.00'}
                            type='number'
                            value={sector.fare_change_percentage || 0.00}
                            min="0.00"
                            max="100"
                            onChange={(e) => handlePercentageChange(index, e.target.value)}
                            unit={'%'}
                        />
                    </ColumnContainer>
                </div>
            ))}
            </div>

            <ButtonsFooter>     
                <Button 
                    size={BUTTON_SIZES.LG}
                    variant={BUTTON_VARIANTS.SECONDARY}
                    onClick={close}
                    label='cancel'
                    className = 'mt-6'
                    disabled={loading}
                    loading={loading}
                />
                <Button 
                    size={BUTTON_SIZES.LG}
                    variant={BUTTON_VARIANTS.PRIMARY}
                    onClick={handleSubmit}
                    label='save'
                    className = 'mt-6 p-2'
                    disabled={loading}
                    loading={loading}
                />
            </ButtonsFooter>
        </Popup>
    )
}

export default PricePerSectorPopup