import React from 'react';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import SideBar from 'layouts/MenuContainer';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import MySolutionHeader from 'pages/my_solution/MySolutionHeader';

const MySolutionContainer = ({ children, title }) => {
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const path = window.location.pathname

  return (
    <div id="menuContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <SideBar style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        
        <MySolutionHeader title={title}/>
        <div className={` ${isRowBased && 'bg-white'}`} >
        {path && path !== "/admin/billing" && 
        <div className='bg-white py-4 px-[32px]'>
          <RestaurantSelect />
        </div>
        }
          <div className='px-[32px] mt-5'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MySolutionContainer;
