import React, { useState, useEffect, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import Spinner from "common/Spinner";
import { toast } from 'react-toastify';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { registerPlugin } from 'react-filepond';
import { Context as AuthContext } from '../../../data/authContext';
import { CreateEditGroupModal } from "./OrderAndPay/Modals/CreateEditGroupModal";
import { OPHeader } from "./OrderAndPay/OPHeader";
import UnavailableFunctionality from "common/UnavailableFunctionality";
import { useSynchronize } from "./OrderAndPay/Logic/useSynchronize";
import { CatalogDNDProvider } from "data/catalogDnDContext";
import { CatalogDragAndDrop } from "./DragAndDrop/CatalogDragAndDrop";
import { ConfirmSynchronizeModal } from "./OrderAndPay/Modals/ConfirmSynchronizeModal";
import { TPVS } from "./DragAndDrop/constants";
import EmptyState from '../../../common/EmptyState.jsx';
import { MenuEmptyStateIcon } from "common/EmptyStateIcons";
import RoundButton from './OrderAndPay/RoundButton';
import { useOrderAndPay } from "./useOrderAndPay";

registerPlugin(
	FilePondPluginFileEncode,
	FilePondPluginImagePreview,
	FilePondPluginFileValidateType
);

export const OrderAndPay = () => {
	const { state: { selectedRestaurantId, selectedRestaurant } } = useContext(AuthContext);
	const { t } = useTranslation();

	const restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'));
	const hasActiveOrderAndPayTab = restaurants.some(item => item.show_order_and_pay_tab_on_backoffice);

	const [createOrEdit, setCreateOrEdit] = useState(null);
	const [isSynchronizing, setIsSynchronizing] = useState(false);
	const [hoveringItem, setHoveringItem] = useState(null);
	const [confirmSynchronizeModal, setConfirmSynchronizeModal] = useState(false);
	const [sectorId, setSectorId] = useState(null);

	const {
		catalog,
		lastSynchro,
		tpv,
		isTspoonlab,
		childrenOptions,
		isValidating,
		mutate,
	} = useOrderAndPay({ selectedRestaurantId, sectorId });

	const synchronize = useSynchronize({ selectedRestaurantId, isSynchronizing, setIsSynchronizing, mutate });

	const closeModal = () => {
		setCreateOrEdit('');
	};

	useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'));
		sessionStorage.removeItem('lastHighlightedRow');

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true);
		};

		return () => sessionStorage.removeItem('lastHighlightedRow');
	}, [selectedRestaurantId]);

	useEffect(() => {
		if (hoveringItem != null) {
			const timer = setTimeout(() => {
				setHoveringItem(null);
			}, 500);
			return () => clearTimeout(timer);
		}
	}, [hoveringItem]);

	if (!hasActiveOrderAndPayTab) return <UnavailableFunctionality title={'Order_and_pay'}/>

	return (
		<>
			{(isSynchronizing || isValidating) &&
				<>
					<div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center">
						<Spinner>{t("Loading")}</Spinner>
					</div>
					<div className="bg-black opacity-20 top-0 left-0 fixed overflow-y-auto h-full w-full z-9999 flex flex-col justify-center items-center"/>
				</>
			}

			<div className="flex flex-col mb-40 w-full bg-gray-100">
				<OPHeader
					customCatalog={tpv.includes(TPVS.YUMMINN) || selectedRestaurant[0].custom_catalog}
					lastSynchro={lastSynchro}
					synchronize={() => setConfirmSynchronizeModal(true)}
					loading={isSynchronizing}
				/>
				{(!isSynchronizing && !isValidating) &&
					<div className="flex-auto p-6 w-full  ">
						{catalog &&
							<CatalogDNDProvider>
								<CatalogDragAndDrop
									catalog={catalog}
									categoriesOptions={childrenOptions.CATEGORY_GROUP}
									tpv={tpv}
									isTspoonlab={isTspoonlab}
									mutate={mutate}
									setCreateOrEdit={setCreateOrEdit}
									setSectorId={setSectorId}
									sectorId={sectorId}
								/>
							</CatalogDNDProvider>
						}

						{(!tpv.includes(TPVS.YUMMINN) && !selectedRestaurant[0].custom_catalog) && (!catalog || (!catalog?.supergroups?.length && !catalog?.product_categories?.length)) &&
							<div className=" bg-white rounded-xl pb-14 flex sm:flex-col font-semibold flex-col-reverse items-center justify-center ">
								<EmptyState 
									text={
											<Trans i18nKey={"click to sync message"}>
												<span style={{fontWeight: 600}}>Sincroniza</span> la carta <span style={{fontWeight: 600}}>en un solo click</span>
											</Trans>
										} 
									icon={<MenuEmptyStateIcon />} 
								/>
								<RoundButton padding="0 50px" onClick={() => setConfirmSynchronizeModal(true)} bgColor={'#5FB894'} textColor={'#FFFFFF'} text={t('synchronize')} />
							</div>
						}
					</div>
				}

				{/* Modals */}

				{createOrEdit &&
					<CreateEditGroupModal
						groupType={createOrEdit.type}
						groupToEdit={createOrEdit?.group}
						parentId={createOrEdit?.parentId}
						options={childrenOptions?.[createOrEdit.type]}
						mutate={mutate}
						closeModal={closeModal}
						t={t}
					/>
				}

				{confirmSynchronizeModal &&
					<ConfirmSynchronizeModal
						closeModal={() => setConfirmSynchronizeModal(false)}
						synchronize={() => {
							setConfirmSynchronizeModal(false);
							synchronize();
						}}
						t={t}
					/>
				}

			</div>
		</>
	);
};

export default OrderAndPay;