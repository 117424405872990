import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { FilePond } from 'react-filepond';
import { Icon, IconType } from 'common/Icon';
import { Button, BUTTON_VARIANTS } from 'common/Button';


export const RestaurantImageSettings = ({ homeImage, setHomeImage, photoOverlay, setPhotoOverlay }) => {
  const { t } = useTranslation();

  const togglePhotoOverlay = () => setPhotoOverlay(prev => !prev);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };


  const changeImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fd = new FormData();
      const url = file ? URL.createObjectURL(file) : null;
      file && fd.append("home_image", file);
      setHomeImage({file, fd, url});
    }
  };

  return (
    <div className='flex flex-col justify-center gap-6 my-5'>
      <div className='flex gap-4 items-center text'>
        <Button
          onClick={handleButtonClick}
          label={homeImage ? "swap_photo" : "add_photo"}
          variant={BUTTON_VARIANTS[homeImage ? "SECONDARY" : "PRIMARY"]}
        />
        {homeImage &&
          <>
            <Icon type={IconType.OPEN} onClick={() => window.open(homeImage.url,"_blank")} size={23}/>
            <Icon type={IconType.TRASH} onClick={() => setHomeImage(null)} size={23}/>
          </>
        }
      </div>

      {/* HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
      <input
        type="file"
        accept="image/*" // Limit to image files
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={changeImage}
      />
      <div className='flex items-center gap-3'>
            <h3 className='text-md'>
              <span className='font-semibold '>{t('overlay')}</span> {`(${t('optional')})`}
            </h3>
            <div className='flex items-center gap-3'>
            <Switch
              height={16}
              width={36}
              handleDiameter={20}
              offColor={'#DDD'}
              offHandleColor={'#CCC'}
              onColor={'#C7E6DA'}
              onHandleColor={'#5FB894'}
              activeBoxShadow={'0 0 0 3px #CFEADF'}
              onChange={togglePhotoOverlay}
              name='loyalty_program'
              checked={photoOverlay}
              uncheckedIcon={false}
              checkedIcon={false}
            />
            <span className='font-semibold text-sm text-principal'>
              {t(photoOverlay ? 'visible' : 'hidden')}
            </span>
          </div>
        </div>
    </div>
        
  );
};

