import React from 'react'
import { CustomGroupPreview, MobileMockup } from "components/previews";
import QRCode from 'react-qr-code';
import { CustomGroupFinishedContainer } from '../ProductHighlightComponents/ProductHighlightContainers';
import { Icon, IconType } from 'common/Icon';
import { Trans } from 'react-i18next';

export const CustomGroupFinishedSettings = ({catalog, customGroupDraft}) => {
  console.log(`🐉 > file: CustomGroupFinishedSettings.jsx:8 > CustomGroupFinishedSettings > customGroupDraft:`, customGroupDraft)
  return (
    <CustomGroupFinishedContainer>
      <MobileMockup>
        <CustomGroupPreview catalog={catalog} customGroupDraft={customGroupDraft}/>
      </MobileMockup>
      <div>
        <h3>
          <Icon type={IconType.VERIFIED}/>
          {customGroupDraft.name}
        </h3>
        <p>
          <Trans
            i18nKey='custom_group_finnished'
            defaults='Tu destacado ya está en la carta.<br/><b>Escanea y prueba</b> desde tu móvil.'
            components={{ b: <b/>, br: <br/> }}
          />
        </p>
        {customGroupDraft.test_table_url &&
          <QRCode
            size={200}
            value={customGroupDraft.test_table_url}
          />
        }
      </div>
    </CustomGroupFinishedContainer>
  )
}
