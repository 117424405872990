import React from 'react';
import { CustomGroupTextsContainer } from './ProductHighlightContainers';
import { HalfMobileMockup } from 'components/previews/HalfMobileMockup';
import { TextInput } from 'common/Input';
import { CustomGroupPreview } from 'components/previews';

export const CustomGroupTexts = ({customGroupDraft, catalog, editCustomGroup}) => {
  return (
    <CustomGroupTextsContainer>
      <HalfMobileMockup>
        <CustomGroupPreview customGroupDraft={customGroupDraft} catalog={catalog}/>
      </HalfMobileMockup>
      <div>
        <TextInput
          label='highlighted_tag'
          placeholder='highlighted_tag_placeholder'
          value={customGroupDraft.tag}
          onChange={e => editCustomGroup({ tag: e.target.value })}
          maxlength={50}
        />
        <TextInput
          label='highlighted_title'
          placeholder='highlighted_title_placeholder'
          value={customGroupDraft.name}
          onChange={e => editCustomGroup({ name: e.target.value })}
          maxlength={50}
        />
        <TextInput
          label='description'
          placeholder='highlighted_description_placeholder'
          value={customGroupDraft.description}
          onChange={e => editCustomGroup({ description: e.target.value })}
          maxlength={50}
        />
      </div>
    </CustomGroupTextsContainer>
  );
};
