import { ChevronRightIcon } from '@heroicons/react/outline';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RestaurantGroupCardPopup } from './RestaurantGroupCardPopup';


export const RestaurantGroupSettings = ({ restaurantGroupRestaurants, setRestaurantGroupRestaurants, theme }) => {
  const { t } = useTranslation();
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  return (
    <div className='flex flex-col gap-5 items-center my-5'>
      <div className='w-[90%] flex flex-col gap-5 items-center'>
        <h2 className={'text-lg font-semibold leading-tight'}>
          {t('edit_each_restaurant_card')}:
        </h2>
      </div>
      <div className='w-[90%] flex flex-col gap-5 items-center'>
      {restaurantGroupRestaurants && restaurantGroupRestaurants?.map((element, index) => (
        <div className='flex justify-between w-full border-2 border-gray-200 rounded-lg p-3 cursor-pointer' onClick={() => setSelectedRestaurant(element)}>
          <p className='text-[15px]'>{element.title ? element.title : element.restaurant.name}</p>
          <ChevronRightIcon height={20} color={'#404040'}/>
        </div>
      ))}
      </div>
      {selectedRestaurant &&
        <RestaurantGroupCardPopup
          restaurantGroupRestaurants={restaurantGroupRestaurants}
          setRestaurantGroupRestaurants={setRestaurantGroupRestaurants}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          theme={theme}
        />
      }
    </div>
  );
};
