import { TabSelector } from 'common/TabSelector';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { ChooseProductHihglightType } from './ChooseProductHihglightType';
import { CustomGroupPopup } from './ProductHighlightContainers';
import { ActiveSwitch } from '../OrderAndPay/ActiveSwitch';
import { CustomGroupPosition } from './CustomGroupPosition';
import { SameSizeButtonGroup } from 'common/SameSizeButtonGroup';
import { CustomGroupProducts } from './CustomGroupProducts';
import { CustomGroupTexts } from './CustomGroupTexts';
import { CustomGroupSchedules } from './CustomGroupSchedules';
import { CustomGroupCustomization } from './CustomGroupCustomization';
import { getContrastRatio } from 'styles/colors';
import { MIN_CONTRAST } from 'pages/settings/pages/customization/ColorSettings';
import { CustomGroupFinishedSettings } from '../OrderAndPay/CustomGroupFinishedSettings';

const TABS_BY_TYPE = {
  individual: ['position', 'product', 'title_and_description', 'schedules', 'customization'],
  multi: ['position', 'products', 'schedules', 'customization'],
  commercial: ['details', 'schedules'],
};

export const CustomGroupModal = ({ customGroup, catalog, isValidating, saveCustomGroup, toggleActive, close }) => {
  const { t } = useTranslation();
  
  const [customGroupDraft, setCustomGroupDraft] = useState({
    ...customGroup,
    image: { url: customGroup?.image },
    default_product_image: { url: customGroup?.default_product_image },
  });
  
  const tabs = useMemo(() =>
    TABS_BY_TYPE[customGroupDraft.custom_group_type] || ['type']
  , [customGroupDraft.custom_group_type]);
  
  const [tab, setTab] = useState(tabs[0]);
  const [showSave, setShowSave] = useState(!!customGroup.id);
  const [disableNext, setDisableNext] = useState(false);

  const editCustomGroup = partialCustomGroup => {
    setCustomGroupDraft(prev => ({...prev, ...partialCustomGroup}));
  };

  const back = () => {
    const index = tabs.indexOf(tab);
    let newTab = tabs?.[index - 1];

    if (!newTab && tab === 'preview') {
      newTab = tabs[tabs.length - 1];
    }

    if (!newTab && customGroupDraft.custom_group_type !== 'commercial') {
      newTab = 'type';
    }

    setTab(newTab);
  };

  const next = () => {
    const index = tabs.indexOf(tab);
    if (index + 1 >= tabs.length) return;
    setTab(tabs[index + 1]);
  };

  const isTabDisabled = tab => {
    if (['position', 'details'].includes(tab)) {
      const hasPosition = !!(
        customGroupDraft.restaurant ||
        customGroupDraft.l1 ||
        customGroupDraft.l2 ||
        customGroupDraft.l3
      );

      const hasTitle = !!(customGroupDraft.custom_group_type !== 'commercial' || customGroupDraft.name)
      const hasImage = !!(customGroupDraft.custom_group_type !== 'commercial' || customGroupDraft.image?.url)

      return !hasPosition || !hasTitle || !hasImage;
    }

    if (['product', 'products'].includes(tab)) {
      const minProducts = customGroupDraft.custom_group_type === 'multi' ? 3 : 1;
      const maxProducts = customGroupDraft.custom_group_type === 'multi' ? 15 : 1;
      const products = customGroupDraft.products_info?.length || 0;

      const hasProducts = (minProducts <= products) && (products <= maxProducts);

      return !hasProducts;
    }

    if (tab === 'title_and_description') {
      return !customGroupDraft.name;
    }

    if (tab === 'customization') {
      
      try {
        const hasTitle = !!(customGroupDraft.custom_group_type !== 'multi' || customGroupDraft.name);
        const hasContrast = getContrastRatio(customGroupDraft.primary_color, customGroupDraft.title_color) >= MIN_CONTRAST;

        return !hasTitle || !hasContrast;
      } catch {
        return true;
      }
    }
  };

  const disableTab = tab => {
    if (customGroupDraft.id) return false;

    const tabIndex = tabs.indexOf(tab);

    const prevTabs = tabs.slice(0, tabIndex);

    return [...prevTabs, tab].map(tab => isTabDisabled(tab)).some(disabled => disabled);
  };

  const save = async () => {
    try {
      const customGroup = await saveCustomGroup(customGroupDraft);
      editCustomGroup(customGroup);
      setTab('preview');
    } catch (error) {
      console.log(error);
    }
  };

  const toggle = async () => {
    const active = await toggleActive(customGroupDraft)
    editCustomGroup({active});
  };

  useEffect(() => {
    setDisableNext(isTabDisabled(tab));
    if (!['type', 'preview'].includes(tab) && tab === tabs[tabs.length - 1]) setShowSave(true);
  }, [customGroupDraft, tab]);

  if (tab === 'type') return (
    <ChooseProductHihglightType
      type={customGroupDraft.custom_group_type}
      editCustomGroup={editCustomGroup}
      next={next}
      close={close}
    />
  );

  return (
    <CustomGroupPopup isOpen>
      <header>
        <h2>{t(`${customGroup.id ? 'edit' : 'new'}_${customGroupDraft.custom_group_type}`) + ' ✨'}</h2>
        <ActiveSwitch
          checked={customGroupDraft.active}
          onChange={toggle}
          loading={isValidating}
        />
      </header>
      <TabSelector options={tabs} value={tab} onChange={setTab} disableTab={disableTab}/>
      {['position', 'details'].includes(tab) &&
        <CustomGroupPosition
          customGroupDraft={customGroupDraft}
          catalog={catalog}
          editCustomGroup={editCustomGroup}
        />
      }
      {['product', 'products'].includes(tab) &&
        <CustomGroupProducts
          customGroupDraft={customGroupDraft}
          catalog={catalog}
          editCustomGroup={editCustomGroup}
        />
      }
      {tab === 'title_and_description' &&
        <CustomGroupTexts
          customGroupDraft={customGroupDraft}
          catalog={catalog}
          editCustomGroup={editCustomGroup}
        />
      }
      {tab === 'schedules' &&
        <CustomGroupSchedules
          customGroupDraft={customGroupDraft}
          editCustomGroup={editCustomGroup}
        />
      }
      {tab === 'customization' &&
        <CustomGroupCustomization
          customGroupDraft={customGroupDraft}
          catalog={catalog}
          editCustomGroup={editCustomGroup}
        />
      }
      {tab === 'preview' &&
        <CustomGroupFinishedSettings
          customGroupDraft={customGroupDraft}
          catalog={catalog}
        />
      }
      <SameSizeButtonGroup as='footer'>
        {tab !== 'preview' &&
          <Button
            label='cancel'
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={close}
            disabled={isValidating}
          />
        }
        {tab !== 'details' &&
          <Button
            label='go_back'
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={back}
            disabled={isValidating}
          />
        }
        {tab !== 'preview' &&
          <Button
            label={showSave ? 'save' : 'next'}
            onClick={showSave ? save : next}
            disabled={disableNext}
            loading={isValidating}
          />
        }
        {tab === 'preview' &&
          <Button
            label='close'
            onClick={close}
            disabled={isValidating}
          />
        }
      </SameSizeButtonGroup>
    </CustomGroupPopup>
  );
};

