const info = {
  "info_new": "Utilisateurs enregistrés",
  "info_recurrent": "Utilisateurs enregistrés ayant effectué plus d'un paiement",
  "info_users_who_paid": "Utilisateurs enregistrés ayant effectué un ou plusieurs paiements",
  "info_payment_rates_per_user": "Pourcentage de paiement par utilisateur",
  "info_cashback_granted": "Total du cashback généré",
  "info_cashback_redeemed": "Cashback utilisé pour les paiements",
  "info_points_granted": "Total des points générés",
  "info_total_reviews": "Selon la période choisie",
  "info_average_stars": "Note moyenne de tous les restaurants",
  "info_today": "Total des paiements du jour",
  "info_last_week": "Total des paiements pour la semaine en cours",
  "info_last_month": "Total des paiements pour le mois en cours",
  "info_accumulated": "Total des paiements avec Yumminn",
  "info_period_payments": "Total des paiements de {{from}} à {{to}}. Conseils inclus.",
  "info_period_tips": "Total des pourboires de {{from}} à {{to}}",
  "info_period_ratings": "Notations de {{from}} à {{to}}}",
  "info_period_restaurants": "Restaurants de {{from}} à {{to}}",
  "info_period_loyalty": "Fidélité de {{from}} à {{to}}",
  "info_active_users": "Enregistrements des utilisateurs enregistrés qui ont payé",
  "info_restaurants_with_reviews": "Voir les détails dans la liste des restaurants ou dans la section des critiques",
  "info_restaurants_with_cashback": "Restaurants avec fidélité active",
  "info_avg_cashback_per_user": "Cashback moyen actuel par utilisateur",
  "info_avg_points_per_user": "Nombre moyen de points par utilisateur",
  "info_with_payments": "Restaurants ayant effectué des paiements par rapport à l'ensemble de la base de données",
  "info_payments_per_restaurant": "Paiements moyens par restaurant",
  "info_avg_payments_with_cashback": "Paiements moyens en cashback",
  "info_new_users": "Nouveaux utilisateurs",
  "info_payments_with_review": "Nombre d'avis après paiement",
  "info_registered_users": "Nouveaux utilisateurs enregistrés et récurrents",
  "info_restaurant_group_visibility": "Visibilité du restaurant sur l'écran d'accueil du groupe",
  "info_locker_counter": "Utilisez le comptoir comme alternative aux casiers si nécessaire (si vous n'avez pas de casiers disponibles ou si une commande ne rentre pas dans le casier). Le comptoir peut contenir autant de commandes que nécessaire.",
  "info_locker_active_disabled": "Vous ne pouvez pas désactiver un casier contenant des commandes.",
  "info_cross_sell_products": "Il doit y avoir autant de produits que possible, avec un minimum de 4 produits. Les 4 premiers produits seront affichés, à l'exclusion de la sélection du convive.",
  "info_default_language": "Nous utiliserons ce langage pour montrer la lettre quand il n'y a pas de traductions disponible dans d'autres langues.",
  "info_available_languages": "Les langues dont les utilisateurs disposeront-elles dans la webapp sont-elles disponibles.",
  info_kitchen_saturated: "Informez vos convives du retard ou désactivez temporairement les commandes à partir d'ici. <1>Plus d'infos dans ce tutoriel</1>.",
  info_kitchen_saturated_delay: "Fixer le délai : {{minutes}} minutes.",
  info_kitchen_saturated_pause: "Le client pourra consulter le menu, mais ne pourra pas passer de commande pendant ce délai.",
  info_pause_undefined: "Les commandes seront suspendues indéfiniment. Vous devrez les activer manuellement.",
  info_modifier_group: "<0>Créer des groupes</0> de modificateurs pour <2>organiser les options de vos produits</2>. Par exemple : <4>'Meat point'</4> ou <6>'Extras'</6>.",
  info_modifier_list: "Créez des modificateurs pour <1>personnaliser les produits</1> et <3>promouvoir les ventes additionnelles</3>.",
  "available_languages": "Langues disponibles",
  "available_languages_saved": "Langues disponibles mises à jour",
  "available_languages_error": "Erreur lors de la mise à jour des langues disponibles",
  "not_remove_default_language": "Il n'est pas possible de supprimer la langue par défaut",
  "ai_translation_error": "Algo ha ido mal mientras se realizaban las traducciones",
  "default_language_saved": "Idioma por defecto guardado",
  "ai_translation_error": "Quelque chose s'est mal passé pendant que je traduisais le menu",
  "default_language_saved": "Langue par défaut enregistrée",
};

export const frenchTranslationObject = {
  ...info,
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  Drinks: "Boissons",
  New_restaurant: "Nouveau restaurant",
  New_company: "Nouvelle Entreprise",
  id: "ID",
  Restaurant_Name: "Nom du restaurant",
  Company_Name: "Nom de l'entreprise",
  Adyen_account_holder_code: "Code du titulaire du compte Adyen",
  confirm: "Confirmer",
  cancel: "Annuler",
  Owner: "Propriétaire",
  Company: "Entreprise",
  registrarse_para_mas_info: " Inscrivez-vous pour plus d'informations ",
  vs: "Vs",
  optimice: "Optimiser",
  contact_us: "Contactez nous",
  borrar: "Supprimer",
  Country_code: "Code du pays",
  Currency_code: "Code de devise",
  are_you_sure: "Êtes-vous certain?",
  obigatorio: "Obligatoire",
  loading: " chargement en cours",
  quien_donde_buscas: "Ce que vous cherchez",
  buscar: "Chercher",
  proyecto: "Projet",
  proyectos: "Projects",
  saved: "Enregistrer",
  error: "Erreur",
  add_application: "Ajouter une application",
  name: "Nom",
  description: "Description",
  background_color: "Couleur de fond d’écran",
  icon: "Icône",
  drop_files_here_or: "Déposer un fichier ici ou",
  browse: "Parcourir",
  add_client: "Ajouter un client",
  nombre: "Nom",
  url: "url",
  logout: "Se déconnecter",
  add_partner: "Ajouter un partenaire",
  apellidos: "Prénom",
  email: "email",
  email_plural: "emails",
  company: "Organisation",
  Status: "Statut",
  status: "statut",
  partner: "Partenaire",
  client: "Client",
  application: "Application",
  template: "Modèle",
  add_template: "Ajouter un modèle",
  state: "Statut",
  add_new: "Ajouter nouveau",
  add_new_m: "Ajouter nouveau",
  add_new_f: "Ajouter nouvelle",
  view: "Vue",
  delete: "Supprimer",
  registro_ok: "Inscription validée",
  registro_error: "Erreur à l’inscription",
  add_user: "Ajouter un utilisateur",
  rol: "Rôle",
  home: "Accueil",
  registrate: "S’inscrire",
  login: "Login",
  dashboard: "Tableau",
  employees: "Employés",
  alerts: "Alertes",
  settings: "Paramètres",
  close_session: " Fermer la session",
  deleted: "Supprimer",
  create_new_application: "Créer une nouvelle application",
  create_new_partner: "Créer un nouveau partenaire",
  survey_creator: "Créateur de modèle",
  form: "Modèle",
  password: "Mot de passe",
  duplicated: "Dupliqué",
  create_new_response: "Crér un nouvel enregistrement",
  create_new_template: "Créer un nouveau modèle",
  table_results: "Liste des enregistrements",
  users_list: "Liste des utilisateurs",
  responses_list: "Liste",
  analytics: "Analyses",
  attachments: "Fichiers joints",
  template_designer: "Créateur",
  select_columns: "Sélectionner les colonnes",
  manage_users: "Gérer les utilisateurs",
  per_page: "par page",
  forgot_password: "I forgot my password :(",
  forgot_password_text: "Retrieve password",
  forgot_email_sent: "Check your email for instructions",
  forgot_password_explanation: "Enter the address used to register and, if it's in our system, we will send am email with the steps to follow.",
  send_reset_link: "Send reset link.",
  return_home: "Back to home.",
  no_email: "No email provided",
  reset_password: "Change password",
  new_password: "New password",
  change_password_text: "Choose your new password",
  no_password: "No password provided",
  update_password_error: "Cannot update password",
  update_password_ok: "Password changed. You can now login.",
  "Wrong password format": "Password must have 8 characters with upper and lower letters and numbers",
  password_conditions: "Le mot de passe doit comporter au moins 8 caractères avec des lettres minuscules, des lettres majuscules et des chiffres",
  Options: "Optiones",
  edit: "Modifier",
  Merchant_Account: "Compte merchant",
  Merchant_category_code: "Code de categorie merchant",
  restaurant_deleted: "Restaurant éliminé",
  company_deleted: "Entreprise éliminée",
  Adyen_config: "Configuration adyen",
  tpv_info: "tpv info",
  tpv: "Tpv",
  Dashboard: "Métriques",
  "Avg": "Moyenne",
  "Previous avg": "Moyenne précédente",
  "Previous period": "Période précédente",
  "Current period": "Période actuelle",
  "payment experience": "Expérience de paiement",
  save_group_first: "Enregistrez d'abord le groupe!",
  paid: "Payé",
  'days of the month': "Jours du mois en cours",
  "previous day": "jour d'avant",
  "day": "jour",
  "Months": "Mois",
  "Date": "Date",
  "Todos": "Tous",
  "Payments with reviews": "paiements avec avis",
  "Payments with tips": "paiements avec pourboire",
  "Is not admin or investor": "L'utilisateur n'est pas un administrateur ou un investisseur",
  "Invalid sent data": "Utilisateur ou mot de passe incorrect",
  Value: "Volume de paiement",
  "Start_Date": "Date de début",
  "End_Date:": "Date de fin",
  "Error_POS_explanation": "Le paiement a été effectué correctement <1>mais il n'a pas été envoyé au POS à cause d'une erreur</1>. Veuillez saisir le paiement manuellement au POS.",
  "Variation": "Variation",
  Avg_Ticket: "Ticket moyen",
  "Create new company": "Créer nouvelle entreprise",
  "Create new restaurant": "Créer nouveau restaurant",
  "Download logos": "Télécharger les logos",
  "Download logo": "Télécharger le logo",
  Last_30_days: "Dernières 30 jours",
  Last_30_days_mobile: "Der 30 jours",
  Avg_Rating_Mobile: "Note moy.",
  Avg_Rating: "Note moyenne",
  Last_7_days: "Dernières 7 jours",
  Last_7_days_mobile: "Der. 7 jours",
  "tips vs payments": "pourboire sur paiements",
  Restaurants: "Restaurants",
  Tips: "Pourboires",
  Payments: "Paiements",
  Yumminn_Payments: "Résumé de paiements",
  Today: "Aujourd'hui",
  "Data today": "Données quotidiennes",
  Add_new_menu: "Ajouter nouveau menu",
  Between: "De",
  and: "à",
  "Loyalty total granted": "Crédits de loyauté accordés",
  "Loyalty used": "Crédits de loyauté utilisés",
  "Loyalty unused": "Crédits de loyauté non utilisés",
  "loyalty program information": "Info programme de loyauté",
  "loyalty program status": "État du programme de loyauté",
  "Minimum to grant": "Minimum pour accorder des crédits",
  "Minimum to redeem": "Minimum pour utiliser des crédits",
  "Earning percentage": "Pourcentage pour gagner des crédits",
  "Loyalty program enabled": "Programme de loyauté activé",
  "Loyalty program disabled": "Programme de loyauté désactivé",
  "Reports email": "Rapports email",
  Transactions: "Transactions",
  "Search table": "Chercher table",
  "Search sector": "Chercher secteur",
  "No payments": "Aucune transaction trouvée pour cette recherche",
  Status: "Status",
  "Date and hour": "Date et l'heure",
  "Visibility schedule": "Horaire de visibilité",
  "Always visible": "Toujours visible",
  "Modified": "Modifié",
  "Active": "Actif",
  "Back": "Retourner",
  "No menus added yet": "Pas encore de menus ajoutés",
  "Internal_menus": "Menus internes",
  "External_menu": "Menu externe",
  "New_menu": "Nouveau menu",
  Refund: "Remboursement",
  Revenue: "Revenu",
  "excluding tips": "hors pourboires",
  "All day": "Toute la journée",
  to: "à",
  "Everyday": "Tous les jours",
  Delete_schedule: "Supprimer horaire",
  Delete_time_range: "Supprimer intervalle de temps",
  "Select days": "Selectionnez des jours",
  "Select time range": "Selectionnez des heures",
  "Type": "Type",
  "Add time range": "Ajouter intervalle de temps",
  "Add new schedule": "Ajouter un nouvel horaire",
  "Upload PDF file with menu": "Télécharger un pdf avec le menu",
  "Add a menu name": "Ajouter le nom du menu",
  Daily: "24 heures",
  Commission: "Commission",
  "About enable or disable": "Activer ou désactiver 'à propos'",
  "You cannot add a new menu schedule": "Vous ne pouvez pas ajouter un nouveau horaire à votre menu sans sans sélectionner au moins un jour dans l'horaire que vous êtes en train de modifier !",
  Loading: "Chargement...",
  About: "À propos de",
  Business_Name: "Raison sociale",
  "Loyalty": "Programme de loyauté",
  Ratings_and_reviews: "Reviews",
  Export_Csv: "Exporter CSV",
  Next: "Suivante",
  Previous: "Précédente",
  "Top_restaurants": "Top restaurants",
  "hours": "heures",
  "days": "jours",
  "weeks": "semaines",
  "months": "mois",
  "Days": "Jours",
  "Years": "Années",
  "avg_tips_explanation": "Il s'agit du nombre total de pronostics divisé par le nombre total de pronostics pour la période choisie.",
  "payments_explanation": "C'est la somme du montant des paiements incluant les pourboires pour la période choisie.",
  "avg_payments_explanation": "C'est la division de la somme totale de tous les paiements de la période choisie par le nombre total de jours de cette période.",
  'avg_ticket_explanation': "C'est la division de la somme totale de tous les paiements de la période choisie par la somme des transactions de cette période.",
  'tips_explanation': "Il s'agit de la somme du nombre total de pourboires pour la période choisie.",
  'transactions_explanation': "C'est la somme du nombre de transactions effectuées pendant la période choisie.",
  'avg_transactions_explanation': "C'est la somme du nombre de transactions effectuées pendant la période choisie divisées entre les jours de cette période. Si la période est 'Mois', c'est la moyenne par mois. Si la période est 'Années', c'est la moyenne par année. Si la période est '24 heures', c'est la moyenne par heure.",
  'reviews_explanation': "C'est la somme du nombre d'avis reçus pendant la période choisie.",
  'avg_reviews_explanation': "C'est la somme du nombre d'avis reçus pendant la période choisie divisée par les jours de cette période.",
  'avg_rating_reviews_explanation': "Il s'agit de la somme des notes reçues pendant la période choisie divisée par le nombre total de notes reçues pendant cette période.",
  'payments_with_reviews_explanation': "C'est le pourcentage de paiements sur le total de la période choisie avec laquelle ils ont laissé une évaluation (avis).",
  'payments_with_tips_explanation': "C'est le pourcentage de paiements sur le total des paiements de la période choisie qui incluent les pourboires.",
  'tips_vs_payments_explanation': "C'est le pourcentage d'un paiement qui correspond au pourboire.",
  'users_explanation': "Ceci est le nombre total d'utilisateurs enregistrés pendant la période choisie.",
  'email_users_explanation': "Il s'agit du nombre total d'utilisateurs enregistrés par e-mail pendant la période choisie.",
  'google_users_explanation': "C'est le nombre total d'utilisateurs enregistrés via google pendant la période choisie.",
  'facebook_users_explanation': "C'est le nombre total d'utilisateurs enregistrés via facebook pendant la période choisie.",
  "Facebook users": "Utilisateurs Facebook",
  "Email Users": "Utilisateurs email",
  "Google users": "Utilisateurs Google",
  Users: "Utilisateurs",
  "year": "Année",
  Total: "Total",
  "Table status": "État des tableaux",
  Product: "Produit",
  Products: "Produits",
  product_plural: "produits",
  "Total bill": "Facture totale",
  "Remaining": "Restante",
  "Scan qr": "Scannez le code QR pour accéder au flux du tableau depuis votre mobile.",
  "pos error": "Non enregistré dans le PDV",
  "has paid": "payé",
  "no tables": "Aucune table avec ce statut",
  "Reports": "Rapports",
  Monday: "Lundi",
  Tuesday: "Mardi",
  Wednesday: "Mercredi",
  Thursday: "Jeudi",
  Friday: "Vendredi",
  Saturday: "Samedi",
  Sunday: "Dimanche",
  Crm: "CRM",
  "surname": "Surname",
  "phone": "Phone",
  "date register": "Date registered",
  "last visited": "Last visited",
  "accumulated tips": "Accumulated tips",
  "generated loyalty": "Generated loyalty",
  "used loyalty": "Loyalty used",
  "loyalty left": "Loyalty left",
  "total": "Total",
  Companies: "Entreprises",
  "Payments with client": "paiements avec utilisateur",
  "Payments with review": "paiements avec examen",
  "payments_with_clients_explanation": "C'est le nombre total de paiements effectués avec un utilisateur enregistré et connecté",
  "clients_registered_after_payment": "utilisateurs enregistrés après le paiement",
  "clients_registered_after_payment_explanation": "C'est le pourcentage d'utilisateurs sur le nombre total d'utilisateurs enregistrés qui se sont inscrits juste après avoir effectué un paiement",
  "users_registered_by_restaurant": "utilisateurs enregistrés dans le restaurant",
  "users_registered_by_restaurant_explanation": "C'est le pourcentage d'utilisateurs enregistrés par restaurant par rapport au nombre total d'utilisateurs Yumminn",
  "Registered users": "Utilisateurs enregistrés",
  "users_with_loyalty": 'Utilisateurs fidèles',
  "loyalty_granted": 'Fidélité générée',
  "loyalty_used": 'Fidélité utilisée',
  "loyalty_used_explanation": "C'est le total des euros de fidélité utilisés.",
  "loyalty_granted_explanation": "C'est le total des euros de fidélité générés pour les utilisateurs.",
  "users_with_loyalty_explanation": "C'est le nombre total d'utilisateurs avec une fidélité accumulée.",
  "prev_used": "Période précédente consommée",
  "actual_used": "Utilisation réelle",
  "prev_granted": "Période précédente générée",
  "actual_granted": "Réel généré",
  Order_and_pay: "Commander et payer",
  Synchronize: "Synchroniser",
  Create_category: "Créer une catégorie",
  Price: "Prix",
  Photo: "Photo",
  swap_photo: "Échanger l'image",
  add_photo: "Ajouter une image",
  "visibility_schedule": "Visibility Schedule",
  "visible": "Visible",
  "not_visible": "not visible",
  Visibility: "Visibilité",
  'payments with reviews': "Paiements avec avis",
  'payments with user': "Paiements avec utilisateur",
  'Show all restaurants': 'Afficher tous les restaurants',
  "This restaurant has no data to collect yet": "Ce restaurant n'a pas encore de data à montrer",
  'assign': 'Attribuer',
  'assigned': 'Attribué',
  'confirm manager': 'Êtes-vous sûr de vouloir être le gestionnaire de compte de ce restaurant ?',
  'confirm change manager': 'Voulez-vous vous mettre en tant que "gestionnaire de compte" ?',
  'loyalty': 'Fidélité',
  'no restaurants with payments': 'Aucun restaurant avec paiement pendant cette période',
  "events": "Événements",
  "Create_event": "Créer un nouvel événement",
  "amount": "Montant",
  "event_date": "Date de l'événement",
  "No_events": "Aucun événement créé",
  "detail": "Détail",
  "created_event_date": "Date de création",
  "Status_payment": "Statut du paiement",
  "Hour": "Heure",
  "Concept": "Conception",
  "Occasion": "Occasion",
  "Pending": "En attente",
  "Confirm delete": "Confirmer la suppression de cet élément ?",
  "Modifier": "Modificateur",
  "Modifier Category": "Catégorie de Modificateur",
  Upload_Product_photos: "Télécharger photo du produit",
  "Suelta tu imagen aquí o haz clic para": "Déposez votre photo ici ou cliquez pour",
  "Prod_max": "Produits maximum",
  "Prod_min": "Produits minimum",
  "Optional": "Facultatif",
  "No Limit": "Pas de limite",
  "ultima sincronizacion": "Dernière synchronisation:",
  "Error_saving_product": "Une erreur s'est produite lors de l'enregistrement du produit",
  "Saved_product": "Produit enregistré",
  "empty_field": "Tous les champs obligatoires ne sont pas utilisés",
  "Error_deleting_time_range": "Il y a eu un problème lors de la suppression de la plage horaire",
  "Deleted_time_range": "Plage de temps supprimée",
  "Updated_time_range": "Plage de temps mise à jour",
  "Error_updating_time_range": "Il y a eu un problème lors de la mise à jour de la plage horaire",
  "Deleted_schedule": "Programme supprimé",
  "Error_deleting_schedule": "Il y a eu un problème lors de la suppression du planning",
  "saving": "Sauvegarde",
  "Updated_modifier": "Updated modifier",
  "Deleted_category": "Catégorie supprimé",
  "Edit_event": "Modifier l'événement",
  "check_link": "Vérifier le lien",
  Delete_main_image: "Supprimer la photo de la home",
  "Upload Business logo": "Télécharger logo",
  "refund_successful": "Paiement remboursé avec succès",
  "adyen_refund_payment except: Cannot refund payments after 1 hour of being done.": "Impossible de rembourser les paiements après une heure de travail",
  "Performance": "Performance",
  "Error_deploy_version": "Votre version de Back Office n'était pas à jour, réessayez!",
  "eggs": "Œufs",
  "lupin": "lupin",
  "gluten": "Gluten",
  "sesame": "Sésame",
  "mustard": "Moutarde",
  "fish": "Poisson",
  "celery": "Céleri",
  "crustaceans": "Crustacés",
  "peanuts": "cacahuètes",
  "molluscs": "mollusques",
  "soy": "Soja",
  "dried fruit": "Fruits secs",
  "sulphites": "Sulfites",
  "milk": "Laitier",
  "Top Waiters": "Top serveurs",
  "Waiter": "Serveur",
  "All": "Tous",
  Main_Image: "Photo de la home",
  Main_Image_OP: "Photo de la home de Order & Pay",
  Created_allergens: "Allergènes enregistrés",
  Error_creating_allergens: "Erreur en enrégistrant allergènes",
  Error_deleting_allergens: "Erreur d'élimination des allergènes",
  Deleted_allergens: "Allergènes eliminés",
  "Select one option": "Sélectionnez une option",
  "catalog": "Catalogue",
  modifier_name: "Nom du modificateur",
  Modifier: "Modificateur",
  modifier_description: "Description du modificateur",
  modifier_category_name: "Categorie du modificateur",
  product_name: "Nom du produit",
  product_description: "Description du produit",
  product_category_name: "Categorie du produit",
  category_group_name: "Categorie secondaire",
  supergroup_name: "Categorie principale",
  "With no restaurants selected you cannot continue to navigate, please select at least one restaurant": "La navigation n'est pas possible sans sélecctioner au moins un restaurant. S'il vous plaît, sélectionnez un restaurant",
  "click to sync message": "<0>Synchronisez</0> votre catalogue <2>en un seul clic</2>",
  "Edit_group": "Modifier le groupe",
  "Error_saving_image": "Erreur lors de l'enregistrement de l'image",
  "Updated_category": "Catégorie mise à jour",
  "Updated_group": "Groupe mis à jour",
  "Swap to visibility_schedule": "Confirmez si vous souhaitez changer la visibilité en 'Horaire de visibilité' pour utiliser vos horaires",
  "Created_time_range": "Création d'horaires",
  "Error_creating_time_range": "Erreur lors de la création de la plage horaire",
  "Updated_schedule": "Calendrier mis à jour",
  "Error_updating_schedule": "Erreur lors de la mise à jour de la plage horaire",
  translations: "Traductions",
  product: "Produit",
  received: "Reçu",
  languages: "langues",
  "spanish": "Espagnol",
  "english": "Anglais",
  "french": "Français",
  "add language": "Ajouter langue",
  "select language": "Choisir la langue",
  "yes": "Oui",
  "no": "Non",
  "Program info": "informations sur le programme",
  "Mail list": "Liste de diffusion",
  "New time range": "Nouveau fuseau horaire",
  "enabled": "activé",
  "disabled": "désactivé",
  "create": "créer",
  "Delete program": "Supprimer le programme",
  "% redeem": "pourcentage de crédits",
  "min to redeem": "Consommation minimale pour échanger du crédits",
  "min to earn": "Consommation minimale pour gagner du crédits",
  "days and hours": "Jours et heures",
  "Current": "Actuel",
  "total_payments": "Total des paiements",
  "payments": "paiements",
  "loyalty_payments": "Paiements de fidélisation",
  "percent_loyalty_payments": "% valeur de fidélité / total=%",
  "transactions": "transactions",
  "total_transactions": "Total des transactions",
  "loyalty_transactions": "Opérations de fidélisation",
  "percent_loyalty_transactions": "% valeur de fidélité / total=%",
  "transactions_with_loyalty_per_user": "# de transactions effectuées par des utilisateurs fidèles",
  "users_who_repeated": "# utilisateurs répétés",
  "avg_ticket": "Ticket moyen",
  "granted_credits": "Total du crédits accordé",
  "used_credits": "Crédits utilisé",
  "unused_credits": "Crédits non utilisé",
  "an_error_has_occurred": "Une erreur s'est produite",
  "show only unfinished translations": "Traductions inachevées",
  "no_translations_message": "Veuillez accéder au catalogue et enregistrer un nom ou une description pour les traduire",
  "show more": "Montrer plus",
  "tpv_name": "Nom au PDV",
  "tpv_description": "Description au PDV",
  "tpv_value_placeholder": "Non applicable - Catégorie créée dans Yumminn",
  "Customization": "Personnalisation",
  "button_color": "Couleur du bouton",
  "accent_color": "Couleur d'accentuation",
  "refunded": "Remboursé",
  "see here": "Voir ici",
  "Sector": "Secteur",
  "Method": "Méthode",
  "register payment on tpv": "Enregistrer le paiement sur le tpv",
  "pos": "système de point de vente",
  "payment charged": "Paiement effectué",
  "UE Commission": "Commission Européenne",
  "Not UE Commission": "Commission non Européenne",
  "latitude": "Latitude",
  "longitude": "Longitude",
  "invalid_value": "Valeur invalide",
  "logo_size_changed": "Taille du logo mise à jour",
  "color_changed": "Couleur mise à jour",
  "logo_size": "Taille relative du logo",
  "optional": "facultatif",
  "Orders activated": "Les produits Webapp sont disponibles pour les commandes au restaurant",
  "Orders deactivated": "Les produits ne sont pas disponibles à la commande par le restaurant, via la webapp",
  "Toggle orders with click": "{{toggle}} les commandes en un clic",
  "Activate": "Activer",
  "Deactivate": "Désactiver",
  "activate": "Activer",
  "deactivate": "désactiver",
  "Apple users": "utilisateurs Apple",
  "Enabled": "Actifs",
  "Disabled": "Desactivés",
  "Users with payments": "Utilisateurs avec paiements",
  prev_clients_who_repeated: "Récurrents antérieures",
  prev_clients_who_payed_just_once: "Nouveau",
  clients_who_repeated: "Récurrents",
  clients_who_payed_just_once: "Nouveau",
  clients_who_repeated_actual: "Récurrents",
  clients_who_repeated_prev: "Récurrents antérieures",
  clients_who_payed_just_once_prev: "Nouveau antérieures",
  hide_difference: "Cacher la différence",
  view_difference: "Afficher la différence",
  "Do_Refund": "S'il vous plaît, écrivez la quantité à rembourser",
  "Refunded amount": "Quantité remboursée",
  "partially_refunded": "Reemboursé partiellement",
  home_customization: "Personnalisation de la maison",
  preview: "Aperçu",
  logo: "Logo",
  size: "Taille",
  home_image: "Photo d'arrière-plan ou gif",
  overlay: "Ombre",
  color_button: "Couleur du bouton",
  color_secondary: "Couleur secondaire",
  color_cover: "Couleur de couverture",
  suggestion_logo: "Suggestions : En blanc et sans fond (png)",
  opcional: "optionnel",
  hidden: "Caché",
  cover: "Page d'accueil",
  changes_applied: "Changements appliqués",
  menu_button_label: "Voir la carte",
  pay_button_label: "Voir le compte et payer",
  table: "Tableau",
  menu: "Menu",
  payment: "Paiement",
  reward: "Récompense",
  contrast_good: "Contraste correct",
  contrast_minimum: "Contraste minimal",
  contrast_bad: "Contraste insuffisant",
  "average": "moyenne",
  "tips": "conseils",
  "users": "utilisateurs",
  "active_users": "utilisateurs actifs",
  "new": "Nouveau",
  "recurrent": "Utilisateurs récurrents",
  "no_recurrent": "Non-rappels",
  "users_who_paid": "Utilisateurs payés",
  "payment_rates_per_user": "Pourcentage de paiement avec utilisateur",
  "cashback_granted": "Cashback généré",
  "cashback_redeemed": "Cashback échangé",
  "points_granted": "Points générés",
  "total_reviews": "Nombre total d'avis",
  "num_reviews": "Nombre d'avis",
  "average_stars": "Moyenne dans les restaurants",
  "average_ticket": "Billet moyen",
  "average_tip": "Moyenne des pourboires",
  "accumulated": "Accumulé",
  "today": "Aujourd'hui",
  "last_week": "Semaine en cours",
  "last_month": "Mois en cours",
  "payments_period": "Paiements de la période",
  "total_period": "Total de la période",
  "last_period": "Dernière période",
  "show_percentages": "Voir pourcentages",
  "show_totals": "Voir totaux",
  "current_week": "<1>Semaine</1> en cours",
  "current_month": "<1>Mois</1> en cours",
  "current_year": "<1>Année</1> en cours",
  "total_week": "Total hebdomadaire",
  "total_month": "Total mensuel",
  "total_year": "Total annuel",
  "historic_total": "Total historique",
  "restaurants": "restaurants",
  "ratings": "classements",
  "without_payments": "Pas de paiement",
  "restaurants_with_reviews": "Restaurants dont l'évaluation est activée",
  "restaurants_with_cashback": "Restaurants avec cashback",
  "avg_cashback_per_user": "Cashback actuel moyen par utilisateur",
  "avg_points_per_user": "Nombre moyen de points par utilisateur",
  "info": "Info",
  "SUPER_GROUP": "Catégories principales",
  "CATEGORY_GROUP": "Catégories secondaires",
  "CATEGORY": "Catégories de produits",
  "Create_SUPER_GROUP": "Nouvelle catégorie principale",
  "Create_CATEGORY_GROUP": "Nouvelle catégorie secondaire",
  "Create_CATEGORY": "Nouvelle catégorie de produits",
  "Edit_SUPER_GROUP": "Modifier la catégorie principale",
  "Edit_CATEGORY_GROUP": "Modifier la catégorie secondaire",
  "Edit_CATEGORY": "Modifier la catégorie de produits",
  "Info_SUPER_GROUP": "Créez jusqu'à 3 catégories principales, pour faciliter la navigation du restaurant",
  "Info_CATEGORY_GROUP": "Créez des catégories secondaires, pour regrouper vos produits",
  "Info_CATEGORY": "Créez des catégories, pour regrouper vos produits",
  "name_SUPER_GROUP": "Nom de la catégorie principale",
  "name_CATEGORY_GROUP": "Nom de la catégorie secondaire",
  "name_CATEGORY": "Nom de la catégorie de produit",
  "name_schedule": "Nom de l'horaire",
  "select_children_SUPER_GROUP": "Sélectionner les catégories secondaires",
  "select_children_CATEGORY_GROUP": "Sélectionner les catégories de produits",
  "select_children_CATEGORY": "Sélectionner les produits",
  "web_preview": "Aperçu Web",
  "define_schedule": "Définir le calendrier",
  "every_day": "Tous les jours",
  "weekend": "Fins de semaine",
  "mon": "Lun",
  "tue": "Mar",
  "wed": "Mer",
  "thu": "Jeu",
  "fri": "Ven",
  "sat": "Sam",
  "sun": "Dim",
  "from": "de",
  "from_(short)": "de",
  "select_days": "Sélectionner les jours",
  "add_time_range": "Ajouter une autre plage horaire",
  "you_have_unsaved_changes": "Vous avez des modifications non enregistrées",
  "warning_message_unsaved_changes": "Si vous fermez cette fenêtre, vous perdrez vos modifications.",
  "keep_editing": "Poursuivre l'édition",
  "close": "Fermer",
  "group": "Groupe",
  "category": "Catégorie",
  "schedule": "Horaire",
  "specs": "Spécifications",
  "schedules": "Visibilité et heures d'ouverture",
  "explanation_schedules_active": "Tous les créneaux (jour/heure) de tous les horaires actifs seront <1>complétés</1>. Si vous souhaitez masquer un créneau, assurez-vous que ce créneau n'apparaît dans aucun horaire actif.",
  "explanation_schedules_inactive": "La catégorie <1>n'est pas visible</1> pour le moment. Pour la rendre visible à vos convives, activez-la en haut à droite de ce panneau.",
  "explanation_schedule_SUPER_GROUP": "<0>Par défaut</0>, cet horaire <2>s'appliquera à toutes les catégories d'enfants et de produits</2> au sein de cette catégorie principale. Vous pouvez modifier les <4>exceptions</4> en éditant la catégorie correspondante.",
  "explanation_schedule_CATEGORY_GROUP": "<0>Par défaut</0>, cet horaire <2>s'appliquera à toutes les catégories de produits</2> au sein de cette catégorie. Vous pouvez modifier les <4>exceptions</4> en éditant la catégorie correspondante.",
  "explanation_schedule_CATEGORY": "<0>Par défaut</0>, cette grille <2>s'appliquera à tous les produits</2> de cette catégorie. Vous pouvez modifier les <4>exceptions</4> en éditant le produit correspondant.",
  "explanation_disabled_select_SUPER_GROUP": "<0>Pour ajouter ou supprimer des catégories enfants</0> à cette catégorie principale, <2>traîner-les de l'aperçu</2> <4>du catalogue</4>.",
  "explanation_disabled_select_CATEGORY_GROUP": "<0>Pour ajouter ou supprimer des catégories de produits</0> à cette catégorie enfant, <2>traîner-les de l'aperçu</2> <4>du catalogue</4>.",
  "explanation_disabled_select_CATEGORY": "<0>Pour ajouter ou supprimer des produits</0> à cette catégorie, <2>traîner-les de l'aperçu</2> <4>du catalogue</4>.",
  "title_empty_schedules_active": "La catégorie sera <1>visible</1> pendant toute la durée de vos heures d'ouverture",
  "title_empty_schedules_inactive": "La catégorie <1>n'est pas visible</1> pour le moment",
  "explanation_empty_schedules_active": "Si vous souhaitez personnaliser les heures de visibilité, vous pouvez <1>définir un horaire ou désactiver la catégorie</1> en haut à droite.",
  "explanation_empty_schedules_inactive": "Pour la rendre visible à vos convives, <1>activez-la</1> en haut à droite de ce panneau.",
  total_clients_registered: "Total de clients enregistrés",
  avg_payments_with_cashback: 'Paiements moyens avec cashback',
  new_users: 'Nouveaux utilisateurs',
  payments_with_review: 'Paiements avec révision',
  registered_users: 'Utilisateurs enregistrés',
  tables_and_sectors: 'Tables et secteurs',
  categories_and_products : 'Catégories et produits',
  my_solution: 'Ma solution',
  metrics: 'Métriques',
  'You dont have this functionality message': "Améliorez <0>l'efficacité</0>, votre ticket moyen et l'expérience de vos convives avec la fonctionnalité <1>Commander</1> !",
  Contact_our_team: "Contactez notre équipe",
  'Payments and orders': 'Paiements et commandes',
  Restaurant: "Restaurant",
  synchronize: "Synchroniser",
  "Yes, synchronize": "Oui, synchroniser",
  "No, cancel": "Non, annuler",
  "confirm_synchronize_text_1": "Si tu as réalisé des modifications de produits et/ou catégories sur ton TPV depuis ta dernière syncrhonisation,",
  "confirm_synchronize_text_2": "ils seront appliqués sur ta carte Yumminn.",
  "confirm_synchronize_text_3": "On garde toujours les dernières modifications, qu'elles aient été réalisées sur le TPV ou sur Yumminn.",
  "Do you wish to continue?": "Tu souhaites continuer ?",
  Logout: "Sortir",
  QR_Codes: "Codes QR",
  Table_statuses: "États des tables",
  Orders: "Commandes",
  Shifts: "Changements",
  payments_per_restaurant: "Paiements par restaurant",
  with_payments: "Avec paiements",
  "current": "actuel",
  "previous": "précédent",
  "users_with_payments": "utilisateurs avec paiements",
  "granted": "généré",
  "redeemed": "racheté",
  customer_name: "Nom du client",
  email_address: "Email client",
  pay_with_qr: "Payer avec QR",
  Highlight_products: "Pleins feux sur les produits",
  discounts: "Réductions",
  promotions: "Promotions",
  coupons: "Coupons",
  add: "Ajouter",
  promo_explanation_title: "Comment fonctionnent les promotions?",
  discount_name: "Nom de la remise",
  typology: "Typologie de la remise",
  discount_value: "Valeur de la remise",
  applied_for: "Appliqué en",
  base_amount: "Montant de base",
  promos: "Promos/Crédits",
  coupon: "Coupon",
  total_base_amount: "Montant de base total",
  total_amount: "Volume des paiements",
  payment_amount: "Nombre de paiements",
  remove_category: "Supprimer catégorie",
  are_you_sure_to_remove: "Vous êtes sûrs que vous souhaitez supprimer cette catégorie ?",
  "no, cancel": "Non, annule",
  "yes, remove": "Oui, supprime",
  do_you_want_to_remove_this_category: "Souhaitez-vous supprimer cette catégorie ?",
  how_to_remove_category: "Afin de supprimer la catégorie, assurez-vous d'enléver vos produits à des autres catégories",
  alright: "D'accord",
  add_new: "Ajouter nouveau",
  see_image: "Voir image",
  Updates: "Nouvelles fonctionnalités",
  "your week in yumminn": "Votre <1>semaine</1> avec Yumminn",
  "maximize your solution": "<0>Maximisez</0> votre solution",
  see_all: "Voir toutes",
  time_saved_for_waiters: "Temps gagné pour les serveurs",
  time_saved_for_diners: "Temps gagné pour les convives",
  total_payments_of_the_last_30_days: "Total des paiements des derniers 30 jours",
  new_users_of_the_last_30_days: "Nouveaux utilisateurs des derniers 30 jours",
  new_reviews_of_the_last_30_days: "Nouveaux avis des derniers 30 jours",
  general_metrics: "Métriques générales",
  product_metrics: "Métriques de produit",
  edit_menu: "Éditer le menu",
  see_dashboard: "Voir métriques générales",
  last_30_days_with_yumminn: "Derniers 30 jours avec votre solution Yumminn",
  products_list: "Liste des produits",
  add_new_product: "Ajouter un nouveau produit",
  title_empty_category: "Cette catégorie est vide",
  message_empty_category: "Créer un nouveau produit ou l'ajouter à partir d'autres catégories",
  Create_product: "Créer un produit",
  Deleted_supergroup: "Catégorie principale supprimée",
  Deleted_group: "Catégorie secondaire supprimée",
  Deleted_category: "Catégorie de produit supprimée",
  Deleted_product: "Produit supprimé",
  Deleted_modifier: "Modificateur supprimé",
  remove_product: "Supprimer le produit",
  are_you_sure_to_remove_product: "Êtes-vous sûr de vouloir supprimer ce produit?",
  info_visibility_switch_disabled: "Les catégories vides n'apparaissent pas dans le menu",
  remove_category_not_allowed: "Il n'est pas possible de supprimer cette catégorie",
  title_empty_menu: "Ce menu est vide",
  message_empty_menu: "Commencez par créer une catégorie principale pour faciliter la navigation du client.",
  creating_product_title: "Créer un produit",
  creating_product_message: "Bientôt, vos clients apprécieront cette nouvelle proposition gastronomique",
  theme: 'Thème',
  light: 'Clair',
  red: 'Rouge',
  dark: 'Foncé',
  blue: 'Bleu',
  blue_linear: 'Bleu linéaire',
  superdark: 'Noir',
  superdark_linear: 'Noir linéaire',
  your_logo: 'Votre logo',
  copy_button_color: 'Copier la couleur des boutons',
  creating_catalog_title: 'Création du catalogue',
  creating_catalog_message: 'Nous créons les premières catégories pour vos produits',
  no_orders_yet: "Vous n'avez pas toujours de commandes",
  create_discounts_in_your_menu: "Créez des réductions sur votre menu !",
  content: "Contenu",
  highlight_name: "Nom du produit vedette",
  create_new_featured_product: "Créer nouveau produit vedette",
  create_advertising_banner: "Créer bannière",
  increase_your_average_ticket: "<0>Incrémentez votre ticket medium<1>en mettant en avant</1></0> vous produits !",
  example_minimum_redeem_amount: "Exemple : 25 €",
  minimum_redeem_amount: "Montant minimum pour appliquer réduction",
  cancel_booking: "Annuler la réservation",
  cancel_booking_confirmation: "Êtes-vous sûr de vouloir annuler cette réservation ?",
  cancel_booking_message: "Si vous souhaitez informer le client de la raison de l'annulation, <1> tapez-la ici</1> et nous l'ajouterons à l'e-mail d'annulation que nous vous enverrons.",
  cancel_reason: "Motif d'annulation",
  no_keep: "Non, conserver",
  yes_cancel: "Oui, annuler",
  Order_scheduling: "Planification des commandes",
  diners_amount: "Quantité de convives",
  date: "date",
  hour: "heure",
  compulsory_fields: "Champs obligatoires",
  compulsory_name_error: "S'il vous plaît, introduisez un nom",
  compulsory_email_error: "S'il vous plaît, introduisez un email valid",
  compulsory_date_error: "S'il vous plaît, selectionnez une date valide",
  compulsory_hour_error: "S'il vous plaît, choissisez une heure entre les options",
  compulsory_diners_error: "S'il vous plaît, selectionnez le numéro de convives",
  lockers: "casiers",
  add_locker: "Créer un casier",
  select_order: "Sélectionner une commande",
  create_locker: "Créer un casier",
  edit_locker: "Modifier le casier",
  locker: "Casier",
  counter: "Compteur",
  key: "Mot de passe",
  no_key: "Pas de clé",
  create_locker_error: "Une erreur s'est produite lors de la création du casier",
  edit_locker_error: "Une erreur s'est produite lors de la mise à jour du casier",
  delete_locker_error: "Une erreur s'est produite lors de la suppression du casier",
  order_locker_error: "Une erreur s'est produite lors de l'attribution de la commande",
  confirm_cancel_locker_title: "Voulez-vous annuler les modifications ?",
  changes_will_be_lost: "Vous perdrez toutes les modifications que vous avez apportées.",
  confirm_delete_locker_title: "Voulez-vous supprimer le casier ?",
  this_cannot_be_undone: "Cette action ne peut être annulée.",
  with_key: "Avec mot de passe",
  without_key: "Sans mot de passe",
  order_management: "Gestion des commandes",
  see_order_management: "Voir gestion des commandes",
  orders_in_lockers: "Commandes dans les casiers",
  orders_in_counter: "Commandes au comptoir",
  new_orders: "Nouvelles commandes",
  empty_lockers: "Casiers vides",
  active_lockers: "Casiers actifs",
  shift_time_left: "reste de l'équipe",
  orders_received: "Commandes reçues",
  orders_assignables: "Commandes assignables",
  orders_in_locker: "Commandes dans les casiers",
  assign_to_locker: "Affecter à un casier",
  see_and_manage: "Voir les détails et gérer",
  promised_delivery_time: "Livraison promise",
  mail: "Courrier",
  products: "Produits",
  comment: "Commentaire",
  no_comments: "Pas de commentaires",
  total_account: "Total du compte",
  no_active_shift: "Il n'y a actuellement aucune équipe active",
  manage_order_title: "Que voulez-vous faire<1/><2> avec la commande {{code}}</2> ?",
  confirm_pickup_title: "La commande précédente<1/><2>a-t-elle déjà été retirée</2> ?",
  select_an_option: "Sélectionnez une option",
  withdrawn: "Déjà retirée",
  to_locker: "Placer dans un casier",
  to_counter: "Apporter au {{counter_name}}",
  to_kitchen: "Retourner à la cuisine",
  remove_from_counter: "Retirer du {{counter_name}}",
  yes_withdrawn: "Oui, déjà retiré",
  no_to_counter: "Non, remettre au {{counter_name}}",
  no_to_kithcen: "Non, retour à la cuisine",
  send_reminder: "Envoyer un rappel au client",
  error_manage_order: "Une erreur s'est produite lors du traitement de la commande",
  clear: "vide",
  see_order: "voir la commande",
  sort_by: "commander par",
  newest: "Le plus récent",
  closer: "Le plus proche",
  farthest: "Le plus éloignée",
  oldest: "Le plus ancien",
  pickup_time: "heure d'enlèvement",
  all_slots: "Tous les emplacements",
  clear_locker: "Vider le casier",
  clear_all_lockers: "Vider tous les casiers",
  clear_all_lockers_title: "Vider tous les casiers",
  clear_all_lockers_message: "En continuant, vous allez <1>vider tous les casiers</1> et marquer toutes les commandes comme livrées. Êtes-vous sûr de vouloir continuer ?",
  replace_order: "Remplacer la commande",
  pickup_success_locker: "Vous avez terminé ! La commande a été retirée de l'armoire",
  pickup_success_counter: "Vous avez terminé ! La commande a été retirée du comptoir",
  move_order_success: "Vous avez terminé ! Des modifications ont été apportées au casier",
  move_to_locker_success: "Vous avez terminé ! La commande a été placée dans {{le casier}}",
  email_reminder_success: "C'est fait ! Un rappel a été envoyé au client",
  email_reminder_message: "Un courriel de rappel a été envoyé au client :",
  ok_close: "Ok, quitter",
  yes_continue: "Oui, continuer",
  no_cancel: "Non, annuler",
  cross_sell_title: "Mise en place de la vente croisée",
  select_products: "Sélectionner les produits",
  at_least_four_products: "⚠️ Sélectionnez au moins 4 produits",
  order_products_dnd: "✏️ Trier les produits par glisser-déposer",
  client_sees_products_before_payment: "Le client verra la vente croisée juste <1>avant de terminer son panier</1>.",
  see_in_app: "<0>Affichage</0> sur l'application web",
  cross_sell_no_products : "Aucun produit n'a été trouvé pour la vente croisée. <1/> Vous devez synchroniser le menu.",
  update: "Actualiser",
  tutorials: "tutoriels",
  connect_to_our_wifi: "Se connecter à notre wifi",
  copy: "Copier",
  text_copied: "Texte copié",
  wifi_pass_config_message: "Allez sur <1>⚙️ paramètres wifi</1> et collez le mot de passe pour vous connecter.",
  wifi_config_message: "Allez sur <1>⚙️ wifi settings</1> pour vous connecter à notre réseau.",
  wifi_name: "Nom du réseau",
  wifi_pass: "Mot de passe du réseau",
  wifi_name_placeholder: "Saisir le nom du réseau",
  wifi_pass_placeholder: "Saisir le mot de passe du réseau",
  wifi_preview_message: "Si le client a <1>une connexion faible</1>, il verra automatiquement les données wifi lorsqu'il scannera le QR.<3/><4/> En outre, il pourra y accéder manuellement à partir de l'icône en haut à gauche.",
  delete_wifi_title: "Voulez-vous supprimer le réseau wifi?",
  delete_wifi_message: "L'avertissement ne s'affiche pas lorsque la connexion est faible et l'icône permettant de visualiser le réseau n'apparaît pas.",
  wifi_save_success: "Réseau Wifi sauvegardé",
  wifi_save_error: "Une erreur s'est produite lors de la sauvegarde du réseau",
  credit_cents: "Réduction crédit",
  manage: "gérer",
  "kitchen_saturated?": "Cuisine saturée?",
  kitchen_saturated: "Cuisine saturée",
  select_and_save: "Sélectionner une option et sauvegarder",
  how_long_delay: "Combien de temps dure le retard ?",
  how_long_report: "Combien de temps allons-nous informer le client ?",
  how_long_pause: "Combien de temps souhaitez-vous suspendre les commandes ?",
  orders_paused: "Le client pourra consulter le menu, mais ne pourra pas passer de commande pendant cette période.",
  clients_will_be_inform_in_webapp: "Nous informerons le client par l'intermédiaire de l'application web",
  report_delay: "<0>Signaler</0> un retard",
  pause_orders: "<0>Suspendre</0> les commandes",
  minutes: "minutes",
  undefined: "indéfini",
  select_restaurant: "Sélectionnez un restaurant",
  active_saturation_restaurants: "<0>{{count}}</0> Restaurant avec <2>gestion active de la cuisine saturée</2>",
  active_saturation_restaurants_plural: "<0>{{count}}</0> Restaurants avec <2>gestion active de la cuisine saturée</2>",
  "Delivery and takeaway": "Delivery et takeaway",
  "ETA_explanation": "C'est le <1>délai d'attente</1> que <br>nous communiquerons à l'utilisateur. Il <br>comprend <3> la préparation de la commande et l'expédition</3>.",
  radius: "Radius",
  geojson: "Geojson",
  delivery_time: "Délai de livraison",
  "delivery area": "Zone d'expedition", 
  "shipping cost": "Frais de port",
  "delivery fee": "frais de livraison",
  minimum_shipping_cost: "Frais de port minimum",
  cost_per_km: "Frais par kilomètre",
  estimated_delivery_time: "Délai estimé de livraison",
  type_of_delivery_area: "Type de zone d'expedition",
  radius_in_meters: "Radius en mètres",
  upload_geojson: "Télécharger un fichier geojson",
  upload_file: "Télécharger fichier",
  map_linked_successfully: "Carte liée avec succès",
  modify_file: "Modifier fichier",
  how_to_create_geojson: "Comment créer un geojson ?",
  how_to_obtain_coordinates: "Comment obtenir les coordonnées de <br>l'adresse de mon restaurant ?",
  error_updating_delivery: "Erreur en actualisant les données de delivery",
  "takeaway": "Takeaway",
  delete_file: "Eliminer fichier",
  "No file selected": "Fichier pas seleccioné",
  "Invalid file format. Please select a .geojson file.": "Format de fichier invalide. Veuillez sélectionner un fichier .geojson.",
  saturation_updated: "Mise à jour du statut de la cuisine",
  saturation_error: "Une erreur s'est produite lors de la mise à jour du statut de la cuisine.",
  saturation_end_delay: "Retarde le message <1>désactivé</1><2> dans {{restaurant}}</2>",
  saturation_end_pause: "Remise en ordre <1>active</1><2> à {{restaurant}}</2>",
  time_finished: "Temps écoulé",
  Purchase: 'Achat',
  purchase: 'achat',
  purchase_plural: 'achats',
  ticket: 'ticket',
  ticket_plural: 'tickets',
  validated: 'Validé',
  validate: 'valider',
  cancel_validation: 'annuler la validation',
  event: 'Événement',
  filter_by_status: 'Filtrer par statut',
  tickets_purchased: '<0>{{count}} Ticket</0> achetée',
  tickets_purchased_plural: '<0>{{count}} Tickets</0> achetés',
  tickets_to_validate: 'Ticket à valider',
  tickets_to_validate_plural: 'Tickets à valider',
  validated_tickets: 'Ticket validé',
  validated_tickets_plural: 'Tickets validés',
  entry: 'entrée',
  entry_plural: 'entrées',
  error_updating_validation: "Une erreur s'est produite lors de la validation du ticket",
  searching: 'recherche',
  no_results: 'pas de résultats',
  mail: 'email',
  '#purchase': '#Achat',
  '#ticket': '#Ticket ',
  placeholder_search_tickets: '#Achat, #Ticket, Téléphone, Email, ou Nom',
  shop: 'commerce',
  no_tickets_yet: "<0>Vous n'avez pas de tickets</0> pour gérer",
  no_events_yet: "<0ous n'avez pas d'évenements/0> pour gérer",
  ticket_validation: "Validation de tickets",
  create_events: "Créer évenements",
  "name of the event": "Nom du évenement",
  "create_your_tickets": "Créez les tickets de votre évenement",
  add_ticket: "Ajouter ticket",
  create_event: "Créer évenement",
  photo_suggestions: "<0>Suggestions:</0> png/jpg ou gif de 2MB máx de poids.",
  "write here": "Écrivez ici",
  "translate full catalog": "Traduire tout le menu",
  "default language": "Langage par défaut",
  edit_event: "Éditer évenement",
  do_not_forget_to_add_tickets: "N'oubliez pas d'ajouter les tickets !",
  creating_event: "Création du évenement",
  create_ticket: "Créer ticket",
  explanation_ticket_image: "<0>Par défaut</0>, vous verrez <2>une icône du ticket</2>, mais vous pouvez ajouter une autre image ici.",
  suggestions_ticket: "<0>Suggestions</0>: couleur blanche et sans fonds (png) de 2MB máx.",
  "name of the ticket": "Nom du ticket",
  price: "Prix",
  event_created: "Évenement crée",
  ticket_created: "Ticket crée",
  event_updated: "Évenement actualisé",
  event_deleted: "Évenement supprimé",
  ticket_updated: "Ticket actualisé",
  ticket_deleted: "Ticket supprimé",
  'Error_creating_event': "Erreur en créant évenement",
  'Error_updating_event': "Erreur en actualisant évenement",
  'Error_deleting_event': "Erreur en supprimant évenement",
  'Error_saving_ticket': "Erreur en créant ticket",
  'Error_updating_ticket':"Erreur en actualisant ticket",
  'Error_deleting_ticket': "Erreur en supprimant ticket",
  drag_or_click_here: "Faites glisser ou clicker ici",
  to_upload_image: "pour télécharger una image",
  edit_ticket: "Modifier ticket",
  see_in_webapp: "Voir sur la webapp",
  yes_delete: "Oui,supprimer",
  delete_event: "Supprimer évenement",
  delete_event_question: "Êtes-vous sûr que vous souhaitez supprimer cet évenement ? ",
  delete_ticket: "Supprimer ticket",
  delete_ticket_question: "Êtes-vous sûr que vous souhaitez supprimer ce ticket ? ",
  you_cannot_remove_this_event: "Vous ne pouvez pas supprimer cet évenement",
  you_cannot_remove_this_ticket: "Vous ne pouvez pas supprimer ce ticket",
  not_deletable_event_explanation: "Des tickets ont déjà été vendus pour cet événement. Vous pouvez le désactiver afin qu'il ne s'affiche pas dans l'application web.",
  not_deletable_ticket_explanation: "Des tickets ont déjà été vendus. Vous pouvez le désactiver afin qu'il ne s'affiche pas dans l'application web..",
  german: 'Allemand',
  italian: 'Italien',
  portuguese: 'Portugais',
  download_qr: 'Télécharger QR',
  see_event_at_webapp: "Voir évenement sur la webapp",
  scan_or_copy_link: "Scannez ou copiez le lien",
  explanation_event_qr: "<0>Visualisez tous les événements créés</0> sur l'application Web en copiant le lien ou en scannant le QR.",
  create_your_first_event: "<0>Crée des nouveaux évenements</0> et vendez de tickets sur Yumminn.",
  creating_ticket: "Création du ticket",
  wait_some_seconds: "Attendez quelques seconds",
  desactivated_event: "Évenement désactivé", 
  desactivated_ticket: "Ticket désactivé", 
  not_visible_at_webapp: "Pas plus visible pour les utilisateurs.",
  allergens_and_tags: 'Allergènes et étiquettes',
  tags: 'Étiquettes',
  tags_explanation: "Apposez des étiquettes sur vos produits afin que vos convives puissent rapidement distinguer leurs préférences.",
  Vegetarian: "Végétarien",
  Vegan: "Végétalien",
  GlutenFree: "Sans gluten",
  Kosher: "Kascher",
  Halal: "Halal",
  Spicy1: "Épicé",
  Spicy2: "Épicé",
  Spicy3: "Épicé",
  no_description: "Pas de description",
  copied: 'Copié',
  "revision mode": "Mode révision",
  "confirm and update": "Confirmer et mettre à jour",
  "cancel all changes": "Annuler toutes les modifications",
  "auto_translation": "Traductions automatiques",
  "manual_translation": "Traductions manuelles",
  "remove_translations_in": "Supprimer les traductions en",
  "warning_message_remove_translations_in": "Êtes-vous sûr de vouloir supprimer les traductions du catalogue en",
  "translations done!": "Super! Vos traductions sont prêtes",
  "translations_done_message": "Vous pouvez les consulter et les ajuster manuellement. Vous devez confirmer les modifications afin qu'elles soient reflétées dans la webapp.",
  "translations_done": "traductions fait",
  "we_are_on_it_message": "On y est, attends quelques secondes...",
  "checking_translations_dimensions": "Nous calculons le nombre de traductions à faire...",
  "Modifier": "Modificateur",
  copied: "Copié",
  modifier_group: "Groupe de modificateurs",
  modifier_list: "Liste de modificateurs",
  add_modifier: "Ajouter un modificateur",
  understood: "Compris",
  delete_modifier_category: "Supprimer un groupe de modificateurs",
  delete_modifier_category_explanation: "Pour supprimer un groupe de modificateurs, vous devez d'abord supprimer ses modificateurs.",
  confirm_delete_modifier_category: "Êtes-vous sûr de vouloir supprimer ce groupe?",
  delete_modifier: "Supprimer le modificateur",
  confirm_delete_modifier: "Etes-vous sûr de vouloir supprimer ce modificateur?",
  modifiers_empty_state: "<0>Créer des modificateurs</0> et rendre vos produits personnalisables!",
  creating_modifier_title: "Création d'un modificateur",
  creating_modifier_message: "Bientôt, vos clients pourront profiter de produits entièrement personnalisés",
  product_modifiers: "Modificateurs du produit",
  copied: 'Copié',
  add_discount: "Ajouter réduction",
  what_type_of_discount_you_want_to_create: "Quel type de réduction souhaitez-vous créer ?",
  write_here_your_code: "Écrivez ici votre code",
  "discount in products": "Réduction sur produits",
  "discount in all menu (promotion)": "Réduction sur toute la carte (promotion)",
  coupon_discount: "Coupon de réduction",
  what_type_of_highlight_you_wanna_create: "Quel type de produit vedette vous souhaitez créer?",
  product_preview: "Preview d'un produit",
  create_discount_in_products: "Créer une réduction pour des produits",
  "select one or several products": "Selectionnez un ou plusieurs produits",
  select: "Selectionner",  
  discount_in_products: "Réduction sur products",
  product_discount: "Réduction sur produit/s",
  discount_already_applied: "Votre réduction a été déjà appliquée et vous pouvez le voir sur la carte!", 
  scan_the_qr: "Scannez le QR",
  products_and_specs: "Produits et especificátions", 
  "discountTitlePercentage": "-{{value}}% sur {{count}} produits",
  "discountTitleAmount": "-{{value}}€ sur {{count}} produits",
  "Applied in" : "Appliqué sur",
  delete_discount_question: "Êtes-vous sûr que vous souhaitez supprimer cette réduction?",
  delete_discount: "Supprimer réduction",
  general_discount_already_active_message: "Vous avez une réduction sur toute la carte (promotion). Désactivez cette réduction pour pouver créer une réduction sur quelques produits.",
  product_discount_already_active_message: "Vous avez une réduction sur produits active. Désactivez cette réduction pour pouver créer une réduction sur tous les produits de votre catalogue",
  not_possible_to_change_status: "C'est pas possible de changer de status",
  should_desactivate_product_discount: "Vous devez désactiver la réduction sur produits pour pouvoir activer cette promotion.",
  should_desactivate_general_discount: "Vous devez désactiver la réduction sur toute la carte pour pouvoir activer cette promotion.",
  discount_deleted: "Réduction supprimée",
  disactivated_discount: "Réduction désactivée",
  activated_discount: "Réduction activée",
  visible_at_webapp: "Visible pour les utilisateurs",
  "General discount": "Réduction sur toute le catalogue",
  wallet: "Portefeuille",
  topups: "Recharges",
  wallet_settings: "Paramètres du portefeuille",
  edit_amounts_clicking_on_inputs: "Éditez les montants en cliquant sur la case",
  general_incentive: "Incitation générale",
  incentive_explanation: "Le pourcentage d'incitation sera appliqué comme une <1>augmentation sur le montant de la recharge.</1>",
  incentive_example: "Exemple: <1>Si l'incitation est de 10%, une recharge de 10€ générera 1€ supplémentaire de crédits dans le portefeuille.</1>",
  first_recharge_example: "Ex: 10€",
  second_recharge_example: "Ex: 15€",
  third_recharge_example: "Ex: 20€",
  forth_recharge_example: "Ex: 25€",
  fifth_recharge_example: "Ex: 50€",
  sixth_recharge_example: "Ex: 100€",
  "topup_your_wallet": "Rechargez votre portefeuille",
  "top up your wallet in order to pay for products in this restaurant": "Rechargez votre portefeuille pour payer les produits de {{restaurantName}}",
  "and you will obtain": "et vous obtiendrez {{extraCredits}} extra",
  'of topup': "de recharge",
  topups_updated_successfully: "Actualisation du portefeuille",
  no_topups_yet: "Il n'y a pas encore de recharges",
  "Amount payed": "Quantité payée",
  "Amount topped up": "Quantité rechargée",
  "wallet_search_input_placeholder": "#ID",
  "of extra credits": "de crédits additionnels",
  "ID topup": "ID recharge",
  "recharge_ids": "ID recharge",
  "you cannot save amounts with a value of zero": "Vous ne pouvez pas gardez des montants avec 0€. Veuillez de les éditer.",
  add_image: "Ajouter image",
  change_image: "Modifier image",
  select_modifier_category: "Sélectionner un groupe",
  edit_modifier_category: "Paramètres du groupe de modificateurs",
  min_selectable_products: "Nombre minimum d'options à sélectionner",
  max_selectable_products: "Nombre maximum d'options à sélectionner",
  individual_max_selectable_products: "Limite de sélection par modificateur",
  "0_optional": "0 (optionnel)",
  no_limit: "Aucune limite",
  img_suggestions: "<0>Suggestions:</0> png/jpg ou gif 400x400 pixels, entre 1MB et 2MB.",
  remove_modifier_category: "Supprimer le groupe de modificateurs",
  confirm_remove_modifier_category: "Êtes-vous sûr de vouloir supprimer le groupe {{name}} de {{parentName}}?",
  custom_schedules: 'Horaires personnalisés',
  selected: 'Choisis',
  unselected: 'Non sélectionné',
  modifier_category_updaetd: 'Groupe de modificateurs mis à jour',
  modifier_category_deleted: 'Groupe de modificateurs supprimé',
  totalActiveCredits: "Total de credits actifs",
  totalCreditUsers: "Total de utilisateurs",
  totalCreditsUsed: "Total de credits utilisés", 
  totalInvestedInIncentives: "Total investisé en incentifs",
  yes_remove: 'Oui, supprimer',
  translation_progress_message: "Le processus prendra environ <1>{{minutes}}</1>. En attendant, vous pouvez continuer à travailler dans le Back Office.",
  go_home: "Allez pour home",
  cancel_process: "Annuler processus",
  no_users_with_credit_yet: "Vous n'aves pas encore d'utilisateurs avec credits",
  "wallet_users_search_input_placeholder": "Chercher par #email #ID",
  see_history: "Voir historique",
  get_out: "Sortir",
  history_of_recharges: "Historique de recharges",
  totalCreditUsersSubtitle: "avec crédite <1>actif</1>",
  totalActiveCreditsSubtitle: "Une moyenne de <1>{{average}}</1> par utilisateur",
  total_users_active: "clients actifs",
  total_incentive: "incentif total",
  total_credits: "crédits totals",
  visibility_and_schedules: "Visibilité et heures d'ouverture",
  use_limits: "Limite d'utilisation",
  all_day: "toute la journée",
  no_limit: "sans limite",
  use: "d'utilisation",
  use_plural: "utilisation",
  discount_uses: "utilisation à prix réduit",
  discount_uses_plural: "utilisation de l'escompte",
  discount_cents_redeemed: "utilisé dans cette remise",
  discount_cents_redeemed_plural: "utilisé dans cette remise",
  operative: "opérationnel",
  period_limit_reached: "Limite de période atteinte",
  uses_limit_reached: "Limite d'utilisation atteinte",
  cents_limit_reached: "Montant limite atteint",
  validity_limits: "Limites de validité",
  create_general_discount: "Créer une remise sur l'ensemble du menu",
  edit_general_discount: "Modifier la remise sur l'ensemble du menu",
  create_product_discount: "Créer une remise sur certains produits",
  edit_product_discount: "Modifier la remise sur certains produits",
  create_code_coupon: "Créer un coupon de réduction",
  edit_code_coupon: "Modifier le coupon de réduction",
  name_schedule_placeholder: "Par exemple : «Horaire hebdomadaire».",
  discount_updated: "Remise mise à jour",
  discount_created: "Remise créée",
  visualize_discount_message: "Votre {{type}} figure déjà dans le tableau. <1>Scanner ou copier-coller pour l'afficher</1>.",
  done: 'prêt',
  code: 'code',
  nutritional_table: "tableau nutritionnel",
  ingredients: "ingrédients",
  energy: "énergie",
  serving_size: "portion totale",
  fats: "graisses",
  carbohydrates: "hydrates de carbone",
  proteins: "protéines",
  saturated_fats: "graisses saturées",
  fiber: "fibres",
  sugars: "sucres",
  sodium: "sodium",
  product_details: "détails du produit",
  nutritional_info_explanation: "Placez une <1>valeur égale ou supérieure à 0 (zéro)</1> dans les cases pour rendre l'information visible. Les cases vides seront cachées dans l'application web.",
  not_specified: 'non spécifié',
  cash: 'espèces',
  pos_terminal: "terminal de paiement",
  access: "Accès webapp",
  access_management: "Gestion des accès",
  email_list: "liste de e-mails",
  domain_list: "liste de domaines",
  access_type: "type d'accès",
  open_access: "accès libre",
  login_required: "log in requise",
  restricted_access: "accès restreint",
  download_sheet: "télécharger modèle",
  write_to_search: "écrire pour rechercher",
  download_list: "télécharger liste",
  add_email: "ajouter e-mail",
  add_domain: "ajouter domaine",
  allowed_domains: "domaines activés",
  domain: "domaine",
  domain_plural: "domaines",
  created_at: "date de création",
  modified_at: "date de modification",
  access_email_placeholder: "Écrivez un e-mail ici",
  access_domain_placeholder: "Entrez un domaine ici",
  invalid_email: "Email invalide",
  invalid_domain: "Domaine invalide",
  search_by_email: "Recherche par mail",
  search_by_domain: "Recherche par domaine",
  open_access_active: "Accès ouvert actif",
  login_required_active: "Log in obligatoire active",
  open_access_message: "Si vous souhaitez <b>limiter l'accès</b> à la webapp, utilisez l'option <b>Log in requise ou Accès restreint</b>.",
  login_required_message: "Pour <b>restreindre l'accès à certains convives en particulier</b>, utilisez l'option <b>Accès restreint</b>. Si vous souhaitez <b>libérer l'accès</b>, utilisez l'option <b>Ouvrir l'accès</b>.",
  email_whitelist_empty_state: "Vous n'avez toujours pas d'e-mails sur la liste d'accès restreint",
  domain_whitelist_empty_state: "Vous n'avez pas encore de domaines répertoriés",
  open_access_tooltip:  "L'inscription <b>ne sera pas obligatoire</b> pour accéder à la webapp.",
  login_required_tooltip: "<b>L'inscription/log in sera obligatoire</b> pour accéder à la webapp.",
  restricted_access_tooltip: "Pour accéder à la webapp, le restaurant <b>doit être enregistré avec une adresse e-mail figurant sur la liste ou appartenant à un domaine activé</b>.",
  get_access_type_error: "Une erreur s'est produite lors du chargement du type d'accès.",
  update_access_type_error: "Une erreur s'est produite lors de la mise à jour du type d'accès.",
  access_management_email_error: "Une erreur s'est produite lors du chargement de la liste d'adresses électroniques.",
  access_management_domain_error: "Une erreur s'est produite lors du chargement de la liste des domaines.",
  update_whitelist_email_error: "Une erreur s'est produite lors de la mise à jour de la liste d'adresses électroniques.",
  update_whitelist_domain_error: "Une erreur s'est produite lors de la mise à jour du domaine",
  delete_whitelist_email_error: "Une erreur s'est produite lors de la suppression de l'email",
  delete_whitelist_domain_error: "Une erreur s'est produite lors de la suppression du domaine.",
  import_whitelist_error: "Une erreur s'est produite lors du téléchargement du fichier",
  export_whitelist_emails_error: "Une erreur s'est produite lors du téléchargement de la liste",
  info_access_import_file: 'Formats valables : CSV, XLSX.',
  email_added: "Email ajouté",
  domain_added: "Domino ajouté",
  subsidies: "subventions",
  preselected: "sélection par défaut",
  subsidy_email_placeholder: "Ecrire un mail ici",
  subsidies_empty_state: "Vous n'avez pas encore de mail dans la liste",
  subsidies_error: "Une erreur s'est produite lors du chargement de la liste de diffusion",
  subsidies_coupons_error: "Une erreur s'est produite lors du chargement de la liste des coupons",
  update_subsidy_error: "Une erreur s'est produite lors de la mise à jour de la liste de diffusion",
  delete_subsidy_error: "Une erreur s'est produite lors de la suppression d'un courrier",
  toggle_all_preselected_error: "Une erreur s'est produite lors de la mise à jour de la liste des coupons",
  import_subsidies_error: "Une erreur s'est produite lors de l'importation du fichier",
  export_subsidies_error: "Une erreur s'est produite lors de l'exportation de la liste",
  coupon_data: "Données sur les coupons",
  type: "type",
  discount_value: "valeur de la remise",
  select_coupon: "Sélectionnez un coupon.",
  select_coupon_message: "Créez d'autres coupons à partir de la section des remises.",
  manage_coupons: "gérer les coupons",
  delivery_fee: 'Delivery',
  gift: 'cadeau',
  "See orders" : 'Voir les commandes',
  "See order": 'Voir la commande',
  "Scan the order": "Scannez le QR sur le lecteur ou saisissez l'identifiant de la commande",
  "Get out of the order": 'Sortir de la commande',
  "Details": "Détails",
  "starting": "à partir du",
  dates: "dates",
  "timetable": "Heures",
  "selection": "Selection",
  "choose one or several dates": "Choississez une ou plusieurs dates",
  "you_have_not_selected_any_dates_yet": "Vous n'aves pas encore choississez aucune date",
  "when_sold_out_explanation": "Lorsqu'ils sont épuisés, le client sera informé via la webapp.",
  "amount of tickets available": "Quantité de tickets disponibles",
  "initial_stock_explanation": "Vous pouvez ajuster le nombre de tickets, mais le changement s'appliquera à toutes les dates de l'événement. Si des tickets ont déjà été vendus et que vous réduisez le stock, certaines dates pourraient dépasser le nombre configuré.",
  "not_repeated": "No se répète pas",
  "each_day": "Chaque jour",
  "every_month_the_date": "Chaque mois le {{date}}",
  "every_month_the_weekday": "Chaque semaine le {{weekday}}",
  "recurrence until": "Récurrence jusqu'au",
  "excluded_dates": "dates exclues",
  "excluded_date": "date exclue",
  "see": "Voir",
  "excluded_dates_explanation": "Excluez les dates que vous ne souhaitez pas dans les paramètres de récurrence en cliquant sur elles dans le calendrier. Pour les inclure à nouveau, ouvrez la liste des dates exclues et supprimez la date. ",
  tickets_sold: "Tickets vendus",
  available: "Disponibles",
  sold: "Épuissées",
  tickets: "Tickets",
  unlimited: "Ilimités",
  Ticket_stock_updated: "Stock du ticket actualisé",
  Error_updating_stock: "Error en actualisant stock",
  "Cannot delete event dates when there are associated ticket sales.": "Vous ne pouvez pas enlèver des dates car il y a des tickets déja vendus",
  "Cannot shorten recurrence end date": "C'est pas possible de changer la date de récurrence car il y a des tickets déja vendus",
  "Cannot exclude date with associated ticket sales.": "C'est pas possible d'excluire quelques dates car il y a déja des tickets vendus.",
  "here": "ici",
  "yumminn_connection_with": "Connexion Yumminn avec",
  "connection_failed": "Connexion échouée",
  "successfully_connected": "Connecté avec succès",
  "try_again_or_contact_an_agent": "Réessayez ou contactez l'un de nos agents en cliquant",
  "connect": "Connecter",
  "disconnect": "Déconnecter",
  "disconnect_yumminn_from": "Êtes-vous sûr de vouloir déconnecter Yumminn de ",
  "disconnect_yumminn_from_message": "Vos clients <1>ne pourront plus commander ni payer via Yumminn, et la synchronisation des tables ainsi que du menu</1> sera perdue.",
  modifier_category_empty_state: "Ajouter un groupe pour pouvoir créer vos modificateurs",
  define: "définir",
  visible_products: "Produits visibles",
  visible_products_with_image: "Produits visibles avec image",
  visible_products_with_description: "Produits visibles avec description",
  unique_products_sold: "Produits uniques vendus",
  visible_products_sold: "Produits visibles vendus",
  total_products_sold: "Total des produits vendus",
  highlighted_products: "Produits en vedette",
  average_score: "Score moyen",
  top_products_score_info: "Il est calculé en fonction du pourcentage de produits visibles:<br/>· Avec image (x1,3)<br/>· Vendu  (x1)<br/>· Avec description (x0,7)",
  item: "item",
  item_plural: "items",
  unit_price: "prix unitaire",
  units_sold: "unités vendues",
  units_prepared: "unités préparées",
  units_not_delivered: "unités non livrées",
  units_delivered: "unités livrées",
  weight_units: "poids (unités)",
  total_billing: "total facturé",
  weight_billing: "poids (chiffre d'affaires)",
  image: "image",
  custom_group_individual: "Un seul produit",
  custom_group_multi: "Divers produits",
  custom_group_commercial: "Bannière",
  catalog_start: "Début de la lettre",
  l1_start: "Accueil de la catégorie principale",
  l2_start: "Accueil de la catégorie secondaire",
  l3_start: "Catégorie de produit Accueil",
  go_back: "retour",
  choose_product_hihglight_title: "Quel type de mise en valeur souhaitez-vous créer ?",
  one_product: "un produit",
  multiple_products: "Divers produits",
  new_individual: "Nouveau produit phare",
  new_multi: "Nouveau point fort de plusieurs produits",
  new_commercial: "Créer une bannière",
  edit_individual: "Modifier le produit phare",
  edit_multi: "Modifier plusieurs produits phares",
  edit_commercial: "Modifier la bannière",
  position: "Position",
  title_and_description: "Titre et description",
  customization: "Personnalisation",
  title_and_position: "Titre et poste",
  details: "Détails",
  highlighted_title: "Titre en vedette",
  highlighted_title_placeholder: "Ex : ‍👩🏻‍🍳 Suggestions du chef !",
  highlighted_position: "Poste en vedette",
  choose_stategic_position: "Choisissez une position stratégique dans votre graphique.",
  examples: "Exemples",
  highlighted_position_suggestion_1: "Mettez en avant les <b>best-sellers, les nouveautés ou les suggestions du chef</b> au début du menu.",
  highlighted_position_suggestion_2: "Proposez des tapas qui s'accordent parfaitement avec vos vins dans la <b>catégorie des vins</b>.",
  individual_product_explanation: "Choisissez un produit que vous souhaitez mettre en valeur",
  multi_product_explanation: "Choisissez les produits que vous souhaitez mettre en avant en tenant compte du fait qu'il doit s'agir de <b>3</b> produits au <b>minimum et de 15 produits au maximum</b>.",
  highlighted_tag: "Étiquette en vedette (facultatif)",
  highlighted_tag_placeholder: "Ex : ⭐️ Recommandé ⭐️",
  highlighted_description_placeholder: "Ex : Choisissez votre smoothie aux fruits km0",
  banner_internal_title: "Titre (interne)",
  banner_internal_title_placeholder: "Ex : Recommandé",
  banner_position: "Position de la bannière",
  banner_image_info: "Spécifications :<br/><b>Bannière 800x350 avec une marge de sécurité de 50px (minimum)</b> de chaque côté pour une visibilité complète sur différents appareils.",
  force_banner_visibility: "Afficher la bannière même lorsqu'il n'y a aucun produit visible dans le menu.",
  title_color: "Couleur du titre",
  one_color: "une couleur",
  two_colors: "deux couleurs",
  background: "Arrière-plan",
  background_color: "couleur de fond",
  top_color: "Couleur supérieure",
  bottom_color: "Couleur du bas",
  main_color: "Couleur principale",
  extra_color: "couleur supplémentaire",
  image_info: "JPG, PNG-860X400 px. Qualité maximale 2,5 Mo.",
  default_product_image_title: "Existe-t-il des produits sans photos ? <b>Ajouter une image par défaut</b>",
  add_logo: "télécharger le logo",
  change_logo: "changer de logo",
  banner: "bannière",
  highlighted: "remarquable",
  empty_schedules_title: "Par défaut, le {{type}} sera visible pendant toutes les heures de service.",
  empty_schedules_message: "Si vous souhaitez personnaliser les horaires de visibilité, <b>vous pouvez définir un planning ou le désactiver</b> quand vous le souhaitez.",
  delete_custom_group_title: 'Supprimer la sélection "{{name}}"',
  delete_custom_group_message: "Êtes-vous sûr de vouloir supprimer ce surlignage ? Alternativement, vous pouvez le désactiver et ne pas perdre vos paramètres.",
  delete_banner_title: 'Supprimer la bannière "{{name}}"',
  delete_banner_message: "Êtes-vous sûr de vouloir supprimer cette bannière ? Alternativement, vous pouvez le désactiver et ne pas perdre vos paramètres.",
  custom_group_finnished: "Votre point fort est déjà au menu.<br/><b>Scannez et testez</b> depuis votre mobile.",
  events_settings: "Configuration évenements",
  synchronize_tables: "Syncronizer tables",
  add_price_per_sector: "Ajouter prix par secteur",
  manage_price_per_sector: "Gèrer prix par secteur",
  sector_price_explanation: "Tous les ajustements seront effectués sur le prix de base du TPV. Vous pouvez voir la mise à jour de chaque produit dans la section « carte ».",
  Price_per_sector: "Prix par secteur",
  "You need to save the created sector first": "Vous devez d'abord enregistrer le secteur créé avant d'ajouter une table."







};
