import React from "react";
import { PhotographIcon } from "@heroicons/react/outline";
import { UploadImageIcon } from './EventIcons';
import { useTranslation } from "react-i18next";
import { getImageUrl } from 'logic/functions';

export const EventImage = ({ object, type }) => {
  const { t } = useTranslation();
  
  return (
    <>
    
        <div className={`flex h-[300px] items-center`}>
          {object.image ? (
            <img
              src={getImageUrl(object.image)}
              style={{ objectFit: "cover", minWidth: "300px", minHeight: "300px", height: "55px"}}
              alt=''
            />
          ) : (
              type === 'event' && object.image ? 
                <PhotographIcon height={55} className="flex justify-center items-center text-[#C0C0C0]" />
              :
              <div className='flex flex-col items-center justify-center'>
                <UploadImageIcon size={40}/>
                <div className='mt-2'>{t('drag_or_click_here')}</div>
                <div>{t('to_upload_image')}</div>
              </div>
               
          )}
        </div>
    
    </>
  );
};
