import styled from "styled-components";

export const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #F4F4F5;

  .filters {
    display: flex;
    gap: 1rem;
    margin: 2rem;

    & > div {
      min-width: 20rem;

      h6 {
        font-weight: 600;
      }
    }
  }

  .pagination {
    margin: 0 2rem;
  }

  .panels {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin: 2rem;

    & > * {
      background-color: #FFF;
      border: 1px solid #DDD;
      border-radius: 16px;
      flex: auto;
    }
  
    .status {
      display: inline-block;
      line-height: 1;
      text-wrap: balance;
      text-align: center;
      max-width: 12ch;
      border-radius: 4px;
      padding: 4px;
    }

    .spinner {
      width: 100%;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const PurchaseTableContainer = styled.div`
  max-width: 50rem;
  max-height: 41rem;
  overflow-y: auto;
  padding: 8px;
  
  table {
    width: 100%;
    border-collapse: separate;
    font-weight: 500;
  }

  th {
    font-size: 1.125rem;
    font-weight: 500;
    padding: 8px 16px;
    background-color: #FFF;
    border-bottom: 1px solid #DDD;
    position: sticky;
    top: 0;
    box-shadow: 0 -8px 0 #FFF;

    div {
      position: relative;
      width: fit-content;
      margin: auto
    }

    span {
      position: absolute;
      left: calc(100% + 0.5rem);
      top: 0;
      height: 100%;
      width: 0.75rem;
      display: flex;
      flex-direction: column;
      opacity: 0.25;
      transition: opacity 0.2s ease-in-out;

      svg {
        flex: 1;
        margin: -4px;
      }
    }

    &:has(div) {
      cursor: pointer;
    }
    
    &:hover span {
      opacity: 0.75;
    }
  }

  tr {
    --bg_color: transparent;
    --border_color: transparent;

    .purchase {
      background-color: #EEF9F5;
      border: 1px solid #AFDCCA;
      border-radius: 1rem;
      padding: 4px 8px;
    }

    &.selected {
      --bg_color: #F7FFFC;
      --border_color: #094553;
      
      .purchase {
        color: #FFF;
        background-color: #094553;
        border-color: #094553;
      }
    }
  }

  td {
    text-align: center;
    background-color: var(--bg_color);
    border-color: var(--border_color);
    border-width: 1px 0;
    border-style: solid;
    padding: 8px 16px;

    &:first-child {
      border-width: 1px 0 1px 1px;
      border-radius: 8px 0 0 8px;
    }
    
    &:last-child {
      text-align: right;
      padding-right: 32px;
      border-width: 1px 1px 1px 0;
      border-radius: 0 8px 8px 0;
    }

    .date {
      display: inline-block;
      white-space: break-spaces;
      max-width: min-content;
      line-height: 1.3rem;
    }

    &:has(.spinner) {
      padding: 0;
      border: none;
    }
  }
`;

export const PurchasePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  color: #222;
  max-width: 32rem;

  header {
    h3 {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.5rem;
      font-weight: 500;
  
      b {
        font-weight: 700;
      }
  
      svg {
        margin-left: auto;
      }
  
      span {
        font-size: 1rem;
      }
    }

    span {
      font-weight: 500;
    }
  }

  h4 {
    font-size: 1.125rem;
    align-self: center;
    text-align: center;
    padding: 8px;
    background-color: #F7FFFC;
    border-radius: 10px;

    b {
      font-weight: 600;
    }
  }

  .list_wrapper {
    border: 1px solid #DDD;
    border-radius: 16px;
    color: #404040;
    padding: 16px;
  }

  h5 {
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    
    span {
      font-size: 1rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      background-color: #404040;
      border-radius: 1.3em;
      height: 1.3em;
      min-width: 1.3em;
    }
  }

  h6 {
    font-size: 1.125rem;
    font-weight: 600;
    text-decoration: underline;
  }
  
  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    max-height: 18rem;
    overflow: auto;

    ul {
      max-height: unset;

      li {
        display: flex;
        align-items: center;
        gap: 16px;

        & > * {
          flex: 1;
        }
        
        & > span {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;
          font-weight: 600;
          text-transform: capitalize;
          border: 1px solid #DDD;
          border-radius: 10px;
          padding: 8px;
          
          b {
            background-color: #EEF9F5;
            border: 1px solid #AFDCCA;
            border-radius: 1rem;
            padding: 2px 8px;
            font-weight: 600;
          }
        }

        a {
          font-weight: 600;
          text-decoration: underline;
          padding: 0 16px;

          &::first-letter {
            text-transform: capitalize;
          }

          &:hover {
            color: #62BF99;
          }
        }
      }
    }
  }

  .payment {
    display: flex;
    justify-content: space-between;
    font-size: 1.125rem;
    font-weight: 500;

    b {
      font-weight: 700;
    }

    i {
      font-style: normal;
      color: #767676;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
`;

export const ValidatedTicketsContainer = styled.div`
  border: 1px solid #E2E2E2;
  border-radius: 1rem;
  padding: 8px 8px 8px 8px;
  margin-left: auto;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  svg {
    height: 1.875rem;
    width: 1.875rem;
    padding: 3%;
    color: #FFF;
    background-color: #094553;
    border-radius: 1rem;
  }
  
  b {
    font-size: 1.75rem;
    font-weight: 600;
    color: #094553;
  }

  span {
    font-size: 0.875rem;
    font-weight: 500;
    color: #767676;
    margin: 0 8px 0 2.375rem;
  }
`;

export const TicketsSoldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .selectors {
    display: flex;
    gap: 1rem;
    margin: 2rem;

    & > div {
      min-width: 20rem;

      h6 {
        font-weight: 600;
      }
    }
  }

  .pagination {
    margin: 0 2rem;
  }

  .panels {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin: 2rem;

    & > * {
      background-color: #FFF;
      border: 1px solid #DDD;
      border-radius: 16px;
      flex: auto;
    }

    .big_spinner {
      width: 100%;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const TicketsSoldTableContainer = styled.div`
  max-width: 50rem;
  max-height: 41rem;
  overflow-y: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
  background-color: white;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 8px 16px;
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  /* Header styling */
  .header {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    position: sticky;
    top: 45px;
    background-color: #f5f5f5;
    font-weight: 600;
    font-size: 1.1rem;
    border-bottom: 1px solid #ddd;
  }

  /* Row Styling */
  .row {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #f5f5f5;
    align-items: center;
  }

  .column {
    flex: 1;
    text-align: center;
  }

  /* Spinner and Empty State */
  .spinner_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
  }

  .empty_state {
    text-align: center;
    padding: 30px 0;
    color: #888;
  }

  /* Responsive */
  @media (max-width: 768px) {
    .table {
      font-size: 14px;
    }

    .header {
      font-size: 1rem;
    }

    .row {
      font-size: 14px;
    }
  }
`;

export const TicketSoldRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #f5f5f5;
  align-items: center;

  .divDates {
      background: #F5F5F5;
      border-radius: 8px;
      padding: 20px;
      span{
        text-align: center;
      }
    }

  .column {
    flex: 1;
    text-align: center;
    font-size: 0.9rem;
    align-items: center;
   
    .weekday{
      font-size: 1.15rem;
    }
    .date{
      font-weight: 600;
      font-size: 1.25rem;
    }
    .time{
      font-size: 1.15rem;
      font-weight: 600;
    }

  }

  .sold {
    font-size: 1.15rem;
    font-weight: 700;
    gap: 15px;
    padding: 12px; 
    display: flex;
    flex-direction: column;
    span {
      padding: 6px;
    }
  }

  .available{
    font-size: 1.15rem;
    font-weight: 700;
    gap: 15px;
    display: flex;
    flex-direction: column;
  }

  .available_stock{
    min-width: 100px;
  }

  input {
    cursor: pointer;
    font-size: 1.15rem;
  }


  .event_name {
    border-radius: 6px;
    gap: 20px;
    display: flex;
    flex-direction: column;

    span {
      padding: 4px 12px 4px 12px;
      border-radius: 6px;
      display: flex; 
      flex-direction: column;
      gap: 4px;
    }
  }


  
`;