import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { generateRandomId } from './EventsLogic';

const chevronSize = 27;
const buttonSize = 35;

const ExternalContainer = styled.div`
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #D4D4D4;
    border-radius: 12px;
    background: #FFFFFF;    
    z-index: 999999999999;

    p {
        max-width: 28ch;
        margin: 0;
        color: #404040;
    }

    b {
        font-weight: 600;
    }
`;
const MonthPicker = styled.div`
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
        min-width: ${chevronSize}px;
        max-width: ${chevronSize}px;
        min-height: ${chevronSize}px;
        max-height: ${chevronSize}px;
    }
`;
const MonthText = styled.p`
    align-self: center;
    width: fit-content;
    font-size: 12px;
    font-weight: 500;
    user-select: none;
`;
const WeekdayInitialsContainer = styled.div`
    display: flex;
    gap: 15px;
    justify-content: space-between;
    width: -webkit-fill-available;
    margin-top: 8px;
    user-select: none;
`;
const WeekdayInitial = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;

    p {
        font-weight: 300;
        font-size: 12px;
        font-weight: 600;
    }
`;
const Calendar = styled.div`
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    user-select: none;
`;
const DayButtonContainer = styled.div`
    //height: 54px;
    display: flex;
    justify-content: center;
`;
const DayButton = styled.div`
    width: ${buttonSize}px;
    height: ${buttonSize}px;
    align-self: center;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &.selectable {
        background-color: #F9F9F9;
        border-radius: ${buttonSize}px;
        border: 1px solid #094553;
        p {
          color: #094553;
          font-weight: 600;
      }
    }

    &.unavailable {
        border-radius: ${buttonSize}px;
        border: 1px solid #404040;
        opacity: 40%;
        p {
          color: #404040;
          font-weight: 600;
      }
    }

    &.selected {
        background-color: #094553;
        border-radius: ${buttonSize}px;
        p {
          color: white;
          font-weight: 600;
        }
    }

    p {
        //font-family: Roboto;
        font-weight: 100;
        font-size: 12px;
        align-self: center;
    }
`;

const generateDaysForMonth = (year, month) => {
    const numberOfDaysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonthWeekPosition = new Date(year, month, 1).getDay();
    const adjustedFirstDay = (firstDayOfMonthWeekPosition === 0) ? 6 : firstDayOfMonthWeekPosition - 1;

    const days = [];
    for (let i = 0; i < adjustedFirstDay; i++) {
        days.push(null);
    }
    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      days.push(i);   
    }
    return days;
}

function capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const currentDay = currentDate.getDate();
const daysForCurrentMonth = generateDaysForMonth(currentYear, currentMonth);

export const EventsDatePicker = ({ 
    selectedDates, 
    setSelectedDates, 
    selectedTimes, 
    addExcludedDate, 
    singleToggleDateSelection, 
    handleRecurrenceEndDate, 
    recurringInitialDate, 
    recurrenceType, 
    newStyles, 
    onClose, 
    setSelectedTimes, 
    editEvent, 
    selectedDatesEvent, 
    setSelectedDatesEvent, 
}) => {
    const recurringStartDate = new Date(recurringInitialDate)
    const recurringInitialMonth = recurringStartDate.getMonth()
    const recurringInitialYear = recurringStartDate.getFullYear()

    const [year, setYear] = useState(recurringInitialDate ? recurringInitialYear : currentYear);
    const [month, setMonth] = useState(recurringInitialDate ? recurringInitialMonth : currentMonth);
    const [days, setDays] = useState(daysForCurrentMonth);

    const weAreInCurrentMonth = recurringInitialDate ? (year === recurringInitialYear && month === recurringInitialMonth) : (year === currentYear && month === currentMonth);
    const weAreInNextYearMonth = recurringInitialDate ? (year === recurringInitialYear  + 1 && month === recurringInitialMonth - 1) : (year === currentYear + 1 && month === currentMonth - 1);
    const { t } = useTranslation();

    useEffect(() => {
        if (month > 11) {
            setMonth(0);
            setYear(year + 1);
        } else if (month < 0) {
            setMonth(11);
            setYear(year - 1);
        } else if (month === currentMonth && recurringInitialDate) {
            const recurringStartDate = new Date(recurringInitialDate)
            const recurringInitialMonth = recurringStartDate.getMonth()
            setMonth(recurringInitialMonth)
        }
        
    }, [month]);

    useEffect(() => {
        setDays(generateDaysForMonth(year, month));
    }, [month, year]);


    const isSelected = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        return Array.isArray(selectedDates) ? selectedDates.some(selectedDate => selectedDate.date === formattedDate) : selectedDates === formattedDate;
    };

    const toggleDateSelection = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD'); 
        if (isSelected(date)) {
            // If the date is already selected, unselect it
            setSelectedDates(prevSelectedDates => 
                prevSelectedDates.filter(selectedDate => selectedDate.date !== formattedDate)
            );

            const isRecurring = selectedTimes.some((selectedTime) => selectedTime.date === formattedDate && !(['none', 'not_repeated'].includes(selectedTime.recurrence_type)))

            const selectedTime = selectedTimes.find((selectedTime) => selectedTime.date === formattedDate && !(['none', 'not_repeated'].includes(selectedTime.recurrence_type)))

            const initialTime = selectedTimes.find((seltime => seltime.id === selectedTime?.event_date||selectedTime?.id)).date
            
            if (isRecurring && initialTime) {
                addExcludedDate(initialTime, formattedDate)
            }
        } else {

            const newSelection = {
                date: formattedDate,
                id: generateRandomId(),  
            };

            const existingSelection = selectedTimes.find((entry) => entry.date === formattedDate);

            if (existingSelection) {
                // Respect existing id or event_date
                newSelection.id = existingSelection.id || generateRandomId();  // Use existing id or generate new if not available
                newSelection.event_date = existingSelection.event_date || newSelection.event_date;  // Use existing event_date or keep the new one
            }

            setSelectedDates(prevSelectedDates => [
                ...prevSelectedDates,
                newSelection,  // Add new selection with date, id, and event_date if available
            ]);
        }
    };

    
    const singleDateSelection = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');

        if (isSelected(date)) {
            return;
        } else {
            // If the date is not selected, set it as the only selected date
            handleRecurrenceEndDate(recurrenceType, recurringInitialDate, formattedDate, selectedTimes, setSelectedTimes, editEvent, selectedDatesEvent, setSelectedDatesEvent)
            setSelectedDates(formattedDate);  
            onClose(false);
        }
    }


    return (
        <div style={{position: newStyles ? 'relative' : 'inherit'}}>
       
          <div style={{position: newStyles ? 'absolute' : 'relative', display: "flex", top: newStyles ? "-13px" : "", /*minWidth: "420px", maxWidth: newStyles ? "350px" : "",*/ flexDirection: "column", alignItems: newStyles ? "" : "center", overflow: "hidden", backgroundColor: "white", borderRadius: "12px", border: "1px solid ##D4D4D4", padding: newStyles ? "" : "0 30px 30px 30px"}}>
            <ExternalContainer>
            <MonthPicker>
                <ChevronLeftIcon
                    style={{height: "20px", cursor: weAreInCurrentMonth ? 'not-allowed' : 'pointer', opacity: weAreInCurrentMonth ? '20%': '100%'}}
                    onClick={() => {
                        if (weAreInCurrentMonth) return;
                        setMonth(month - 1);
                    }}
                />
                <MonthText>
                    {capitalizeFirstLetter(new Date(year, month, 1).toLocaleString('es', { month: 'long' })) + " " + year.toString()}
                </MonthText>
                <ChevronRightIcon
                    style={{height: "20px", cursor: weAreInNextYearMonth ? 'not-allowed' : 'pointer', opacity: weAreInNextYearMonth ? '20%': '100%'}}
                    onClick={() => {
                        if (weAreInNextYearMonth) return;
                        setMonth(month + 1);
                    }}
                />
            </MonthPicker>
            <WeekdayInitialsContainer>
                <WeekdayInitial><p>{t('Lun')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Mar')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Mie')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Jue')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Vie')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Sab')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Dom')}</p></WeekdayInitial>
            </WeekdayInitialsContainer>
            <Calendar>
                {days.map((day, index) => {
                    const date = new Date(year, month, day);
                    const isVoidButton = !day;
                    const isDayPrevToToday = month === currentMonth && day < currentDay;
                    const recurringStartDate = new Date(recurringInitialDate)
                    const recurringInitialDay = recurringStartDate.getDate()
                    const recurringInitialMonth = recurringStartDate.getMonth()

                    const isDayPrevToInitialRecurringDate = day < recurringInitialDay && month === recurringInitialMonth;
                    const selected = isSelected(date);
                    return (
                        <DayButtonContainer key={'calendar_day_' + index}>
                            <DayButton
                                className={isVoidButton || isDayPrevToToday || isDayPrevToInitialRecurringDate ? '' : selected ? 'selected' : 'selectable'}
                                onClick={() => {
                                    if (isDayPrevToToday || isVoidButton || isDayPrevToInitialRecurringDate) return;
                                    singleToggleDateSelection ? singleDateSelection(date) : toggleDateSelection(date);
                                }}
                            >
                                <p>{day ? day : ''}</p>
                            </DayButton>
                        </DayButtonContainer>
                    );
                })}
              </Calendar>
            </ExternalContainer>
          </div>
        </div>
    );
};
