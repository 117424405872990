import React, { useContext, useEffect, useState } from 'react';
import { CatalogDNDContext } from 'data/catalogDnDContext';
import { DragDropContext } from 'react-beautiful-dnd';
import { DroppableTabs } from './DroppableTabs';
import { ProductsTable } from '../OrderAndPay/ProductsTable';
import { ObjectTypes, generateNewCatalog, patchOrderAndParent } from '../OrderAndPay/api-OrderAndPay';
import { CHILDREN, CONTEXT_KEYS, FIELDS, MAX_LEVELS, PARENTS, PARENT_FIELDS, TPVS } from './constants';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../OrderAndPay/AddButton';
import { ProgressBar } from 'common/ProgressBar';
import { Context as AuthContext } from '../../../../data/authContext';
import { CategoriesContainer } from '../OrderAndPay/ProductsTable/ProductTableContainters';

export const CatalogDragAndDrop = ({ catalog, tpv, isTspoonlab, setCreateOrEdit, setSectorId, sectorId, categoriesOptions, mutate }) => {
  const {
		state: {
			selectedSuperGroup,
			selectedGroup,
			selectedCategory,
      draggingItem,
			draggingName,
      sourceList,
      lastHover,
		},
		setSelectedSuperGroup,
		setSelectedGroup,
		setSelectedCategory,
		setDraggingItem,
		setDraggingName,
		setSourceList,
    setLastHover,
    } = useContext(CatalogDNDContext);
  
  const {
    state: { selectedRestaurantId, selectedRestaurant },
	} = useContext(AuthContext);

  const { t } = useTranslation();

  const [productList, setProductList] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [progress, setProgress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

	const canChangeOrder = listName => draggingName === listName;
	const canChangeParent = listName => draggingName === CHILDREN[listName] && catalog.numberOfLevels <= MAX_LEVELS[listName];

  const isDisabled = listName => {
    const changeOrder = canChangeOrder(listName);
    const changeParent = canChangeParent(listName);
    const cannotDragToChild = MAX_LEVELS[listName] < MAX_LEVELS[draggingName];
    const isDisabledVar = !!draggingName && (!changeOrder && !changeParent || cannotDragToChild);

    return isDisabledVar;
  };

  const onDragStart = (result) => {
    const listName = result.draggableId.split('-')[0];
    const id = result.draggableId.split('-')[1];
    const allSelected = { selectedSuperGroup, selectedGroup, selectedCategory };
    const selectedParent = allSelected[CONTEXT_KEYS[PARENTS[listName]]?.SELECTED];

    setDraggingName(listName);
    let sourceLists = {
      SUPER_GROUP: catalog?.supergroups,
      CATEGORY_GROUP: selectedSuperGroup?.category_groups,
      CATEGORY: selectedGroup?.product_categories,
    };

    listName in sourceLists
      ? setSourceList(sourceLists[result.draggableId.split('-')[0]])
      : setDraggingItem({ id, listName, listId: result.draggableId, index: result.index, parent_id: selectedParent?.id });

  };

	const onDragEnd = async ({ source, destination, draggableId }) => {
    setDraggingItem(null);
    setDraggingName('');
    setLastHover({
      SUPER_GROUP: null,
      CATEGORY_GROUP: null,
      CATEGORY: null
    });
    
    if (!source || !destination || !draggableId || !draggingItem) return;
    
    setIsLoading(true);

    const sourceName = source.droppableId.split('-')[0];
    const destinationName = destination.droppableId.split('-')[0];
    const parentName = PARENTS[sourceName];
    
    const allSelected = { selectedSuperGroup, selectedGroup, selectedCategory };
    const allSetSelected = { setSelectedSuperGroup, setSelectedGroup, setSelectedCategory };

    const selected = allSelected[CONTEXT_KEYS[sourceName]?.SELECTED];
    const selectedParent = allSelected[CONTEXT_KEYS[parentName]?.SELECTED];
    const setSelected = allSetSelected[CONTEXT_KEYS[sourceName]?.SET_SELECTED];

    const isMod = !draggingItem?.parent_id;
    const isProductOrMod = ['PRODUCT', 'MODIFIER', 'PRODUCT_MODIFIER_CATEGORY', 'MODIFIER_MODIFIER_CATEGORY'].includes(sourceName);
    const isMovingToParent = destinationName === parentName;
    const isSameParent = isMod || (isMovingToParent ? lastHover[parentName] === draggingItem?.parent_id : selectedParent?.id === draggingItem?.parent_id);
    const isInTheSamePlace = isMovingToParent ? isSameParent : isSameParent && source.index === destination.index;

    if ((isInTheSamePlace || !isMovingToParent) && (sourceName !== destinationName)) {
      setIsLoading(false);
      return;
    }

    try {
      const object = isProductOrMod ? {...sourceList[source.index]} : {...draggingItem};
      const parentField = PARENT_FIELDS[sourceName];
      let destinationList = [];
      
      if (isMovingToParent && canChangeParent(destinationName)) {
        const destinations = {
          SUPER_GROUP: (index) => {
            const parent = catalog.supergroups[index];
            return [parent.id, parent.category_groups];
          },
          CATEGORY_GROUP: (index) => {
            const parent = selectedSuperGroup.category_groups[index];
            return [parent.id, parent.product_categories];
          },
          CATEGORY: (index) => {
            const parent = selectedGroup.product_categories[index];
            return [parent.id, parent.products];
          },
        };
        
        const [destinationId, list] = destinations[destinationName](destination.index);

        if (destinationId === object.parent_id) return;

        object.order = 0;
        object.parent_id = destinationId;
        object[parentField] = destinationId;
        destinationList = list;
      }
      
      if (!isMovingToParent && !isSameParent && canChangeParent(PARENTS[destinationName])) {
        object.parent_id = selectedParent.id;
        object[parentField] = selectedParent.id;
        destinationList = selectedParent[FIELDS[destinationName]];
      }

      if (!isMovingToParent && isSameParent) {
        sourceName === 'MODIFIER' && sourceList.forEach((item, index) => item.order = index)
        destinationList = sourceList;
      }
      
      if (!isMovingToParent && canChangeOrder(destinationName)) {
        object.order = destination.index;
      }
      
      sourceList.splice(source.index, 1);
      destinationList.splice(object.order, 0, object);

      ['width', 'listId', 'listName', 'index'].forEach(key => delete object[key]);
      
      const filteredObject = { id: object.id, order: object.order };

      if (!isSameParent) filteredObject[parentField] = object[parentField];

      await patchOrderAndParent({ objectType: ObjectTypes[sourceName], object: filteredObject, t });
      
      if (setSelected) {
        isMovingToParent && selected.id === object.id && setSelected(sourceList[0]);

        !isMovingToParent && setSelected(object);
      };
    } catch (error) {
      console.log(`drag error: `, error);
    } finally {
      setIsLoading(false);
    }
	};

  const makeBaseCatalog = async () => {
    await generateNewCatalog({ restaurant: selectedRestaurantId, setProgress, t});
    setProgress(null);
    await mutate();
  };

  useEffect(() => {
    const hasSupergroups = 'supergroups' in catalog && catalog.supergroups?.length > 0;

    let selectedL1;
    let selectedL2;
    let selectedL3;
  
    if (hasSupergroups) {
      const previousL1 = Number(sessionStorage.getItem('selectedSuperGroup'));
      const previousL2 = Number(sessionStorage.getItem('selectedGroup'));
      const previousL3 = Number(sessionStorage.getItem('selectedCategory'));

      if (previousL1 || previousL2 || previousL3) {
        selectedL1 = catalog.supergroups.find(l1 => {
          if (previousL3) {
            selectedL2 = l1.category_groups.find(l2 => {
              selectedL3 = l2.product_categories.find(l3 => l3.id === previousL3);
              return !!selectedL3;
            });
            return !!selectedL2;
          }
  
          if (previousL2) {
            selectedL2 = l1.category_groups.find(l2 => {
              if (l2.id === previousL2) {
                selectedL3 = l2.product_categories?.[0];
  
                return true;
              }
            });
  
            return !!selectedL2;
          }
  
          if (previousL1 && l1.id === previousL1) {
            selectedL2 = l1.category_groups?.[0];
            selectedL3 = selectedL2?.product_categories?.[0];
  
            return true
          }
        });
      } else {
        selectedL1 = catalog.supergroups[0]
        selectedL2 = selectedL1?.category_groups?.length > 0 ? selectedL1?.category_groups[0] : null;
        selectedL3 = selectedL2?.product_categories?.length > 0 ? selectedL2?.product_categories[0]: null;
      }
    } else {
      const previousL3 = sessionStorage.getItem('selectedCategory');
      selectedL2 = catalog;
      selectedL3 = catalog.product_categories?.find(l3 => l3.id === previousL3) || catalog.product_categories?.[0];
    }

    sessionStorage.removeItem('selectedSuperGroup');
    sessionStorage.removeItem('selectedGroup');
    sessionStorage.removeItem('selectedCategory');


    const isCatalogEmpty = !hasSupergroups && selectedL2?.product_categories?.length <= 0;
    setIsEmpty(isCatalogEmpty);

    if (isCatalogEmpty && (tpv.includes(TPVS.YUMMINN) || selectedRestaurant[0].custom_catalog)) {
      makeBaseCatalog();
    } else if (!isCatalogEmpty) {
      setSelectedSuperGroup(selectedL1);
      setSelectedGroup(selectedL2);
      setSelectedCategory(selectedL3);
    };
  }, []);

  useEffect(() => {
    if (draggingItem) return;
    setProductList(selectedCategory);
  }, [selectedCategory, draggingItem]);

  if (!tpv.includes(TPVS.YUMMINN) && !selectedRestaurant[0].custom_catalog && isEmpty) return <></>

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <CategoriesContainer>
        {isEmpty &&
          <div className={`text-gray-400 flex flex-col justify-center items-center gap-2 p-5`}>
            <b className='font-semibold'>{t('title_empty_menu')}</b>
            <p>{t('message_empty_menu')}</p>
            <AddButton onClick={() => setCreateOrEdit({type: 'SUPER_GROUP'})} text={t(`Create_SUPER_GROUP`)}/>
          </div>
        }
        {catalog?.supergroups &&
          <DroppableTabs
            listId={'SUPER_GROUP'}
            draggableList={catalog.supergroups}
            numberOfLevels={catalog.numberOfLevels}
            disabled={isDisabled('SUPER_GROUP') || isLoading}
            createNew={() => setCreateOrEdit({type: 'SUPER_GROUP'})}
            openEditModal={group => setCreateOrEdit({type: 'SUPER_GROUP', group})}
          />
        }
        {selectedSuperGroup &&
          <DroppableTabs
            listId={'CATEGORY_GROUP-' + selectedSuperGroup.id}
            draggableList={selectedSuperGroup.category_groups}
            numberOfLevels={catalog.numberOfLevels}
            disabled={isDisabled('CATEGORY_GROUP') || isLoading}
            createNew={() => setCreateOrEdit({type: 'CATEGORY_GROUP', parentId: selectedSuperGroup.id})}
            openEditModal={group => setCreateOrEdit({type: 'CATEGORY_GROUP', parentId: selectedSuperGroup.id, group})}
          />
        }
        {selectedGroup &&
          <DroppableTabs
            listId={'CATEGORY-' + selectedGroup.id}
            draggableList={selectedGroup.product_categories}
            numberOfLevels={catalog.numberOfLevels}
            disabled={isDisabled('CATEGORY') || isLoading}
            createNew={() => setCreateOrEdit({type: 'CATEGORY', parentId: selectedGroup.id})}
            openEditModal={group => setCreateOrEdit({type: 'CATEGORY', parentId: selectedGroup.id, group})}
          />
        }
      </CategoriesContainer>

      {productList &&
        <ProductsTable
          category={productList}
          setSourceList={setSourceList}
          categoriesOptions={categoriesOptions}
          catalog={catalog}
          disabled={!['PRODUCT', 'PRODUCT_MODIFIER_CATEGORY', 'MODIFIER', 'MODIFIER_MODIFIER_CATEGORY', ''].includes(draggingName) || isLoading}
          tpv={tpv}
          isTspoonlab={isTspoonlab}
          mutate={mutate}
          setSectorId={setSectorId}
          sectorId={sectorId}
        />
      }

      {progress !== null &&
        <div className="z-9999 fixed top-0 left-0 right-0 bottom-0 flex bg-[#0006]">
          <div className="flex flex-col gap-6 p-10 m-auto overflow-hidden bg-white rounded-3xl text-center shadow-xl">
            <h4 className="text-xl font-semibold">
              {t('creating_catalog_title') + '✨'}
            </h4>
            <ProgressBar progress={progress}/>
            <i className="max-w-xs">
              {t('creating_catalog_message')}
            </i>
          </div>
        </div>
      }
    </DragDropContext>
  );
};