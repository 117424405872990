import { SelectInput } from 'common/Input';
import { CustomGroupPreview } from 'components/previews';
import { HalfMobileMockup } from 'components/previews/HalfMobileMockup';
import React from 'react';
import { CustomGroupProductsContainer } from './ProductHighlightContainers';
import { Trans, useTranslation } from 'react-i18next';
import { Icon, IconType } from 'common/Icon';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export const CustomGroupProducts = ({customGroupDraft, catalog, editCustomGroup}) => {
  const { t } = useTranslation();

  const isMulti = customGroupDraft.custom_group_type === 'multi';
  const products = customGroupDraft.products_info?.sort((a,b) => a.order - b.order) || [];

  const options = catalog.product_categories.filter(category => !!category.products?.length).map((category) => ({
    label: category.name || category.tpv_name,
    options: category.products.map((product) => ({
      label: product.visible_name || product.name || product.tpv_name,
      value: {
        product_id: product.id,
        product__name: product.visible_name || product.name || product.tpv_name,
        product_image: product.image || "",
        product__tpv_price_cents: product.tpv_price_cents,
        alergens_ids: product.allergens || [],
      },
    })),
  }));

  const flatOptions = options.map(category => category.options).flat();
  const value = products.map(product => flatOptions.find(option => option.value.product_id === product.product_id));

  const selectProducts = selection => {
    if (isMulti) {
      const orderedProducts = selection.slice(0, 15).map((product, index) => ({...product.value, order: index}));
      editCustomGroup({ products_info: orderedProducts });
    } else {
      editCustomGroup({ products_info: [{...selection.value, order: 0}] });
    }
  };

  const changeOrder = ({ source, destination }) => {
    const orderedProducts = [...products];
    const [productMoved] = orderedProducts.splice(source.index, 1);

    orderedProducts.splice(destination.index, 0, productMoved);
    orderedProducts.forEach((product, index) => product.order = index);

    editCustomGroup({ products_info: orderedProducts });
  };

  const removeProduct = id => {
    const orderedProducts = [...products];
    const index = orderedProducts.findIndex(p => p.product_id === id);

    orderedProducts.splice(index, 1);
    orderedProducts.forEach((product, index) => product.order = index);
    
    editCustomGroup({ products_info: orderedProducts });
  };

  return (
    <CustomGroupProductsContainer>
      <HalfMobileMockup>
        <CustomGroupPreview customGroupDraft={customGroupDraft} catalog={catalog}/>
      </HalfMobileMockup>
      <div>
        {!isMulti &&
          <>
            <h6>{t('product')}</h6>
            <p>{t('individual_product_explanation')}</p>
          </>
        }
        <SelectInput
          label={isMulti ? 'products' : undefined}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          placeholder='select'
          options={options}
          value={value}
          onChange={selectProducts}
        />
        {isMulti &&
          <>
            <p>
              <Trans
                i18nKey='multi_product_explanation'
                default='Elige los productos que quieres destacar teniendo en cuenta que deben ser <b>3</b> productos como <b>mínimo y 15 productos máximo</b>.'
                components={{ b: <b/> }}
              />
            </p>
            <DragDropContext onDragEnd={changeOrder}>
              <Droppable droppableId={customGroupDraft.id?.toString() || 'new'}>
                {providedDrop =>
                  <ul ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                    {products.map((product, index) =>
                      <Draggable
                        key={product.product_id}
                        draggableId={product.product_id.toString()}
                        index={index}
                      >
                        {providedDrag =>
                          <li
                            ref={providedDrag.innerRef}
                            {...providedDrag.draggableProps}
                            {...providedDrag.dragHandleProps}
                          >
                            <Icon type={IconType.DRAG}/>
                            {product.product_visible_name || product.product__name || product.product__tpv_name}
                            <Icon type={IconType.CLOSE} onClick={() => removeProduct(product.product_id)}/>
                          </li>
                        }
                      </Draggable>
                    )}
                    {providedDrop.placeholder}
                  </ul>
                }
              </Droppable>
            </DragDropContext>
          </>
        }
      </div>
    </CustomGroupProductsContainer>
  );
};
