import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Context as AuthContext } from '../../../../data/authContext';
import PayoutRow from "./PayoutRow"

const rowStyle = {
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '1fr 1fr 2fr 1fr 1fr 1fr .5fr',
	width: '100%',
  };

export const Payouts = ({payouts, setPayoutToSee, downloadPdf}) => {
	const {
		state: { selectedRestaurant, selectedDate, selectedDateTo }
	} = React.useContext(AuthContext);

	const { t } = useTranslation();
	let startDate = new Date(selectedDate);
	startDate.setHours( 0,0,0,0 );
	startDate = startDate.getTime();
	let endDate = new Date(selectedDateTo);
	endDate.setHours( 23,59,59,0 );
	endDate = endDate.getTime();
	let indent = 0;
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const pageSize = 15
	let payoutsToShow = JSON.parse(JSON.stringify(payouts)).slice(pageSize * (page - 1), pageSize * page)
	let hasNextPage = JSON.parse(JSON.stringify(payouts)).slice(pageSize * (page), pageSize * (page + 1)).length > 0

	return (
		<>
			<div style={{marginBottom: '25px', width: '90%', alignSelf: 'flex-start', zIndex: 1}}>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'center', marginTop: '10px'}} className="bg-white rounded-xl p-5 pt-0 w-full border-2 border-gray-200">
						<table className=" w-full">
							<thead className="sticky top-0 m-0 text-gray-600 z-50 bg-white">
							<tr
								style={rowStyle}>
								<th style={{fontSize: "15px"}} className="font-bold border-b-2 border-gray-200 py-4">Importe</th>
								<th style={{fontSize: "15px"}} className="font-bold border-b-2 border-gray-200 py-4">Estado</th>
								<th style={{fontSize: "15px"}} className="font-bold border-b-2 border-gray-200 py-4">Periodo</th>
								<th style={{fontSize: "15px"}} className="font-bold border-b-2 border-gray-200 py-4">Fecha</th>
								<th style={{fontSize: "15px"}} className="font-bold border-b-2 border-gray-200 py-4">IBAN</th>
								<th style={{fontSize: "15px"}} className="font-bold border-b-2 border-gray-200 py-4">Metodos de pago</th>
								<th style={{fontSize: "15px"}} className="font-bold border-b-2 border-gray-200 py-4"></th>
							</tr>
							</thead>
						<tbody
							style={{position: 'relative'}}
							>
						
							<div style={{position: 'relative'}}>
							{payoutsToShow && payoutsToShow.length > 0 ?
								payoutsToShow.map((payout, index) =>			
								<div>
									<PayoutRow								
										payout={payout}
										setPayoutToSee={setPayoutToSee}
										downloadPdf={downloadPdf}
										indent={indent}
									/>
								</div>) : <div style={{width: "100%", textAlign: 'center', marginTop: "15px", fontSize: "16px", fontWeight: 700}}>No hay registros para mostrar</div>}
								</div>
						</tbody>
					</table>
					{payoutsToShow && payoutsToShow.length > 0 &&
					<div style={{display: 'flex', gap: "30px", alignSelf: 'flex-end', margin: "15px 10px 0 0"}}>
					<div style={{cursor: 'pointer'}} onClick={() => page > 1 ? setPage(page - 1) : ""}>{"< Anterior"}</div>
					<div>{page} de {Math.ceil(payouts.length/pageSize)}</div>
					<div style={{cursor: 'pointer'}} onClick={() => hasNextPage ? setPage(page + 1) :  ""}>{"Siguiente >"}</div>
					</div>}
					</div>
					</div>
					</div>
			</>
	)
}

export default Payouts