import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';


export const TitleSettings = ({ title, setTitle, subtitle=null, setSubtitle=null }) => {
  const { t } = useTranslation();


  return (
    <div className='flex flex-col justify-center w-full gap-6 my-5'>
      <div className='flex flex-col gap-2'>
        <p>{t("write_title")}</p>
        <input
          type='text'
          placeholder='Ejemplo: "Elige tu experiencia"'
          className='yumminn-input'
          value={title}
          name='restaurant_group_title'
          maxLength={50}
          onChange={(e) => setTitle(e.target.value)}
          />
      </div>
      {setSubtitle &&
      <div className='flex flex-col gap-2'>
        <p>{t("write_subtitle")}</p>
        <input
          type='text'
          placeholder='Ejemplo: "Elige entre todas nuestras opciones"'
          className='yumminn-input'
          value={subtitle}
          name='restaurant_group_subtitle'
          maxLength={50}
          onChange={(e) => setSubtitle(e.target.value)}
          />
      </div>}
    </div>
  );
};

