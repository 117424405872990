import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

export const Popup = ({isOpen, close, children, className}) => {
  const backdropId = uuid();

  const popupClassName = ('yumminn_popup ' + (className || '')).trim();

  const onBackdropClick = (event) => {
    event.stopPropagation();
    event.target.id === backdropId && close?.();
  };

  if (!isOpen) return <></>

  return (
    <PopupContainer id={backdropId} onClick={onBackdropClick} className={popupClassName}>
      <div id="popupDiv">
        {children}
      </div>
    </PopupContainer>
  );
};

const PopupContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  z-index: 9999;
  background-color: #76767666;
  padding: 24px;

  & > div {
    background-color: #FFFFFF;
    border-radius: 32px;
    padding: 32px 40px;
    margin: auto;
    width: fit-content;
    box-shadow: 0 4px 8px #76767640;
    max-width: 100%;
    max-height: 100%;
  }

  & > .events{
    min-width: 1200px;
    min-height: 600px;
  } 

`;