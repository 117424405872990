export const parseSchedule = schedule => {
  return {
    id: schedule.schedule.id || null,
    tempId: schedule.schedule.tempId,
    name: schedule.schedule.name,
    days: schedule.schedule.days,
    time_ranges: schedule.time_range.map(range => ({
      id: range.id || null,
      start_hour: range.from[0],
      start_minutes: range.from[1],
      end_hour: range.to[0],
      end_minutes: range.to[1],
    })),
  };
};

export const parseCutomGroupSchedule = schedule => {
  return {
    schedule: {
      id: schedule.id || null,
      tempId: schedule.tempId,
      name: schedule.name,
      days: schedule.days,
    },
    time_range: schedule.time_ranges.map(range => ({
      id: range.id || null,
      from: [range.start_hour, range.start_minutes],
      to: [range.end_hour, range.end_minutes],
    })),
  };
};