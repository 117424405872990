import React from 'react';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { Popup } from 'common/Popup';
import { useTranslation } from 'react-i18next';
import { ButtonsFooterOneButton, ColumnContainer } from '../styles/SectorsAndTablesContainers';

const WarningPopup = ({ open, close }) => {

    const { t } = useTranslation();

    return (
        <Popup isOpen={open} close={close}>

            <div className='flex ml-8 text-[1.125rem]'>
                <ColumnContainer>
                    {t('You need to save the created sector first')}
                </ColumnContainer>

            </div>

            <hr></hr>

            <ButtonsFooterOneButton>     
                <Button 
                    size={BUTTON_SIZES.LG}
                    variant={BUTTON_VARIANTS.PRIMARY}
                    onClick={close}
                    label='ok'
                    className = 'mt-6 p-2'
                />
            </ButtonsFooterOneButton>
        </Popup>
    )
}

export default WarningPopup