import React, { useRef } from 'react';
import { FilePond } from 'react-filepond';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import StepperInput from 'common/StepperInput';
import { Icon, IconType } from 'common/Icon';
import { Button, BUTTON_VARIANTS } from 'common/Button';

export const LogoCustomization = ({ logo, setLogo, logoWidth=null, setLogoWidth=null, fieldName="logo" }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };


  const changeImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fd = new FormData();
      const url = file ? URL.createObjectURL(file) : null;
      file && fd.append(fieldName, file);
      setLogo({file, fd, url});
    }
  };


  const validateLogoWidth = (value) => {
    const width = !value ? null : Math.min(Math.abs(Number(value)), 100)

    if (typeof width !== 'number' && width !== null) {
      toast.error(t('invalid_value'))
      return null;
    }

    return width;
  };

  const onLogoWidthChange = (value) => {
    setLogoWidth(validateLogoWidth(value));
  } 

  return (
    <div className='flex flex-col justify-center gap-6 my-5'>
      <div className='flex gap-4 items-center text'>
        <Button
          onClick={handleButtonClick}
          label={logo ? "swap_photo" : "add_photo"}
          variant={BUTTON_VARIANTS[logo ? "SECONDARY" : "PRIMARY"]}
        />
        {logo &&
          <>
            <Icon type={IconType.OPEN} onClick={() => window.open(logo.url,"_blank")} size={23}/>
            <Icon type={IconType.TRASH} onClick={() => setLogo(null)} size={23}/>
          </>
        }
      </div>

      {/* HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
      <input
        type="file"
        accept="image/*" // Limit to image files
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={changeImage}
      />
      {/*HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
      <p><span className='font-semibold'>Sugerencias:</span> En color blanco y sin fondo (png) de 2MB máx.</p>
      {logoWidth && 
      <div className='flex items-center gap-4'>
        <h3 className='font-semibold text-[16px]'>{t('logo_size')}</h3>
        <StepperInput 
          value={logoWidth} 
          changeValueFunc={onLogoWidthChange} 
          disabledDown={logoWidth==1} 
          disabledUp={logoWidth==100}
          isPercentage={true}
          customWidth='100px'
        />
      </div>}
      
    </div>
  );
};
