import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import StepperInput from 'common/StepperInput';


export const SplashSettings = ({ colors, splashLogoWidth, setColors, setSplashLogoWidth }) => {
  const { t } = useTranslation();

  const validateLogoWidth = (value) => {
    const width = !value ? null : Math.min(Math.abs(Number(value)), 100)
  
    if (typeof width !== 'number' && width !== null) {
      toast.error(t('invalid_value'))
      return null;
    }
  
    return width;
  };
  
  const onSplashLogoWidthChange = (value) => {
    setSplashLogoWidth(validateLogoWidth(value));
  } 

  const onColorChange = color => setColors(prev => ({...prev, ...color}));

  const containerStyles = 'flex flex-col gap-2';
  const titleStyles = 'font-semibold leading-tight w-32';
  const getLabelStyles = `flex justify-between items-center w-min h-10 px-2 bg-white border-2 rounded-lg cursor-pointer z-10`;
  const inputStyles = 'absolute opacity-0 w-0 pointer-events-none';
  const swatchStyles = 'w-6 h-6 shadow-md rounded-full ml-2';

  return (
    <div className='flex flex-col gap-8 items-start my-5'>
      <div className={containerStyles}>
        <h3 className={titleStyles}>
          {t('color_cover')}
        </h3>
        <label className={getLabelStyles}>
          <span className='w-[7.5ch]'>{colors.secondary?.toUpperCase()}</span>
          <span className={swatchStyles} style={{backgroundColor: colors.secondary}}/>
          <input
            type='color'
            defaultValue={colors?.secondary_color}
            onInput={event => onColorChange({secondary: event.target.value})}
            className={inputStyles}
          />
        </label>
      </div>
      {splashLogoWidth && 
      <div className='flex items-center gap-4'>
        <h3 className='font-semibold text-[16px]'>{t('logo_size')}</h3>
        <StepperInput 
          value={splashLogoWidth} 
          changeValueFunc={onSplashLogoWidthChange} 
          disabledDown={splashLogoWidth==1} 
          disabledUp={splashLogoWidth==100}
          isPercentage={true}
          customWidth='100px'
        />
      </div>}
    </div>
  );
};

