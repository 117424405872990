import { components } from 'react-select';
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../data/authContext';
import { apiAuth, baseAuth } from "logic/api";
import { SelectInput } from 'common/Input';

export const TableSelect = () => {
    const {
		state: { selectedRestaurant },
    setSelectedTables,
	} = React.useContext(AuthContext);

	const { t } = useTranslation();

  const [tables, setTables] = useState([]);
  const [innerSelectedCategories, setInnerSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleChangeOption = (value) => {
    const result = value.map(option => tables.find(table => table.id === option.id))
    setInnerSelectedCategories(result)
    setSelectedTables(result)
  }

    const selectorOptions = tables.map((table) => ({
        "label": table.name,
        "value": table.id,
        "name": table.name,
        "id": table.id,
    }));
    
    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <label>{props.label}</label>
              <input
                className="checkboxRestaurantSelect"
                type="checkbox"
                checked={innerSelectedCategories.find((s_t) => s_t.id === props.value)}
                onChange={() => null()}
              />{" "}
              <span></span>
              </div>
            </components.Option>
          </div>
        );
      };

    useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setTables([])
		if (selectedRestaurant?.length, !isLoading) {
			getData();
		}
	}, [selectedRestaurant])

	const getData = async () => {
    setIsLoading(true);
    const endpoint = `/list/restaurant_tables`;
    const body = {restaurants: selectedRestaurant.map(restaurant => restaurant.value)};


		await apiAuth.post(endpoint, body)
    .then(response => {
      const newTables = response.data;
      setTables(newTables);
    })
    .catch(err => console.log(`PaymentsList.jsx:refreshSectorTables ~ endpoint=${endpoint} catch: ${err}`))
    .finally(() => setIsLoading(false));
  }


  return(
    <SelectInput
      label='Mesas'
      id='tables'
      placeholder='All'
      isMulti={true}
      multiValue={selectorOptions.filter(option => innerSelectedCategories.some(tab => tab.id === option.id))}
      options={selectorOptions}
      className={'w-80'}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      onChange={handleChangeOption}
      components={{Option}}
      isClearable={false}
    />
  );
};



