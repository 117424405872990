import bdpIcon from '../img/LogosTpv/bdp.png'
import centralCashIcon from '../img/LogosTpv/central-cash.png'
import deliverectIcon from '../img/LogosTpv/deliverect.png'
import hostelTactilIcon from '../img/LogosTpv/hosteltáctil.png'
import icgIcon from '../img/LogosTpv/ICG.png'
import lastAppIcon from '../img/LogosTpv/lastapp.png'
import neogrupIcon from '../img/LogosTpv/neogroup.png'
import oracleIcon from '../img/LogosTpv/Oracle.png'
import revoIcon from '../img/LogosTpv/revo.png'
import tahc4Icon from '../img/LogosTpv/tahc4.png'
import sighoreIcon from '../img/LogosTpv/sighore.png'
import sinqroIcon from '../img/LogosTpv/Sinqro.png'
import squareIcon from '../img/LogosTpv/square_logo.svg'
import techniWebIcon from '../img/LogosTpv/techniweb.png'
import zoneSoftIcon from '../img/LogosTpv/zonesoft.png'

export const POS_LIST = {
  "square": { "icon": squareIcon, "label": "Square" },
  "bdp": { "icon": bdpIcon, "label": "BDP" },
  "central_cash": { "icon": centralCashIcon, "label": "Central Cash" },
  "deliverect": { "icon": deliverectIcon, "label": "Deliverect" },
  "hostel_tactil": { "icon": hostelTactilIcon, "label": "HostelTáctil" },
  "icg": { "icon": icgIcon, "label": "ICG" },
  "last_app": { "icon": lastAppIcon, "label": "LastApp" },
  "neogrup": { "icon": neogrupIcon, "label": "Neogrup" },
  "oracle": { "icon": oracleIcon, "label": "Oracle" },
  "revo": { "icon": revoIcon, "label": "Revo" },
  "tahc4": { "icon": tahc4Icon, "label": "TAC4" },
  "sighore": { "icon": sighoreIcon, "label": "Sighore" },
  "sinqro": { "icon": sinqroIcon, "label": "Sinqro" },
  "techni_web": { "icon": techniWebIcon, "label": "TechniWeb" },
  "zonesoft": { "icon": zoneSoftIcon, "label": "ZoneSoft" }
};