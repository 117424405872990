import { BUTTON_VARIANTS, Button } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { TextInput } from 'common/Input';
import { Popup } from 'common/Popup';
import { ProductPopupMockup } from 'components/previews/components';
import { RestaurantGroupCardMockup } from 'components/previews/components/RestaurantGroupCardMockup';
import { cloneDeep } from 'lodash';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const RestaurantGroupCardPopup = ({restaurantGroupRestaurants, setRestaurantGroupRestaurants, selectedRestaurant, setSelectedRestaurant, cancel, theme}) => {
  const { t } = useTranslation();
  const [draftTitle, setDraftTitle] = useState(selectedRestaurant?.title);
  const [draftSubtitle, setDraftSubtitle] = useState(selectedRestaurant?.description);
  const [draftImage, setDraftImage] = useState(selectedRestaurant?.image);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };

  const changeImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fd = new FormData();
      const url = file ? URL.createObjectURL(file) : null;
      file && fd.append("image", file);
      setDraftImage({file, fd, url});
    }
  };


  const handleSave = () => {

    const groupCopy = cloneDeep(restaurantGroupRestaurants);
    const restaurantToEdit =  groupCopy.find((element) => element.restaurant.id == selectedRestaurant.restaurant.id);
    if (restaurantToEdit) {
      restaurantToEdit.title = draftTitle
      restaurantToEdit.description = draftSubtitle
      restaurantToEdit.image = draftImage
    }
    setRestaurantGroupRestaurants(groupCopy)
    setSelectedRestaurant(null)
  };


  return (
    <Popup isOpen={true} close={cancel}>
      <Container>
      <div className="flex gap-4 w-full px-2overflow-auto py-6">
        <RestaurantGroupCardMockup draftImage={draftImage} draftTitle={draftTitle} draftSubtitle={draftSubtitle} restaurantCard={selectedRestaurant} themeName={theme}/>
        <div className="flex flex-col gap-4 w-full max-w-[25rem]">
          <TextInput
            label='title'
            subtitle='by_default_restaurant_name'
            value={draftTitle}
            placeholder={t('title')}
            onChange={(e) => setDraftTitle(e.target.value)}
          />
          <TextInput
            label='subtitle'
            value={draftSubtitle}
            placeholder={"Ejemplo: “Tapas tradicionales, y..."}
            onChange={(e) => setDraftSubtitle(e.target.value)}
          />
          <div className="flex flex-row gap-5 items-center text">
            <Button
              onClick={handleButtonClick}
              label={selectedRestaurant.image ? "swap_photo" : "add_photo"}
              variant={BUTTON_VARIANTS[selectedRestaurant.image ? "SECONDARY" : "PRIMARY"]}
            />
            {draftImage &&
              <>
                <Icon type={IconType.OPEN} onClick={() => window.open(draftImage.url,"_blank")} size={23}/>
                <Icon type={IconType.TRASH} onClick={() => setDraftImage(null)} size={20}/>
              </>
            }
          </div>

        {/* HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
        <input
          type="file"
          accept="image/*" // Limit to image files
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={changeImage}
        />
        {/*HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
      </div>
    </div>
    <footer className={`flex flex-row justify-between items-center gap-4`}>
          <div className={`flex flex-row justify-end items-center gap-4 w-full`}>
            <Button
              label="cancel"
              onClick={() => setSelectedRestaurant(null)}
              disabled={false}
              variant={BUTTON_VARIANTS.SECONDARY}
            />
            <Button
              label={"save"}
              loading={false}
              disabled={false}
              onClick={handleSave}
            />
          </div>
        </footer>
      </Container>
    </Popup>
  );
};

const Container = styled.div`
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0.5em;
    max-width: 50ch;
  }
  
  p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 2em;
    max-width: 50ch;
  }

  & > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }
`;