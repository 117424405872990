import React, { useEffect, useState } from 'react';
import { TYPE_OF_TICKET_EVENT_COLORS } from './constants';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TicketSoldRowContainer } from './TicketsContainers';
import { NumberInput } from 'common/Input';
import { Icon, IconType } from 'common/Icon';

export const TicketSoldRow = ({ purchaseTicket, onClick, availableStock, setAvailableStock, setModifiedStocks, loading }) => {
  const { t } = useTranslation();
  const [editableStocks, setEditableStocks] = useState({}); // Track editable mode for each ticket

  const handleAvailableStock = (e, eventDateId, eventRecurringDateId, eventTicketId, eventTicketStockId=null ) => {
    const newStockValue = Number(e.target.value);

    setAvailableStock(prevState => {

      const entryExists = prevState.some(ticket =>
        (ticket.event_ticket_stock_id 
          ? ticket.event_ticket_stock_id === eventTicketStockId
          : (
            ticket.event_date_id === eventDateId &&
            ticket.event_recurring_date_id === eventRecurringDateId &&
            ticket.event_ticket_id === eventTicketId
          )
        )
      );

      if (entryExists) {
        return prevState.map(ticket => {

          return(
            (ticket.event_ticket_stock_id 
              ? ticket.event_ticket_stock_id === eventTicketStockId
              :(
                ticket.event_date_id === eventDateId &&
                ticket.event_recurring_date_id === eventRecurringDateId &&
                ticket.event_ticket_id === eventTicketId
              )
            )
            ? { ...ticket, stock: newStockValue }
            : ticket
            );
        })
         
      }
     
      return [
        ...prevState,
        {
          event_ticket_id: eventTicketId,
          event_ticket_stock_id: eventTicketStockId, // null if no stock object exists
          event_date_id: eventDateId,
          event_recurring_date_id: eventRecurringDateId,
          stock: newStockValue,
        },
      ];   
    });

     // Update modifiedStocks to only include changed entries
     setModifiedStocks(prevModified => {
      const isAlreadyModified = prevModified.some(
        ticket =>
          (ticket.event_ticket_stock_id 
            ? ticket.event_ticket_stock_id === eventTicketStockId
            :(
              ticket.event_date_id === eventDateId &&
              ticket.event_recurring_date_id === eventRecurringDateId &&
              ticket.event_ticket_id === eventTicketId
            )
          )
      );

      // Only add/update in modifiedStocks if there's a change in stock
      if (!isAlreadyModified) {
        return [
          ...prevModified,
          {
            event_ticket_id: eventTicketId,
            event_ticket_stock_id: eventTicketStockId,
            event_date_id: eventDateId,
            event_recurring_date_id: eventRecurringDateId,
            stock: newStockValue,
          },
        ];
      } else {
        return prevModified.map(ticket =>
          ticket.event_ticket_stock_id === eventTicketStockId ||
          (ticket.event_date_id === eventDateId &&
            ticket.event_recurring_date_id === eventRecurringDateId &&
            ticket.event_ticket_id === eventTicketId)
            ? { ...ticket, stock: newStockValue }
            : ticket
        );
      }
    });
  };

  const toggleEditableStock = (eventTicketStockId) => {
    setEditableStocks(prev => ({
      ...prev,
      [eventTicketStockId]: !prev[eventTicketStockId],
    }));
  };

  useEffect(() => {
    const initialEditableState = purchaseTicket.ticket_type.reduce((acc, ticket) => {
        acc[ticket.eventTicketStockId] = ticket.eventTicketStockId ? true : false;
        return acc;
      }, {});
      setEditableStocks(initialEditableState);

  }, [purchaseTicket?.ticket_type]);


  console.log(availableStock, "availableStock");

  return (
    <TicketSoldRowContainer onClick={() => onClick(purchaseTicket?.id)}>
      <div className="column divDates">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span className="weekday">{t(moment(purchaseTicket?.date).format('dddd'))}</span>
          <span className="date">{moment(purchaseTicket?.date).format('DD/MM/YYYY')}</span>
          <span className="time">{purchaseTicket?.time ? `${purchaseTicket?.time.slice(0, 5)} h` : ''}</span>
        </div>
      </div>

      <div className="column event_name">
        {purchaseTicket?.ticket_type?.map((ticket, indexEventName) => {
          const newIndex = indexEventName % 3;
          return (
            <span key={indexEventName} style={{ backgroundColor: TYPE_OF_TICKET_EVENT_COLORS[newIndex] }}>
              {ticket?.ticketName}
            </span>
          );
        })}
      </div>

      <div className="column available">
        {purchaseTicket?.ticket_type.map((ticket, indexAvailable) => {
          const isEditable = editableStocks[ticket.eventTicketStockId];
          const stockValue = availableStock.find(stock => 
            (ticket.eventTicketStockId 
              ? stock.event_ticket_stock_id === ticket.eventTicketStockId 
              : (stock.event_date_id === ticket.eventDateId || stock.event_recurring_date_id === ticket.eventRecurringDateId) && stock.event_ticket_id === ticket.eventTicketId
            )
          )?.stock || 0;

          return (
            <span key={indexAvailable}>
              {!isEditable ? (
                <span
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                  onClick={() => toggleEditableStock(ticket.eventTicketStockId)}
                >
                  {t('unlimited')}
                </span>
              ) : (
                <NumberInput
                  type="number"
                  pattern="[0-9]*[.,]?[0-9]+"
                  min={0}
                  className="available_stock"
                  name="available_stock"
                  value={stockValue}
                  onChange={(e) => handleAvailableStock(e, ticket.eventDateId, ticket.eventRecurringDateId, ticket.eventTicketId, ticket.eventTicketStockId)}
                  //onBlur={handleBlur}
                  rightIcon={<Icon type={IconType.PENCIL} />}
                  fontSize={'1.15rem'}
                  disabled={loading}
                  height={"40px"}
                />
              )}
            </span>
          );
        })}
      </div>

      <div className="column sold">
        {purchaseTicket?.ticket_type.map((ticket, indexSold) => (
          <span key={indexSold}>
            {ticket?.soldTickets}
          </span>
        ))}
      </div>
    </TicketSoldRowContainer>
  );
};
