import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import "./Spinner.css"
import React from 'react'
import {useTranslation} from "react-i18next";

const StepperInput = ({value, changeValueFunc, disabledUp = false, disabledDown = false, step = 1, isPercentage=false, customWidth='auto'}) => {
    const {t} = useTranslation()
    return (
        <div style={{display: "flex", justifyContent: "space-between", border: "1px solid #D4D4D8", borderRadius: "8px", height: "40px", width: customWidth, alignItems: 'center', padding: "2px", cursor: 'default'}}>
            <div style={{marginLeft: "5px"}}>
                {value}{value && isPercentage ? '%' : ''}
            </div>
            {value &&
            <div style={{backgroundColor: "#F1F1F1", height: "35px", width: "20px", borderRadius: "5px",  display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '2px'}}>
            <ChevronUpIcon onClick={() => !disabledUp && changeValueFunc(value + step)} style={{color: disabledUp ? "#A3A3A3" : "#000000",  cursor: disabledUp ? "not-allowed" : "pointer"}}/>
            <ChevronDownIcon onClick={() => !disabledDown && changeValueFunc(value - step)} style={{color: disabledDown ? "#A3A3A3" : "#000000", cursor: disabledDown ? "not-allowed" : "pointer"}}/>
            </div>}
        </div>
    )
}

export default StepperInput;


