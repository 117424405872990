import React, { useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ProductHighlightRow from '../OrderAndPay/ProductHighlightRow'
import { useTranslation } from 'react-i18next';
import { ProductHighlightTableContainer } from './ProductHighlightContainers';
import { toast } from 'react-toastify';

export const ProductHighlightTable = ({
  title,
  customGroups,
  changeGroupOrder,
  toggleActive,
  isValidating,
  droppableId,
  setCustomGroupToRemove,
  setCustomGroupToEdit,
}) => {
  const { t } = useTranslation();

	const handleDragEnd = async ({ source, destination }) => {
    if (!destination || source.droppableId !== destination.droppableId) return;

    const [customGroup] = customGroups.splice(source.index, 1);

    customGroups.splice(destination.index, 0, customGroup);
    
    const newOrderList = customGroups.map((customGroup, index) => ({ ...customGroup, order: index }));

    await changeGroupOrder(newOrderList);
  };

  useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'));

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true);
		}
	}, []);

  return (
    <ProductHighlightTableContainer className={`${isValidating ? 'loading' : ''}`}>
      <header>{t(title)}</header>
      <table>
        <thead>
          <tr>
            <th className='col_name'><h3>{t('highlight_name')}</h3></th>
            <th className='col_content'><h3>{t('content')}</h3></th>
            <th className='col_type'><h3>{t('type')}</h3></th>
            <th className='col_schedules'><h3>{t('schedules')}</h3></th>
            <th className='col_status'><h3>{t('status')}</h3></th>
            <th className='col_icon'/>
            <th className='col_icon'/>
            <th className='col_icon'/>
          </tr>
        </thead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId={droppableId}
            direction="vertical"
            style={{ width: '100%' }}
            isDropDisabled={isValidating}
          >
          {(providedList) =>
            <tbody ref={providedList.innerRef} {...providedList.droppableProps}>
              {customGroups.map((custom_group, index) =>
                <Draggable
                  key={custom_group.id}
                  index={index}
                  draggableId={custom_group.id.toString()}
                  isDragDisabled={isValidating}
                >
                  {(provided) => (
                    <tr ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                      <ProductHighlightRow
                        custom_group={custom_group}
                        setCustomGroupToRemove={setCustomGroupToRemove}
                        setCustomGroupToEdit={setCustomGroupToEdit}
                        toggleActive={toggleActive}
                        isValidating={isValidating}
                      />
                    </tr>
                  )}
                </Draggable>
              )}
              {providedList.placeholder}
            </tbody>
          }
          </Droppable>
        </DragDropContext>
      </table>
    </ProductHighlightTableContainer>
  );
};