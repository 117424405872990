import { Context } from 'data/authContext';
import { baseAuth } from 'logic/api';
import React, { useContext } from 'react';
import useSWR from 'swr';

const groupByLevel = (groups, level) => Object.entries(
  groups.reduce((obj, group) => {
    const levelId = group[level];
    if (levelId in obj) {
      obj[levelId].push(group);
    } else {
      obj[levelId] = [group];
    }

    return obj;
  }, {})
);

const parseCustomGroups = data => {
  const custom_groups = data.product_highlights;

  const restaurantGroups = custom_groups.filter(c_g => c_g.restaurant != null);
  const l1Groups = groupByLevel(custom_groups.filter(c_g => c_g.l1 != null), 'l1');
  const l2Groups = groupByLevel(custom_groups.filter(c_g => c_g.l2 != null), 'l2');
  const l3Groups = groupByLevel(custom_groups.filter(c_g => c_g.l3 != null), 'l3');

  return { restaurantGroups, l1Groups, l2Groups, l3Groups };
}

const addLevelName = (catalog, groups, level) => groups.forEach(group => {
  const levelObj = catalog?.[level]?.find(lv => lv.id === Number(group[0]));
  const name = levelObj?.name || levelObj?.tpv_name || '';
  
  group.splice(1, 0, name);
})
;

const fetcher = async endpoint => {
  const response = await baseAuth.get(endpoint);
  const catalog = response.data.catalog;

  const { restaurantGroups, l1Groups, l2Groups, l3Groups } = parseCustomGroups(response.data);
  
  addLevelName(catalog, l1Groups, 'supergroups');
  addLevelName(catalog, l2Groups, 'category_groups');
  addLevelName(catalog, l3Groups, 'product_categories');

  return { catalog, restaurantGroups, l1Groups, l2Groups, l3Groups };
};

export const useProductHighlight = () => {
	const { state: { selectedRestaurantId } } = useContext(Context);
  
  const { data, isLoading, isValidating, mutate } = useSWR(
    `/custom_group/custom_groups?restaurant=${selectedRestaurantId}`,
    fetcher,
    { revalidateOnFocus: false },
  );

  const updateData = async newData => mutate(prev => {
    const { restaurantGroups, l1Groups, l2Groups, l3Groups } = parseCustomGroups(newData);

    addLevelName(prev.catalog, l1Groups, 'supergroups');
    addLevelName(prev.catalog, l2Groups, 'category_groups');
    addLevelName(prev.catalog, l3Groups, 'product_categories');

    return { ...prev, restaurantGroups, l1Groups, l2Groups, l3Groups };
  });

  const changeActiveState = async (custom_group, new_state) => {
    try {
      custom_group.active = new_state
      custom_group.type = 1
    
      let custom_group_f_d = new FormData();
    
      for ( var key in custom_group ) {
        if (!["products_info", "visibility_schedules"].includes(key)) {
          custom_group_f_d.append(key, custom_group[key] != null ? custom_group[key] : "");
        }
      }
    
      custom_group_f_d.append("products_info", JSON.stringify(custom_group["products_info"]));
      custom_group_f_d.append("visibility_schedules", JSON.stringify(custom_group["visibility_schedules"]));
    
      const response = await baseAuth(`/custom_group/update_custom_group`, {
        method: "POST",
        "Content-Type": "multipart/form-data",
        data: custom_group_f_d,
      })
    
      await updateData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

	const saveCustomGroup = async (customGroup, remove = false) => {
    let formData = new FormData();
    
    Object.entries(customGroup).forEach(([key, value]) => {
      if (["products_info", "visibility_schedules"].includes(key)) {
        formData.append(key, JSON.stringify(value));
      } else if (["image", "default_product_image"].includes(key)) {
        formData.append(key, value.file);
      } else {
        formData.append(key, value || '');
      };
    });

    formData.append('type', remove ? 0 : 1);

    const response = await baseAuth(`/custom_group/update_custom_group`, {
      method: "POST",
      "Content-Type": "multipart/form-data",
      data: formData,
    });
    console.log(`🐉 > file: useProductHighlight.js:113 > saveCustomGroup > response:`, response)

    await updateData(response.data);

    return response.data.custom_group;
	};

	const deleteCustomGroup = customGroup => saveCustomGroup(customGroup, true);

  const toggleActive = async customGroup => {
    if (!customGroup.id) {
      return !customGroup.active;
    }

    const response = await baseAuth.post('/custom_group/toggle_custom_group_active', {id: customGroup.id});
    const active = response.data.active;

    const position = [];

    ['restaurant', 'l1', 'l2', 'l3'].forEach(key => {
      if (customGroup[key]) {
        position.push(key, customGroup.key);
      };
    });

    mutate(prev => {
      const level = position[0] + 'Groups';
      const newGroups = [...prev[level]];
      const index = newGroups.findIndex(group => group.id === customGroup.id);

      if (index !== -1) {
        newGroups[index].active = active;
      }
      
      return { ...prev, [level]: newGroups };
    });

    return active;
  };

	const changeGroupOrder = async (newOrderList) => {
    try {
      const data = {new_order_list: newOrderList, restaurant_id: selectedRestaurantId};
  
      const response = await baseAuth.post(`/custom_group/update_custom_group_order`, data);
  
      await updateData(response.data);
    } catch (error) {
      console.log(`🐉 > file: useProductHighlight.js:180 > changeGroupOrder > error:`, error);
    }
	};

  return {
    restaurant: selectedRestaurantId,
    catalog: data?.catalog || null,
    restaurantGroups: data?.restaurantGroups || [],
    l1Groups: data?.l1Groups || [],
    l2Groups: data?.l2Groups || [],
    l3Groups: data?.l3Groups || [],
    isLoading,
    isValidating,
    saveCustomGroup,
		deleteCustomGroup,
    toggleActive,
    changeGroupOrder,
    changeActiveState,
  };
};
