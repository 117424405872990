import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Context as AuthContext } from '../../../../data/authContext';
import TopProductRow from "./TopProductRow"
import { OrderIndicator } from "common/OrderIndicator";
import { Pagination } from "common/Pagination";

const rowStyle = {
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '.1fr .5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
  gap: '0.5rem',
	width: '100%',
};

export const TopProductsTable = ({data, itemsQuantitySum, itemsAmountSum}) => {
	const {
		state: { selectedDate, selectedDateTo }
	} = React.useContext(AuthContext);

	const { t, lang } = useTranslation();

	const [page, setPage] = useState(1);
	const [sortBy, setSortBy] = useState('');

	let startDate = new Date(selectedDate);
	startDate.setHours( 0,0,0,0 );
	startDate = startDate.getTime();

	let endDate = new Date(selectedDateTo);
	endDate.setHours( 23,59,59,0 );
	endDate = endDate.getTime();

	const indent = 0;
	const pageSize = 10

	const getWeightUnits = item => item.quantity / itemsQuantitySum * 100;
	const getTotalBilling = item => item.tpv_price_cents * item.quantity;
	const getWeightBilling = item => item.tpv_price_cents * item.quantity / itemsAmountSum;

	const getAttrOrder = attr => {
		if (sortBy.replace('-', '') !== attr) return 0;
		return sortBy.startsWith('-') ? -1 : 1;
	};

	const collator = new Intl.Collator(lang || 'es');

	const sortedData = sortBy ? [...data].sort((a, b) => {
		const attr = sortBy.replace('-', '');
		const order = getAttrOrder(attr);

		if (attr === 'tpv_name') return collator.compare(a.tpv_name, b.tpv_name) * order;
		if (attr === 'weight_units') return (getWeightUnits(a) - getWeightUnits(b)) * order;
		if (attr === 'total_billing') return (getTotalBilling(a) - getTotalBilling(b)) * order;
		if (attr === 'weight_billing') return (getWeightBilling(a) - getWeightBilling(b)) * order;
		return (a[attr] - b[attr]) * order;
	}) : data;

	const itemsToShow = sortedData.slice(pageSize * (page - 1), pageSize * page);

	const toggleOrder = attr => {
		const prevOrder = getAttrOrder(attr);

		if (prevOrder === -1) setSortBy('');
		if (prevOrder === 0) setSortBy(attr);
		if (prevOrder === 1) setSortBy('-' + attr);
	};

	return (
		<>
			<div style={{margin: '24px 0', width: '100%', alignSelf: 'flex-start', zIndex: 1}}>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'center'}} className="bg-white rounded-xl p-5 pt-2 w-full border border-gray-200">
						<table className="w-full">
							<thead className="sticky top-0 m-0 text-gray-600 z-50 bg-white" style={{boxShadow: '0 1px 0 #E4E4E7'}}>
								<tr style={rowStyle}>
									<th style={{fontSize: "15px"}} className="py-4"/>
									<th style={{fontSize: "15px"}} className="py-4"/>
									<th style={{fontSize: "15px"}} className="font-medium py-4">
										<button onClick={() => toggleOrder('tpv_name')} className="flex justify-center items-center gap-2 h-full w-full focus:outline-none">
											<span className="capitalize-first">
												{t('item')}
											</span>
											<OrderIndicator order={getAttrOrder('tpv_name')}/>
										</button>
									</th>
									<th style={{fontSize: "15px"}} className="font-medium py-4">
										<button onClick={() => toggleOrder('tpv_price_cents')} className="flex justify-center items-center gap-2 h-full w-full focus:outline-none">
											<span className="capitalize-first">
												{t('unit_price')}
											</span>
											<OrderIndicator order={getAttrOrder('tpv_price_cents')}/>
										</button>
									</th>
									<th style={{fontSize: "15px"}} className="font-medium py-4">
										<button onClick={() => toggleOrder('quantity')} className="flex justify-center items-center gap-2 h-full w-full focus:outline-none">
											<span className="capitalize-first">
												{t('units_sold')}
											</span>
											<OrderIndicator order={getAttrOrder('quantity')}/>
										</button>
									</th>
									<th style={{fontSize: "15px"}} className="font-medium py-4">
										<button onClick={() => toggleOrder('units_prepared')} className="flex justify-center items-center gap-2 h-full w-full focus:outline-none">
											<span className="capitalize-first">
												{t('units_prepared')}
											</span>
											<OrderIndicator order={getAttrOrder('units_prepared')}/>
										</button>
									</th>
									<th style={{fontSize: "15px"}} className="font-medium py-4">
										<button onClick={() => toggleOrder('units_not_delivered')} className="flex justify-center items-center gap-2 h-full w-full focus:outline-none">
											<span className="capitalize-first">
												{t('units_not_delivered')}
											</span>
											<OrderIndicator order={getAttrOrder('units_not_delivered')}/>
										</button>
									</th>
									<th style={{fontSize: "15px"}} className="font-medium py-4">
										<button onClick={() => toggleOrder('units_delivered')} className="flex justify-center items-center gap-2 h-full w-full focus:outline-none">
											<span className="capitalize-first">
												{t('units_delivered')}
											</span>
											<OrderIndicator order={getAttrOrder('units_prepared')}/>
										</button>
									</th>
									<th style={{fontSize: "15px"}} className="font-medium py-4">
										<button onClick={() => toggleOrder('weight_units')} className="flex justify-center items-center gap-2 h-full w-full focus:outline-none">
											<span className="capitalize-first">
												{t('weight_units')}
											</span>
											<OrderIndicator order={getAttrOrder('weight_units')}/>
										</button>
									</th>
									<th style={{fontSize: "15px"}} className="font-medium py-4">
										<button onClick={() => toggleOrder('total_billing')} className="flex justify-center items-center gap-2 h-full w-full focus:outline-none">
											<span className="capitalize-first">
												{t('total_billing')}
											</span>
											<OrderIndicator order={getAttrOrder('total_billing')}/>
										</button>
									</th>
									<th style={{fontSize: "15px"}} className="font-medium py-4">
										<button onClick={() => toggleOrder('weight_billing')} className="flex justify-center items-center gap-2 h-full w-full focus:outline-none">
											<span className="capitalize-first">
												{t('weight_billing')}
											</span>
											<OrderIndicator order={getAttrOrder('weight_billing')}/>
										</button>
									</th>
								</tr>
							</thead>
							<tbody style={{position: 'relative'}}>
								<div style={{position: 'relative'}}>
								{itemsToShow && itemsToShow.length > 0 ?
									itemsToShow.map((item) =>
									<div key={item.id}>
										<TopProductRow
											product={item}
											totalProducts={itemsQuantitySum}
											totalAmount={itemsAmountSum}
											indent={indent}
											page={page}
										/>
									</div>) : <div style={{width: "100%", textAlign: 'center', marginTop: "15px", fontSize: "16px", fontWeight: 700}}>No hay registros para mostrar</div>}
									</div>
							</tbody>
						</table>
						<div style={{display: 'flex', alignSelf: 'center', marginTop: '16px'}}>
							<Pagination
								page={page}
								numOfEntries={data.length}
								numOfPages={Math.ceil(data.length/pageSize)}
								setPage={setPage}
								disabled={!itemsToShow?.length}
								entryName='item'
							/>
						</div>
					</div>
				</div>
			</div>
			</>
	)
}

export default TopProductsTable