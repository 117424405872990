import { Checkbox } from 'common/Input';
import { ProductCardMockup } from 'components/previews/components';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CompressedCardDesign from '../../../../../img/CompactMenuCard.svg'
import ExpandedCardDesign from '../../../../../img/OpenMenuCard.svg'


export const MenuSettings = ({ expandedProductCards, setExpandedProductCards }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col gap-8 items-center my-5'>
      <div className='w-[90%] flex flex-col gap-5 items-center'>
        <h2 className={'text-xl font-semibold leading-tight'}>
          {t('cards_content')}:
        </h2>
        <ProductCardMockup allergens biggest description={'long'} truncate={!expandedProductCards}/>
      </div>
      <div className='w-[90%] flex flex-col gap-5 items-center'>
        <div className='flex justify-between w-full'>
          <div className='flex items-center gap-2'>
            <img style={{height: "20px"}} src={CompressedCardDesign} alt=''/>
            <p className='text-[16px] font-semibold'>{t('see_compressed')}<span className='font-normal italic'>{` (${t('default')})`}</span></p>
          </div>
          <Checkbox checked={!expandedProductCards} onChange={() => expandedProductCards && setExpandedProductCards(false)}/>

        </div>
        <div className='flex justify-between w-full'>
          <div className='flex items-center gap-2'>
            <img style={{height: "20px"}} src={ExpandedCardDesign} alt=''/>
            <p className='text-[16px] font-semibold'>{t('see_expanded')}</p>
          </div>
          <Checkbox checked={expandedProductCards} onChange={() => !expandedProductCards && setExpandedProductCards(true)}/>
        </div>
      </div>
    </div>
  );
};
