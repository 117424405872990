import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { baseAuth } from "logic/api";
import Spinner from "common/Spinner";
import { toast } from 'react-toastify';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Context as AuthContext } from '../../../data/authContext';
import DateRangeSelect from "components/yumminn/DateRangeSelect";
import WaitersTable from "./WaitersComponents/WaitersTable";
import WaiterSelect from "./WaitersComponents/WaiterSelect";

export const Waiters = () => {
	const {
		state: { selectedRestaurantId, selectedDate, selectedDateTo }
	} = React.useContext(AuthContext);
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [selectedWaiters, setSelectedWaiters] = useState([]);	
	const [paymentsData, setPaymentsData] = useState(null);	
	const [reviewsData, setReviewsData] = useState(null);	
	const [waitersData, setWaitersData] = useState(null);	
	const selectedWaitersIds = selectedWaiters.map(s_w => s_w.id)
	useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))
		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setReviewsData(null)
		setPaymentsData(null)
		setWaitersData(null)
		if (selectedRestaurantId && selectedDate && selectedDateTo && !loading) {
			getData();
		}
	}, [selectedRestaurantId, selectedDate, selectedDateTo])

	const getData = async () => {
		let startDate = new Date(selectedDate);
		startDate.setHours( 0,0,0,0 );
		startDate = startDate.getTime();
		let endDate = new Date(selectedDateTo);
		endDate.setHours( 23,59,59,0 );
		endDate = endDate.getTime();

		setLoading(true)

		await baseAuth.get(`/restaurant/waiters_info?restaurant=${selectedRestaurantId}&from=${startDate}&to=${endDate}`)
			.then((response) => {
				const data = response.data
				setReviewsData(data.reviews_data)
				setPaymentsData(data.payments_data)
				setWaitersData(data.waiters_data)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
			})
	}

	return (
		<>
			<div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
				<div style={{display: 'flex', width: "90%", alignItems: 'center', alignSelf: 'flex-start', gap: "15px"}}>
					<DateRangeSelect hourFormat={false} defaultRange="last_month"/>
					<WaiterSelect waiterData={waitersData} setWaiters={setSelectedWaiters}/>
				</div>
				{loading ?
					<>
						<div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center">
							<Spinner>{t("Loading")}</Spinner>
						</div>
						<div className="bg-black opacity-20 top-0 left-0 fixed overflow-y-auto h-full w-full z-9999 flex flex-col justify-center items-center"/>
					</> :
					<>
						<WaitersTable paymentsData={paymentsData} reviewsData={reviewsData} setWaitersData={setWaitersData} waitersData={selectedWaitersIds.length > 0 ? waitersData.filter(w_d => selectedWaitersIds.includes(w_d.id)) : waitersData}/>
					</>
				}
			</div>
		</>
	)
}

export default Waiters;
