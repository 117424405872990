import { apiAuth, baseAuth } from '../logic/api';
import createDataContext from './createDataContext';
import i18next from "i18next";

const USERS_ALLOWED_TO_HAVE_DEMO_RESTAURANTS = ["test@yumminn.com", "miguel.lobato@sinqro.com", "florian.poirot@sinqro.com", "demo@zerosix.com", "soportehosteltactilrd@gmail.com"]

// REDUCER
const authReducer = (state, action) => {
  switch (action.type) {
    case 'SIGNIN':
      return { ...state, loading: false, user: action.payload.user };

    case 'UPDATE_PROFILE':
      return { ...state, loading: false, user: action.payload.user };

    case 'RESTORE_TOKEN':
      return {
        ...state,
        loading: false,
        userToken: action.payload.token,
        user: action.payload.user,
        restaurants: action.payload.restaurants,
        selectedCompany: action.payload.selectedCompany,
        selectedMonth: action.payload.selectedMonth,
        error: null,
      };
      
    case 'RESTAURANTS':
      const newSelectedRestaurant = action.payload.selectedRestaurant;
      return {
        ...state,
        restaurants: action.payload.restaurants,
        selectedRestaurant: newSelectedRestaurant,
        selectedRestaurantId: newSelectedRestaurant.length === 1 ? newSelectedRestaurant[0].value : null
      };
    case 'VALIDATE_ACTIVATION':
      return { ...state, loading: false, user: action.payload.user };

    case 'SIGNOUT':
      return { ...state, loading: false, userToken: null };

    case 'AUTH_LOADING':
      return { ...state, loading: true };

    case 'AUTH_ERROR':
      return { ...state, error: action.payload, loading: false };

    case 'CLEAR_ERROR':
      return { ...state, error: null };

    case 'SET_RESTAURANT':
      return {
        ...state,
        selectedRestaurant: action.payload.selectedRestaurant,
        selectedRestaurantId: action.payload.selectedRestaurantId,
      };
    case 'SET_COMPANY':
      return {
        ...state,
        selectedCompany: action.payload.selectedCompany,
      };
    case 'SET_YEAR':
      return {
        ...state,
        selectedYear: action.payload.selectedYear,
      };
    case 'SET_YEAR_TO':
      return {
        ...state,
        selectedYearTo: action.payload.selectedYearTo,
      };
    case 'SET_MONTH':
      return {
        ...state,
        selectedMonth: action.payload.selectedMonth,
      };
    case 'SET_MONTH_TO':
      return {
        ...state,
        selectedMonth: action.payload.selectedMonthTo,
      };
    case 'SET_DATE':
      return {
        ...state,
        selectedDate: action.payload.selectedDate,
      };
    case 'SET_DATE_TO':
      return {
        ...state,
        selectedDateTo: action.payload.selectedDateTo,
      };
    case 'SET_START_TIME':
      return {
        ...state,
        selectedStartTime: action.payload.selectedStartTime,
      };
    case 'SET_END_TIME':
      return {
        ...state,
        selectedEndTime: action.payload.selectedEndTime,
      };
    case 'SET_REVIEW_FILTER':
      return {
        ...state,
        selectedReviewFilter: action.payload.filter,
      };
    case 'SET_WAITER':
        return {
          ...state,
          selectedWaiter: action.payload.waiter,
        };
    case 'SET_AGENTS_RESTAURANTS':
    return {
      ...state,
      selectedAgentsRestaurants: action.payload.agentsRestaurants,
    };
    case 'SET_AGENTS_PERIOD':
      return {
        ...state,
        selectedAgentsPeriod: action.payload.agentsPeriod,
      };
    case 'SET_AGENTS_INTERVAL':
        return {
          ...state,
          selectedAgentsInterval: action.payload.agentsInterval,
        };
    case 'SET_ISLOGGEDIN':
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
      };
    case 'SET_SELECTED_PRODUCT_CATEGORIES':
      return {
        ...state,
        selectedProductCategories: action.payload.selectedProductCategories
      }
    case 'SET_SELECTED_TABLES':
      return {
        ...state,
        selectedTables: action.payload.selectedTables
      }
    case 'SET_TOP_PRODUCTS':
      return {
        ...state,
        topProducts: action.payload.topProducts
      }
    case 'SET_TOP_MODIFIERS':
      return {
        ...state,
        topModifiers: action.payload.topModifiers
      }
    case 'SET_ACCESS_TYPE':
      return {
        ...state,
        accessType: action.payload.accessType
      }
    case 'SET_ALLOWED_ROUTES':
      return {
        ...state,
        allowedRoutes: action.payload.allowedRoutes
      }
    default:
      return state;
  }
};

// ACTIONS

const loadUser = (dispatch) => {
  return async (token) => {
    dispatch({ type: 'AUTH_LOADING' });
    //
    if (token) {
      try {
        const tuser = await apiAuth.get(`/users/retrieve`);
        if (tuser.data.is_admin) {
          
          const filteredUser = {};
          filteredUser.is_superuser = tuser.data.is_superuser
          filteredUser.email = tuser.data.email
          filteredUser.rol = tuser.data.rol

          localStorage.setItem(
            'yumminn-user',
            JSON.stringify(filteredUser)
          );
          
          //const trestaurants = await apiAuth.get(`/restaurants`)
          const trestaurants = await baseAuth.get(`/restaurant/list`);

          // arrange restaurants list for select  
          let restaurant_list = []; 
          if(USERS_ALLOWED_TO_HAVE_DEMO_RESTAURANTS.includes(tuser.data.email)){
            restaurant_list = trestaurants.data.results.map((r) => ({
              value: r.id,
              label: r.name,
              show_order_and_pay_tab_on_backoffice: r.show_order_and_pay_tab_on_backoffice,
              show_lockers_page: r.show_lockers_page,
              show_events_page: r.show_events_page,
              show_wallet_page: r.show_wallet_page,
              custom_catalog: r.custom_catalog,
              is_order_active: r.is_order_active,
              allow_booking: r.allow_booking,
              default_language: r.default_language,
              use_sector_pricing: r.use_sector_pricing
            }))
          } else{
            restaurant_list = trestaurants.data.results.map((r) => ({
              value: r.id,
              label: r.name,
              demo: r.demo,
              show_order_and_pay_tab_on_backoffice: r.show_order_and_pay_tab_on_backoffice,
              show_lockers_page: r.show_lockers_page,
              show_events_page: r.show_events_page,
              show_wallet_page: r.show_wallet_page,
              custom_catalog: r.custom_catalog,
              is_order_active: r.is_order_active,
              allow_booking: r.allow_booking,
              default_language: r.default_language,
              use_sector_pricing: r.use_sector_pricing
            })).filter((r => r.demo === false));
          }
          localStorage.setItem(
            'yumminn-restaurants',
            JSON.stringify(restaurant_list)
          );
          localStorage.setItem(
            'yumminn-selectedRestaurant',
            JSON.stringify([restaurant_list[0] || null])
          );
          dispatch({
            type: 'RESTORE_TOKEN',
            payload: {
              token,
              user: tuser.data
            },
          })
        } else {
          throw Error('Is not admin');
        }
      } catch (e) {
        dispatch({ type: 'RESTORE_TOKEN', payload: { token: null } });
      }
    } else {
      dispatch({ type: 'RESTORE_TOKEN', payload: { token: null } });
    }
  };
};

const reloadRestaurants = (dispatch) => {
  return async (selectedRestaurant) => {
    const tuser = JSON.parse(localStorage.getItem("yumminn-user"))
    
    if (tuser) {
      //const trestaurants = await apiAuth.get(`/restaurants`)
      const trestaurants = await baseAuth.get(`/restaurant/list`);

      // arrange restaurants list for select  
      let restaurant_list = [];
      if (USERS_ALLOWED_TO_HAVE_DEMO_RESTAURANTS.includes(tuser.email)) {
        restaurant_list = trestaurants.data.results.map((r) => ({
          value: r.id,
          label: r.name,
          show_order_and_pay_tab_on_backoffice: r.show_order_and_pay_tab_on_backoffice,
          show_lockers_page: r.show_lockers_page,
          show_events_page: r.show_events_page,
          show_wallet_page: r.show_wallet_page,
          custom_catalog: r.custom_catalog,
          is_order_active: r.is_order_active,
          allow_booking: r.allow_booking,
          default_language: r.default_language,
          use_sector_pricing: r.use_sector_pricing
        }))
      } else {
        restaurant_list = trestaurants.data.results.map((r) => ({
          value: r.id,
          label: r.name,
          demo: r.demo,
          show_order_and_pay_tab_on_backoffice: r.show_order_and_pay_tab_on_backoffice,
          show_lockers_page: r.show_lockers_page,
          show_events_page: r.show_events_page,
          show_wallet_page: r.show_wallet_page,
          custom_catalog: r.custom_catalog,
          is_order_active: r.is_order_active,
          allow_booking: r.allow_booking,
          default_language: r.default_language,
          use_sector_pricing: r.use_sector_pricing
        })).filter((r => r.demo === false));
      }
      
      localStorage.setItem(
        'yumminn-restaurants',
        JSON.stringify(restaurant_list)
      );

      //compares the restaurants from /restaurants with selectedRestaurants, and updates said array   
      if(selectedRestaurant){
        let reloadedSelectedRestaurant = restaurant_list.filter((restaurant)=>selectedRestaurant.some((selected) => selected.value === restaurant.value))    
        if(!Array.isArray(reloadedSelectedRestaurant)){
          reloadedSelectedRestaurant = [reloadedSelectedRestaurant]
        }
        dispatch({
          type: 'RESTAURANTS',
          payload: {
            selectedRestaurant: reloadedSelectedRestaurant,
            restaurants: restaurant_list
          },
        });
      }else{
        dispatch({
          type: 'RESTAURANTS',
          payload: {
            restaurants: restaurant_list,
            selectedRestaurant: [restaurant_list[0]],
          },
        });
      }
    }
  }
}

const setIsLoggedIn = (dispatch) => {
  return async (isLoggedIn) => {
    dispatch({ type: 'SET_ISLOGGEDIN', payload: { isLoggedIn } });
  };
};

const loadSuperUser = (dispatch) => {
  return async (token) => {
    dispatch({ type: 'AUTH_LOADING' });
    //
    if (token) {
      try {
        const tuser = await apiAuth.get(`/users/retrieve`);
        const issuper = tuser.data.is_superuser;
        const isinvestor = tuser.data.is_investor;
        if(issuper) {
          await localStorage.setItem(
            'yumminn-user',
            JSON.stringify(tuser.data)
          );
          const trestaurants = await apiAuth.get(`/restaurants-admin-new`);
          const tcompanies = await apiAuth.get(`/companies`);
          // arrange restaurants list for select
          let restaurant_list = trestaurants.data.results.map((r) => ({
            value: r.id,
            label: r.name,
            demo: r.demo,
            company: r.company,
            account_manager: r.account_manager,
            show_order_and_pay_tab_on_backoffice: r.show_order_and_pay_tab_on_backoffice,
            show_lockers_page: r.show_lockers_page,
            show_events_page: r.show_events_page,
            show_wallet_page: r.show_wallet_page,
            custom_catalog: r.custom_catalog,
            is_order_active: r.is_order_active,
            allow_booking: r.allow_booking,
            default_language: r.default_language
          })).filter((r => r.demo === false));

          let companies_list = tcompanies.data.results.map((r) => ({
            value: r.id,
            label: r.name
          }))

          // restaurant_list.unshift({value: null, label: i18next.t("Todos")})
          // companies_list.unshift({value: null, label: i18next.t("Todos")})

          localStorage.setItem(
            'yumminn-restaurants',
            JSON.stringify(restaurant_list)
          );

          // localStorage.setItem(
          //   'yumminn-selectedRestaurant',
          //   JSON.stringify(restaurant_list[0])
          // );

          localStorage.setItem(
            'yumminn-companies',
            JSON.stringify(companies_list)
          );

          // localStorage.setItem(
          //   'yumminn-selectedRestaurant',
          //   JSON.stringify(companies_list[0])
          // );
          
          // restaurant_list.map((restaurant) => {
          //   if (restaurant.value === null){
          //     return localStorage.setItem(
          //       'yumminn-selectedRestaurant',
          //       JSON.stringify([{value: null, label:i18next.t("Todos")}]))
          //   } else {
          //     return localStorage.setItem(
          //       'yumminn-selectedRestaurant',
          //       JSON.stringify([restaurant_list[0] || null]))
          //   }
          // }) 

          localStorage.setItem(
            'yumminn-selectedCompany',
            JSON.stringify([companies_list[0] || null]))

          dispatch({
            type: 'RESTORE_TOKEN',
            payload: {
              token,
              user: tuser.data,
              restaurants: restaurant_list,
              // selectedRestaurant: restaurant_list[0] || null,
              selectedCompany: companies_list[0] || null
            },
          });
        } else if(isinvestor){
          await localStorage.setItem(
            'yumminn-user',
            JSON.stringify(tuser.data)
          );
          let restaurant_list = [];
          let companies_list = [];
          // restaurant_list.push({value: null, label: i18next.t("Todos")})
          // companies_list.push({value: null, label: i18next.t("Todos")})

          localStorage.setItem(
            'yumminn-restaurants',
            JSON.stringify(restaurant_list)
          );
          // localStorage.setItem(
          //   'yumminn-selectedRestaurant',
          //   JSON.stringify([{value: null, label:i18next.t("Todos")}]))
          
          localStorage.setItem(
            'yumminn-companies',
            JSON.stringify(companies_list)
          );
          localStorage.setItem(
            'yumminn-selectedCompanies',
            JSON.stringify([{value: null, label:i18next.t("Todos")}]))
          

          dispatch({
            type: 'RESTORE_TOKEN',
            payload: {
              token,
              user: tuser.data,
              restaurants: restaurant_list,
              // selectedRestaurant: restaurant_list[0] || null,
              selectedCompany: companies_list[0] || null
            },
          })

        } else {
          throw Error('Is not admin');
        }
      } catch (e) {
        dispatch({ type: 'RESTORE_TOKEN', payload: { token: null } });
      }
    } else {
      dispatch({ type: 'RESTORE_TOKEN', payload: { token: null } });
    }
    //
  };
};

const login = (dispatch) => {
  return async (data) => {
    dispatch({ type: 'AUTH_LOADING' });
    // sign a user with its credentials
    if (data) {
      try {
        //
        const { data } = await apiAuth.get(`/users/${data.user}`);
        localStorage.setItem('yumminn-userlogin', JSON.stringify(data));
        dispatch({ type: 'SIGNIN', payload: { user: data } });
      } catch (e) {
        //
        console.log('Login error', e);
        localStorage.removeItem('yumminn-userlogin');
        dispatch({ type: 'AUTH_ERROR', payload: 'Bad credentials' });
      }
    }

    //
  };
};

const setSelectedRestaurant = (dispatch) => {
  return async (selectedRestaurant) => {
    // change selected restaurant
    localStorage.setItem(
      'yumminn-selectedRestaurant',
      JSON.stringify(selectedRestaurant)
    );

    const selectedRestaurantId = selectedRestaurant.length === 1 ? selectedRestaurant[0].value : null;

    dispatch({ type: 'SET_RESTAURANT', payload: { selectedRestaurant, selectedRestaurantId } });
  };
};

const setSelectedCompany = (dispatch) => {
  return async (selectedCompany) => {
    // change selected company
    localStorage.setItem(
      'yumminn-selectedCompany',
      JSON.stringify(selectedCompany)
    );
    dispatch({ type: 'SET_COMPANY', payload: { selectedCompany } });
  };
};

const setSelectedYear = (dispatch) => {
  return async (selectedYear) => {
    sessionStorage.setItem(
      'yumminn-selectedYear',
      JSON.stringify(selectedYear)
    );
    dispatch({ type: 'SET_YEAR', payload: { selectedYear } });
  };
};

const setSelectedYearTo = (dispatch) => {
  return async (selectedYearTo) => {
    sessionStorage.setItem(
      'yumminn-selectedYear',
      JSON.stringify(selectedYearTo)
    );
    dispatch({ type: 'SET_YEAR_TO', payload: { selectedYearTo } });
  };
};

const setSelectedMonth = (dispatch) => {
  return async (selectedMonth) => {
    sessionStorage.setItem(
      'yumminn-selectedMonth',
      JSON.stringify(selectedMonth)
    );
    dispatch({ type: 'SET_MONTH', payload: { selectedMonth } });
  };
};

const setSelectedMonthTo = (dispatch) => {
  return async (selectedMonthTo) => {
    // change selected restaurant
    sessionStorage.setItem(
      'yumminn-selectedMonthTo',
      JSON.stringify(selectedMonthTo)
    );
    dispatch({ type: 'SET_MONTH_TO', payload: { selectedMonthTo } });
  };
};

const setSelectedDate = (dispatch) => {
  return async (selectedDate) => {
    sessionStorage.setItem(
      'yumminn-selectedDate',
      JSON.stringify(selectedDate)
    );
    dispatch({ type: 'SET_DATE', payload: { selectedDate } });
  };
};

const setSelectedDateTo = (dispatch) => {
  return async (selectedDateTo) => {
    sessionStorage.setItem(
      'yumminn-selectedDateTo',
      JSON.stringify(selectedDateTo)
    );
    dispatch({ type: 'SET_DATE_TO', payload: { selectedDateTo } });
  };
};

const setSelectedStartTime = (dispatch) => {
  return async (selectedStartTime) => {
    sessionStorage.setItem(
      'yumminn-selectedStartTime',
      selectedStartTime
    );
    dispatch({ type: 'SET_START_TIME', payload: { selectedStartTime } });
  };
};

const setSelectedEndTime = (dispatch) => {
  return async (selectedEndTime) => {
    sessionStorage.setItem(
      'yumminn-selectedEndTime',
      selectedEndTime
    );
    dispatch({ type: 'SET_END_TIME', payload: { selectedEndTime } });
  };
};

const setSelectedReviewFilter = (dispatch) => {
  return async (filter) => {
    sessionStorage.setItem('yumminn-selectedReviewFilter', filter);
    dispatch({ type: 'SET_REVIEW_FILTER', payload: { filter } });
  };
};

const setSelectedWaiter = (dispatch) => {
  return async (waiter) => {
    sessionStorage.setItem('yumminn-selectedWaiter', waiter);
    dispatch({ type: 'SET_WAITER', payload: { waiter } });
  };
};

const setSelectedAgentsRestaurants = (dispatch) => {
  return async (agentsRestaurants) => {
    const agentsRestaurantsJSON = JSON.stringify(agentsRestaurants);
    localStorage.setItem('yumminn-selectedAgentsRestaurants', agentsRestaurantsJSON);
    dispatch({ type: 'SET_AGENTS_RESTAURANTS', payload: { agentsRestaurants } });
  };
};

const setSelectedAgentsPeriod = (dispatch) => {
  return async (agentsPeriod) => {
    localStorage.setItem('yumminn-selectedAgentsPeriod', agentsPeriod);
    dispatch({ type: 'SET_AGENTS_PERIOD', payload: { agentsPeriod } });
  };
};

const setSelectedAgentsInterval = (dispatch) => {
  return async (agentsInterval) => {
    localStorage.setItem('yumminn-selectedAgentsInterval', agentsInterval);
    dispatch({ type: 'SET_AGENTS_INTERVAL', payload: { agentsInterval } });
  };
};

const setTopProducts = (dispatch) => {
  return (topProducts) => {
    dispatch({ type: 'SET_TOP_PRODUCTS', payload: { topProducts }});
  };
};

const setTopModifiers = (dispatch) => {
  return (topModifiers) => {
    dispatch({ type: 'SET_TOP_MODIFIERS', payload: { topModifiers }});
  };
};

const signout = (dispatch) => {
  return async () => {
    // sign out user
    localStorage.removeItem('yumminn-selectedRestaurant');
    localStorage.removeItem('yumminn-selectedCompany');
    sessionStorage.removeItem('yumminn-selectedYear');
    sessionStorage.removeItem('yumminn-selectedYearTo');
    sessionStorage.removeItem('yumminn-selectedMonth');
    sessionStorage.removeItem('yumminn-selectedMonthTo');
    sessionStorage.removeItem('yumminn-selectedDate');
    sessionStorage.removeItem('yumminn-selectedDateTo');
    sessionStorage.removeItem('yumminn-selectedStartTime');
    sessionStorage.removeItem('yumminn-selectedEndTime');
    sessionStorage.removeItem('yumminn-selectedReviewFilter');
    sessionStorage.removeItem('yumminn-selectedWaiter');
    localStorage.removeItem('yumminn-selectedAgentsRestaurants');
    localStorage.removeItem('yumminn-selectedAgentsPeriod');
    localStorage.removeItem('yumminn-selectedAgentsInterval');
    localStorage.removeItem('yumminn-userToken');
    localStorage.removeItem('yumminn-user');
    localStorage.removeItem('tabs');
    dispatch({ type: 'SIGNOUT' });
  };
};

const clearError = (dispatch) => {
  return async () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };
};

const setSelectedProductCategories = (dispatch) => {
  return async (selectedProductCategories) => {
    dispatch({ type: 'SET_SELECTED_PRODUCT_CATEGORIES', payload: { selectedProductCategories } });
  };
};

const setSelectedTables = (dispatch) => {
  return async (selectedTables) => {
    dispatch({ type: 'SET_SELECTED_TABLES', payload: { selectedTables } });
  };
};

const setAccessType = (dispatch) => {
  return async (accessType) => {
    dispatch({ type: 'SET_ACCESS_TYPE', payload: { accessType } });
  };
};

const setAllowedRoutes = (dispatch) => {
  return async (allowedRoutes) => {
    dispatch({ type: 'SET_ALLOWED_ROUTES', payload: { allowedRoutes } });
  };
};

const storageSelected = JSON.parse(localStorage.getItem('yumminn-selectedRestaurant')) || null;
export const { Context, Provider } = createDataContext(
  authReducer,
  {
    login,
    signout,
    loadUser,
    loadSuperUser,
    setSelectedRestaurant,
    setSelectedCompany,
    setSelectedYear,
    setSelectedYearTo,
    setSelectedMonth,
    setSelectedMonthTo,
    setSelectedDate,
    setSelectedDateTo,
    setSelectedStartTime,
    setSelectedEndTime,
    setSelectedReviewFilter,
    setSelectedWaiter,
    setSelectedAgentsRestaurants,
    setSelectedAgentsPeriod,
    setSelectedAgentsInterval,
    setAccessType,
    clearError,
    setIsLoggedIn,
    reloadRestaurants,
    setSelectedProductCategories,
    setTopProducts,
    setTopModifiers,
    setAllowedRoutes,
    setSelectedTables,
  },
  {
    loading: false,
    error: null,
    userToken: null,
    user: null,
    restaurants: null,
    selectedRestaurant: storageSelected || null,
    selectedRestaurantId: storageSelected?.[0]?.value || null,
    selectedCompany: null,
    selectedMonth: null,
    selectedReviewFilter: 'days',
    selectedWaiter: [],
    selectedAgentsRestaurants: null,
    setSelectedAgentsPeriod: null,
    setSelectedAgentsInterval: null,
    isLoggedIn: false,
    selectedDate: sessionStorage.getItem('yumminn-selectedDate') ? new Date(JSON.parse(sessionStorage.getItem('yumminn-selectedDate'))) : null,
    selectedDateTo: sessionStorage.getItem('yumminn-selectedDate') ? new Date(JSON.parse(sessionStorage.getItem('yumminn-selectedDateTo'))) : null,
    selectedProductCategories: null,
    selectedTables: [],
    topProducts: null,
    topModifiers: null,
    accessType: null,
    allowedRoutes: []
  }
);
