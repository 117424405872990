import React, { useState } from 'react';
import { CustomGroupSchedulesContainer } from './ProductHighlightContainers';
import { Trans, useTranslation } from 'react-i18next';
import { stringifyDays } from 'logic/functions';
import { Icon, IconType, SquareIcon } from 'common/Icon';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { Checkbox } from 'common/Input';
import { parseCutomGroupSchedule, parseSchedule } from './productHighlightFunctions';
import { EditVisibilitySchedule } from 'pages/discounts/components/EditVisibilitySchedule';

export const CustomGroupSchedules = ({customGroupDraft, editCustomGroup}) => {
  const schedules = customGroupDraft?.visibility_schedules.map(sch => parseSchedule(sch)) || [];

  const { t } = useTranslation();

  const isBanner = customGroupDraft.custom_group_type === 'commercial';
  
  const [scheduleDraft, setScheduleDraft] = useState(null);

  const newSchedule = {
    id: null,
    tempId: `new ${(schedules?.length || 0) + 1}`,
    name: '',
    days: [],
    time_ranges: [{start_hour: 0, end_hour: 24, start_minutes: 0, end_minutes: 0}],
  };

  const editSchedule = partialSchedule => setScheduleDraft(prev => ({...prev, ...partialSchedule}));

  const defineSchedule = () => {
    if (!scheduleDraft) return;
    
    const newSchedules = schedules || [];
    
    const index = newSchedules.findIndex(sch =>
      (scheduleDraft.id && sch.id === scheduleDraft.id) ||
      (scheduleDraft.tempId && sch.tempId === scheduleDraft.tempId)
    );

    const newSchedule = {
      ...scheduleDraft,
      name: scheduleDraft.name || `${t('schedule')} ${newSchedules.length + 1}`,
    };
    
    if (index === -1) {
      newSchedules.push(newSchedule);
    } else {
      newSchedules.splice(index, 1, newSchedule);
    }

    editCustomGroup({ visibility_schedules: newSchedules.map(sch => parseCutomGroupSchedule(sch)) });
    setScheduleDraft(null);
  };

  const deleteSchedule = schedule => {
    const newSchedules = schedules || [];
    const index = newSchedules.findIndex(sch =>
      (schedule.id && sch.id === schedule.id) ||
      (schedule.tempId && sch.tempId === schedule.tempId)
    );
    
    if (index !== -1) {
      newSchedules.splice(index, 1);
      editCustomGroup({ visibility_schedules: newSchedules.map(sch => parseCutomGroupSchedule(sch)) });
    }
  };

  const toggleForceVisibility = () => {
    editCustomGroup({ force_visibility: !customGroupDraft.force_visibility })
  };

  if (!!scheduleDraft) return (
    <EditVisibilitySchedule
      schedule={scheduleDraft}
      editSchedule={editSchedule}
      defineSchedule={defineSchedule}
      cancel={() => setScheduleDraft(null)}
    />
  );

  return (
    <CustomGroupSchedulesContainer>
      <ul>
        {!!schedules?.length &&
          <h4>{t('custom_schedules')}</h4>
        }
        {schedules?.map((schedule, index) =>
          <li key={schedule.id}>
            <div>
              <b>{schedule.name || `${t('schedule')} ${index + 1}`}</b>
              <i> - {stringifyDays(schedule.days, t)}</i>
            </div>
            <Icon type={IconType.PENCIL} size={20} onClick={() => setScheduleDraft(schedule)}/>
            <Icon type={IconType.TRASH} size={20} onClick={() => deleteSchedule(schedule)}/>
          </li>
        )}
        <Button
          leftIcon={<Icon type={IconType.ADD}/>}
          label='define_schedule'
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={() => setScheduleDraft(newSchedule)}
        />
        {isBanner && !!customGroupDraft.restaurant &&
          <Checkbox
            label='force_banner_visibility'
            checked={customGroupDraft.force_visibility}
            onChange={toggleForceVisibility}
          />
        }
      </ul>
      {!schedules?.length &&
        <div className='empty_state'>
          <SquareIcon>🕐</SquareIcon>
          <strong>
            {t('empty_schedules_title', {type: t(isBanner ? 'banner' : 'highlighted')})}
          </strong>
          <p>
            <Trans
              i18nKey='empty_schedules_message'
              defaults='Si deseas personalizar los momentos de visibilidad, <b>puedes definir un horario o desactivarlo</b> cuando quieras.'
              components={{ b: <b/> }}
            />
          </p>
        </div>
      }
    </CustomGroupSchedulesContainer>
  );
};
