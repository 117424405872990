import { PlusCircleIcon } from '@heroicons/react/solid';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { Icon, IconType, SquareIcon } from 'common/Icon';
import { stringifyDays } from 'logic/functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditVisibilitySchedule } from './EditVisibilitySchedule';

const NEW_SCHEDULE = {
  name: '',
  days: [],
  times: [{start_hour: 0, end_hour: 24, start_minutes: 0, end_minutes: 0}],
};

export const VisibilitySchedules = ({ discountDraft, editDiscount }) => {
  const { t } = useTranslation();

  const [scheduleDraft, setScheduleDraft] = useState(null);

  const editSchedule = partialSchedule => setScheduleDraft(prev => ({...prev, ...partialSchedule}));

  const defineSchedule = () => {
    if (!scheduleDraft) return;
    
    const newSchedules = discountDraft?.schedules || [];
    const index = newSchedules.findIndex(sch =>
      (scheduleDraft.id && sch.id === scheduleDraft.id) ||
      (scheduleDraft.tempId && sch.tempId === scheduleDraft.tempId)
    );

    const newSchedule = {
      ...scheduleDraft,
      name: scheduleDraft.name || `${t('schedule')} ${newSchedules.length + 1}`,
    };
    
    if (index === -1) {
      newSchedules.push(newSchedule);
    } else {
      newSchedules.splice(index, 1, newSchedule);
    }

    editDiscount({ schedules: newSchedules });
    setScheduleDraft(null);
  };

  const deleteSchedule = schedule => {
    const newSchedules = discountDraft?.schedules || [];
    const index = newSchedules.findIndex(sch =>
      (schedule.id && sch.id === schedule.id) ||
      (schedule.tempId && sch.tempId === schedule.tempId)
    );
    
    if (index !== -1) {
      newSchedules.splice(index, 1);
      editDiscount({ schedules: newSchedules });
    }
  };

  if (!!scheduleDraft) return (
    <EditVisibilitySchedule
      schedule={scheduleDraft}
      editSchedule={editSchedule}
      defineSchedule={defineSchedule}
      cancel={() => setScheduleDraft(null)}
    />
  );

  return (
    <div>
      <ul>
        {!!discountDraft?.schedules?.length &&
          <h4>{t('custom_schedules')}</h4>
        }
        {discountDraft?.schedules?.map((schedule, index) =>
          <li key={schedule.id}>
            <div>
              <b>{schedule.name || `${t('schedule')} ${index + 1}`}</b>
              <i> - {stringifyDays(schedule.days, t)}</i>
            </div>
            <Icon type={IconType.PENCIL} size={20} onClick={() => setScheduleDraft(schedule)}/>
            <Icon type={IconType.TRASH} size={20} onClick={() => deleteSchedule(schedule)}/>
          </li>
        )}
        <Button
          leftIcon={<PlusCircleIcon/>}
          label='define_schedule'
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={() => setScheduleDraft({...NEW_SCHEDULE, tempId: `new-${discountDraft?.schedules?.length || 0}`})}
        />
      </ul>
      {!discountDraft?.schedules?.length &&
        <div className='empty_state'>
          <SquareIcon>🕐</SquareIcon>
          <strong>
            Por defecto, el descuento estará visible durante todo el horario de servicio.
          </strong>
          <p>
            Si deseas personalizar los momentos de visibilidad, <b>puedes definir un horario o desactivarlo</b> cuando quieras.
          </p>
        </div>
      }
    </div>
  );
};
