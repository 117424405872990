import React, { useState, useEffect, useContext } from "react";
import RoundButton from "../RoundButton";
import { ObjectTypes, patchObject, saveGroup } from "../api-OrderAndPay";
import { Context as AuthContext } from '../../../../../data/authContext';
import VisibilitySchedulePopup from "../VisibilitySchedulePopup";
import { TabSelector } from "common/TabSelector";
import { isEqual, cloneDeep } from "lodash";
import { GroupSpecsPopup } from "../GroupSpecsPopup";
import { GroupShedulesPopup } from "../GroupShedulesPopup";
import { TrashIcon } from '@heroicons/react/outline';
import { deleteObject } from "../api-OrderAndPay";
import { CHILDREN_FIELDS, CONTEXT_KEYS } from "../../DragAndDrop/constants";
import { ActiveSwitch } from "../ActiveSwitch";
import { logToServer } from "logic/functions";
import { BUTTON_COLORS, BUTTON_VARIANTS, Button } from "common/Button";
import { DeletePopup } from "../../Modifiers/DeletePopup";
import { ConfirmPopup } from "../../Lockers/ConfirmPopup";
import { Popup } from "common/Popup";
import styled from "styled-components";

const VISIBILITY_ENDPOINTS = {
  SUPER_GROUP: 'supergroup',
  CATEGORY_GROUP: 'category_group',
  CATEGORY: 'product_category',
};

const NEW_GROUP = {name: '', visibility: 0};

export const CreateEditGroupModal = ({ groupToEdit, groupType, parentId, options, mutate, closeModal, t }) => {

	const {
    state: { selectedRestaurantId },
	} = useContext(AuthContext);

  const [tab, setTab] = useState('specs');

  const [groupDraft, setGroupDraft] = useState(groupToEdit || NEW_GROUP);
  const [groupChildren, setGroupChildren] = useState([]);

  const [scheduleToEdit, setScheduleToEdit] = useState(null);
  const [schedulesToDelete, setSchedulesToDelete] = useState([]);
  const [timeRangesToDelete, setTimeRangesToDelete] = useState([]);
  
  const [skipSchedules, setSkipSchedules] = useState(!!groupToEdit)
	const [showConfirmCancel, setShowConfirmCancel] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showHowToDelete, setShowHowToDelete] = useState(false)

  const [hasChanges, setHasChanges] = useState(false);
	const [isVisibilityDisabled, setIsVisibilityDisabled] = useState(true);
	const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isDeletable, setIsDeletable] = useState(false)
	const [loading, setLoading] = useState(false);

  const editGroup = (object) => {
    if (object?.name !== undefined) object.visible_name = object.name;

    setGroupDraft(prevGroup => ({...prevGroup, ...object}));
  };

  const openSchedulePopup = () => {
    setTab('edit_schedule');
  };

  const closeSchedulePopup = () => {
    setTab('schedules');
    setScheduleToEdit(null);
  };

  const newSchedule = () => {
    setScheduleToEdit(null);
    openSchedulePopup();
  };
  
  const editSchedule = (schedule) => {
    setScheduleToEdit(schedule);
    openSchedulePopup();
  };
  
  const defineSchedule = (schedule) => {
    const index = groupDraft?.visibility_schedules?.findIndex(
      item => schedule.id && (item.id === schedule.id || item.tempId === schedule.tempId)
    );
    
    if (!index) {
      editGroup({visibility_schedules: [schedule]});
    } else if (index === -1) {
      editGroup({visibility_schedules: [...groupDraft.visibility_schedules, schedule]});
    } else {
      const newSchedules = [...groupDraft.visibility_schedules];
      newSchedules[index] = schedule;
      editGroup({visibility_schedules: newSchedules});
    }

    closeSchedulePopup();
  };

  const next = () => setTab('schedules');

	const save = async () => {
    if (isSaveDisabled) return;

		setLoading(true)

    const endpoint = VISIBILITY_ENDPOINTS[groupType];
    const restaurant = selectedRestaurantId;
    const group = cloneDeep(groupDraft);

    if (!groupChildren.length) {
      group.visibility = 0;
    }

    try {
      const groupId = await saveGroup({
        endpoint,
        group,
        restaurant,
        groupType,
        parentId,
        groupToEdit,
        groupChildren,
        schedulesToDelete,
        timeRangesToDelete,
        t,
      });

      sessionStorage.setItem(CONTEXT_KEYS[groupType].SELECTED, groupId);

      await mutate();
      closeModal();
    } catch (error) {
      setLoading(false);
      logToServer("🚀 ~ file: CreateEditGroupModal.jsx:134 ~ save ~ error:", error, selectedRestaurantId)
      console.log("🚀 ~ file: CreateEditGroupModal.jsx:134 ~ save ~ error:", error);
    }
	}

  const cancel = () => hasChanges ? setShowConfirmCancel(true) : closeModal();

  const deleteCategory = () => isDeletable ? setShowConfirmDelete(true) : setShowHowToDelete(true);

  const confirmDelete = async() => {
    try {
      setLoading(true)
      await deleteObject({objectType: ObjectTypes[groupType], object: groupDraft, t:t})
      await mutate();
      closeModal()
    } catch (error){
      console.log("🚀 ~ file: CreateEditGroupModal.jsx:339 ~ delete ~ error:", error);
      setLoading(false)
    }
  }

  const toggleVisibility = async () => {
    if (loading) return;

    const hasSchedules = groupDraft?.visibility_schedules?.length > 0;
    const newVisibility = groupDraft.visibility === 0 ? (hasSchedules ? 2 : 1) : 0;
    const childrenName = CHILDREN_FIELDS[groupType];
    
    if (!groupToEdit || (groupToEdit && !groupToEdit[childrenName].length && newVisibility !== 0)) {
      editGroup({visibility: newVisibility});
      return;
    }
    
    setLoading(true);
    
    const object = {
      id: groupDraft.id,
      visibility: newVisibility,
    };
    
    try {
      const res = await patchObject({ objectType: ObjectTypes[groupType], object, t });
      res.status === 200 && editGroup({visibility: newVisibility});
      groupToEdit.visibility = newVisibility;
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ file: CreateEditGroupModal.jsx:48 ~ toggleVisibility ~ error:", error);
      setLoading(false);
    }
  }

  const handleSelectChildren = (option) => {
    setGroupChildren(option);
  };

	useEffect(() => {
    if (groupToEdit && options) {
      const childrenName = CHILDREN_FIELDS[groupType];
      const newChildren = groupToEdit[childrenName].map(
        group => ({value: group.id, label: group.name || group.visible_name})
      );

      setGroupChildren(newChildren);
      setIsVisibilityDisabled(!newChildren.length);

      if (!newChildren.length && groupToEdit.visibility !== 0) {
        toggleVisibility();
      }
    }
	}, []);

  useEffect(() => {
    if (groupDraft.visibility === 0) return;
    
    const newVisibility = groupDraft?.visibility_schedules && groupDraft?.visibility_schedules.length > 0 ? 2 : 1;
    editGroup({visibility: newVisibility});
  }, [groupDraft?.visibility_schedules]);

  useEffect(() => {
    tab === 'schedules' && !skipSchedules && setSkipSchedules(true);
  }, [tab]);

  useEffect(() => {
    const groupCopy = cloneDeep(groupDraft);
    const groupToEditCopy = cloneDeep(groupToEdit || NEW_GROUP);

    groupCopy.visibility = null;
    groupToEditCopy.visibility = null;

    const hasNewChanges = !isEqual(groupCopy, groupToEditCopy);
    const hasName = !!(groupDraft.name || groupDraft.visible_name || groupDraft.tpv_name);
    const hasTpvId = !!(groupDraft.tpv_id)
    const realHasChildren = !!groupChildren.length

    setHasChanges(hasNewChanges);
    setIsSaveDisabled(!hasName || !hasNewChanges);
    setIsDeletable(!realHasChildren && !hasTpvId);
    setIsVisibilityDisabled(!realHasChildren);
  }, [groupDraft, groupChildren]);

	return (
    <GroupModal isOpen={true} close={cancel}>
      <header>
        <h3 className="text-lg text-[#404040] font-semibold">
          {t(groupToEdit ? `Edit_${groupType}` : `Create_${groupType}`)}
        </h3>
        <ActiveSwitch
          checked={groupDraft.visibility !== 0}
          loading={loading}
          disabled={isVisibilityDisabled}
          disabledInfo={t('info_visibility_switch_disabled')}
          onChange={toggleVisibility}
        />
      </header>
      <TabSelector
        name={'edit_group'}
        value={tab !== 'edit_schedule' ? tab : 'schedules'}
        options={['specs', 'schedules']}
        onChange={setTab}
      />
      {tab === 'specs' &&
        <GroupSpecsPopup
          group={groupDraft}
          groupType={groupType}
          groupToEdit={groupToEdit}
          groupChildren={groupChildren}
          options={options}
          editGroup={editGroup}
          handleSelectChildren={handleSelectChildren}
          newSchedule={newSchedule}
          editSchedule={editSchedule}
          setSchedulesToDelete={setSchedulesToDelete}
        />
      }
      {tab === 'schedules' &&
        <GroupShedulesPopup
          group={groupDraft}
          newSchedule={newSchedule}
          editSchedule={editSchedule}
          editGroup={editGroup}
          setSchedulesToDelete={setSchedulesToDelete}
          setSkipSchedules={setSkipSchedules}
        />
      }
      {tab === 'edit_schedule' &&
        <VisibilitySchedulePopup
          scheduleToEdit={scheduleToEdit}
          define={defineSchedule}
          cancel={closeSchedulePopup}
          setTimeRangesToDelete={setTimeRangesToDelete}
          schedulesLength={groupDraft?.visibility_schedules?.length || 0}
          groupType={groupType}
        />
      }
      {tab !== 'edit_schedule' &&
        <footer>
          {groupToEdit && !groupDraft.tpv_id &&
            <Button
              label={<TrashIcon/>}
              onClick={deleteCategory}
              disabled={!isDeletable || loading}
              variant={BUTTON_VARIANTS.SECONDARY}
              color={BUTTON_COLORS.DANGER}
            />
          }
          <div>
            <Button
              label="cancel"
              onClick={cancel}
              disabled={loading}
              variant={BUTTON_VARIANTS.SECONDARY}
            />
            <Button
              label={skipSchedules ? (groupDraft ? "save" : "create") : "next"}
              loading={loading}
              disabled={isSaveDisabled}
              onClick={skipSchedules ? save : next}
            />
          </div>
        </footer>
      }
      <ConfirmPopup
        isOpen={showConfirmCancel}
        title='you_have_unsaved_changes'
        message='warning_message_unsaved_changes'
        cancelLabel='keep_editing'
        confirmLabel='close'
        cancel={() => setShowConfirmCancel(false)}
        confirm={closeModal}
      />
      <DeletePopup
        isOpen={showConfirmDelete || showHowToDelete}
        title={isDeletable ? 'remove_category' : 'remove_category_not_allowed'}
        message={isDeletable ? 'are_you_sure_to_remove' : 'how_to_remove_category'}
        onCancel={isDeletable ? () => setShowConfirmDelete(false) : null}
        onConfirm={() => isDeletable ? confirmDelete() : setShowHowToDelete(false)}
        close={() => isDeletable ? setShowConfirmDelete(false) : setShowHowToDelete(false)}
      />
    </GroupModal>
	);
};

const GroupModal = styled(Popup)`
  & > div {
    display: flex;
    flex-direction: column;
    min-width: 50rem;
    min-height: 43rem;
    //min-height: 100%;

    header {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 8px;
    }

    footer {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      padding-top: 16px;
      border-top: 1px solid #DDD;
      margin-top: auto;
      
      div {
        display: flex;
        gap: 16px;
        margin-left: auto;
      }
    }
  }
`;