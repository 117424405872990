import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { BUTTON_SIZES, BUTTON_VARIANTS, Button } from "common/Button";
import styled from "styled-components";
import { Context as AuthContext } from "../../../../../data/authContext";

export const HeaderButton = ({ onClick, selected, route, children }) => {
  const {
    state: {
      allowedRoutes
    }
  } = React.useContext(AuthContext);

  if(allowedRoutes.length === 0 || allowedRoutes.includes(route)){
    return(
      <Button
        onClick={onClick}
        label={<span className="whitespace-nowrap">{children}</span>}
        variant={BUTTON_VARIANTS[selected ? 'PRIMARY' : 'SECONDARY']}
        size={BUTTON_SIZES.SM}
      />
    ) 
  } else {
    return null;
  }
};

const MenuRoutesButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  (
    <>
      <HeaderButton
        onClick={() => history.push("/menu")}
        selected={window.location.pathname === "/menu"}
        route={"/menu"}
      >
        {t("link_and_pdf")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/admin/order-and-pay")}
        selected={window.location.pathname === "/admin/order-and-pay"}
        route={"/admin/order-and-pay"}
      >
        {t("products_and_categories")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/admin/modifiers")}
        selected={window.location.pathname === "/admin/modifiers"}
        route={"/admin/modifiers"}
      >
        {t("modifiers")}
      </HeaderButton>
      <HeaderButton
        onClick={() =>history.push("/admin/product-highlight")}
        selected={window.location.pathname === "/admin/product-highlight"}
        route={"/admin/product-highlight"}
      >
        {t("highlights_banners")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/discounts")}
        selected={window.location.pathname.includes("/discounts")}
        route={"/discounts"}
      >
        {t("discounts")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/admin/cross-sell")}
        selected={window.location.pathname.includes("/admin/cross-sell")}
        route={"/admin/cross-sell"}
      >
        {t("cross_selling")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/translations/supergroup_name")}
        selected={window.location.pathname.includes("/translations")}
        route={"/translations"}
      >
        {t("translations")} 
      </HeaderButton>
    </>
  )
}

const DashboardRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  (
    <>
      <HeaderButton
        onClick={() => history.push("/admin/home")}
        selected={window.location.pathname === "/admin/home"}
        route={"/admin/home"}
      >
        {t("home")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/admin/dashboard")}
        selected={window.location.pathname === "/admin/dashboard"}
        route={"/admin/dashboard"}
      >
        {t("general_metrics")}
      </HeaderButton>
      <HeaderButton
        onClick={() =>history.push("/admin/catalog-dashboard")}
        selected={window.location.pathname === "/admin/catalog-dashboard"}
        route={"/admin/catalog-dashboard"}
      >
        {t("product_metrics")}
      </HeaderButton>
     
    </>
  )
}

const PaymentsAndOrdersRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  (
    <>
      <HeaderButton
        onClick={() => history.push("/payments")}
        selected={window.location.pathname === "/payments"}
        route={"/payments"}
      >
        {t("Payments")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/admin/orders")}
        selected={window.location.pathname === "/admin/orders"}
        route={"/admin/orders"}
      >
        {t("Orders")}
      </HeaderButton>
     
    </>
  )
}

const RatingsRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  (
    <>
      <HeaderButton
        onClick={() => history.push("/ratings")}
        selected={window.location.pathname === "/ratings"}
        route={"/ratings"}
      >
        {t("Ratings_and_reviews")} 
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/ratings/reviews")}
        selected={window.location.pathname === "/ratings/reviews"}
        route={"/ratings/reviews"}
      >
        {t("Reviews")}
      </HeaderButton>
     
    </>
  )
}

const DeliveryAndTakeawayRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  (
    <>
      <HeaderButton
        onClick={() => history.push("/delivery")}
        selected={window.location.pathname === "/delivery"}
        route={"/delivery"}
      >
        {t("delivery")} 
      </HeaderButton>
      {/*<HeaderButton
        onClick={() => history.push("/takeaway")}
        selected={window.location.pathname === "/takeaway"}
      >
        {t("takeaway")}
      </HeaderButton>*/}
     
    </>
  )
}

const LoyaltyRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  (
    <>
      <HeaderButton
        onClick={() => history.push("/loyalty")}
        selected={window.location.pathname === "/loyalty"}
        route={"/loyalty"}
      >
        {t("metrics")} 
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/loyalty/programs")}
        selected={window.location.pathname === "/loyalty/programs"}
        route={"/loyalty/programs"}
      >
        {t("Cashback")} 
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/loyalty/mails")}
        selected={window.location.pathname === "/loyalty/mails"}
        route={"/loyalty/mails"}
      >
        {t("CRM")} 
      </HeaderButton>
    </>
  )
}

const StockRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  ( 
    <>
      <HeaderButton
        onClick={() => history.push("/admin/stock-status")}
        selected={window.location.pathname === "/admin/stock-status"}
        route={"/admin/stock-status"}
      >
        {t("Current state")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/admin/stock-configuration")}
        selected={window.location.pathname === "/admin/stock-configuration"}
        route={"/admin/stock-configuration"}
      >
        {t("Configuration")}
      </HeaderButton>
    </>
  )
};

const EventRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  ( 
    <>
     <HeaderButton
        onClick={() => history.push("/admin/events-settings")}
        selected={window.location.pathname === "/admin/events-settings"}
        route={"/admin/events-settings"}
      >
        {t("events_settings")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/admin/event-tickets")}
        selected={window.location.pathname === "/admin/event-tickets"}
        route={"/admin/event-tickets"}
      >
        {t("ticket_validation")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/admin/tickets-sold")}
        selected={window.location.pathname === "/admin/tickets-sold"}
        route={"/admin/tickets-sold"}
      >
        {t("tickets_sold")}
      </HeaderButton>
    </>
  )
};

const ServiceRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  (
      <>
        <HeaderButton
          onClick={() => history.push("/settings/tables/sectors")}
          selected={window.location.pathname === "/settings/tables/sectors"}
          route={"/settings/tables/sectors"}

        >
          {t("tables_and_sectors")}
        </HeaderButton>
        <HeaderButton
          onClick={() => history.push("/settings/tables")}
          selected={window.location.pathname === "/settings/tables"}
          route={"/settings/tables"}

        >
          {t("Table_statuses")}
        </HeaderButton>
        <HeaderButton
          onClick={() => history.push("/admin/waiters")}
          selected={window.location.pathname === "/admin/waiters"}
          route={"/admin/waiters"}

        >
          {t("Performance camareros") }
        </HeaderButton>
        <HeaderButton
          onClick={() => history.push("/admin/shifts")}
          selected={window.location.pathname === "/admin/shifts"}
          route={"/admin/shifts"}

        >
          {t("Order_scheduling") }
        </HeaderButton>
        <HeaderButton
          onClick={() => history.push("/admin/booking-shifts")}
          selected={window.location.pathname === "/admin/booking-shifts"}
          route={"/admin/booking-shifts"}

        >
          {t("BookingShifts") }
        </HeaderButton>
        <HeaderButton
          onClick={() => history.push("/admin/bookings")}
          selected={window.location.pathname === "/admin/bookings"}
          route={"/admin/bookings"}

        >
          {t("Bookings") }
        </HeaderButton>
        <HeaderButton
          onClick={() => history.push("/admin/lockers")}
          selected={window.location.pathname === "/admin/lockers"}
          route={"/admin/lockers"}

        >
          {t("Lockers") }
        </HeaderButton>
      </>
  ) 
}

const WalletRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  (
    <>
      <HeaderButton
        onClick={() => history.push("/wallet/settings")}
        selected={window.location.pathname === "/wallet/settings"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
        route={"/wallet/settings"}
      >
        {t("settings")} 
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/wallet/topups")}
        selected={window.location.pathname === "/wallet/topups"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
        route={"/wallet/topups"}
      >
        {t("topups")} 
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/wallet/metrics")}
        selected={window.location.pathname === "/wallet/metrics"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
        route={"/wallet/metrics"}
      >
        {t("metrics")} 
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/wallet/users")}
        selected={window.location.pathname === "/wallet/users"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
        route={"/wallet/users"}
      >
        {t("users")} 
      </HeaderButton>
    </>
  );
};

const AccessManagementRouteButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return  (
    <>
      <HeaderButton
        onClick={() => history.push("/access/email_list")}
        selected={window.location.pathname === "/access/email_list"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
        route={"/access/email_list"}
      >
        {t("email_list")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/access/domain_list")}
        selected={window.location.pathname === "/access/domain_list"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
        route={"/access/domain_list"}
      >
        {t("domain_list")}
      </HeaderButton>
    </>
  );
};

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: 1rem;
  white-space: nowrap;

  &::first-letter {
    text-transform: capitalize;
  }
`;

const MenuHeader = ({ title, route_group }) => {
  const { t } = useTranslation();
  const [headerIsOverflowing, setHeaderIsOverflowing] = useState(false)

  const getRouteGroup = () => {
    switch (route_group) {
      case "dashboard":
        return <DashboardRouteButtons />
  
      case "menu":
        return <MenuRoutesButtons />
  
      case "payments_and_orders":
        return <PaymentsAndOrdersRouteButtons />

      case "ratings":
        return <RatingsRouteButtons />

      case "delivery_and_takeaway":
        return <DeliveryAndTakeawayRouteButtons />

      case "loyalty":
        return <LoyaltyRouteButtons />
      
      case "service":
        return <ServiceRouteButtons />

      case "stock_control":
        return <StockRouteButtons />

      case "event_tickets":
        return <EventRouteButtons />

      case "wallet":
        return <WalletRouteButtons />

      case "access":
        return <AccessManagementRouteButtons />
  
      default:
        return null;
    }
  }

  const headerButtonsRef = useRef(null);

  useEffect(() => {
    const headerButtons = headerButtonsRef?.current;
  
    const isOverflowing = () => {
      return headerButtons?.scrollWidth > headerButtons?.clientWidth;
    };

    const checkOverflow = () => {
      if (isOverflowing()) {
        setHeaderIsOverflowing(true)
      } else{
        setHeaderIsOverflowing(false)
      }
    }
    // Initial check when the component mounts
    checkOverflow();
    // Check overflow when the div is resized
    const resizeObserver = new ResizeObserver(checkOverflow);

    resizeObserver.observe(headerButtons);
    // Cleanup the observer when the component is unmounted
    return () => resizeObserver.disconnect()
  }, []);

  const handleClick = (direction) => {
    const headerButtons = headerButtonsRef?.current;

    if (headerButtons) {
      const scrollAmount = 70;
  
      if (direction === 'right') {
        headerButtons.scrollLeft += scrollAmount;
      } else {
        headerButtons.scrollLeft -= scrollAmount;
      }
    }
  };

  return (
    <div
      className={`text-[#094553] bg-[#E2FEF1] py-5 w-full px-8 flex flex-row items-center gap-3`}
    >
      <Title>{t(title)}</Title>
      {headerIsOverflowing &&
        <ChevronLeftIcon
          height={40}
          width={40}
          color={'#094553'}
          onClick={() => handleClick('left')}
          className='cursor-pointer -my-2'
        />
      }
      <div
        ref={headerButtonsRef}
        className={`flex flex-1 w-0 flex-row items-center gap-3 overflow-auto scrollbar-thin py-1 -my-1 ${headerIsOverflowing ? '-mb-4' : ''}`}
      >
        {getRouteGroup()}
      </div>
      {headerIsOverflowing &&
        <ChevronRightIcon
          height={40}
          width={40}
          color={'#094553'}
          onClick={() => handleClick('right')}
          className='cursor-pointer -my-2'
        />
      }

    </div>
  );
};


export default MenuHeader;
