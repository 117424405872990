import React, { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const CoverPreview = ({color, logo, logoWidth}) => {
  const { t } = useTranslation();

  const logoRef = useRef(null);
  const [ratio, setRatio] = useState(0);

  const getContainerStyles = (logoWidth, ratio) => ({
    height: logoWidth ? 'auto' : (ratio >= 1 ? '30%' : '50%'),
    width: logoWidth ? `${logoWidth}%` : (ratio >= 1 ? '70%' : '30%'),
    maxHeight: '100%',
    maxWidth: logoWidth ? `${logoWidth}%` : ratio > 1 ? '100%' : '50%',
    objectFit: 'contain',
    display: 'flex',
  })

  const getRatio = () => {
    const logo = logoRef.current;
    const logoHeight = logo?.naturalHeight;
    const logoWidth = logo?.naturalWidth;
    setRatio(logoWidth/logoHeight);
  };

  const getLogoStyles = (logoWidth, ratio) => ({
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: logoWidth || ratio > 1 ? '100%' : '50%',
    objectFit: 'contain',
    borderRadius: '4px',
    margin: 'auto',
  })

  return (
    <div className='flex justify-center items-center w-full h-full transition-colors' style={{backgroundColor: color}}>
      {logo ?
        <div className='transition-all' style={getContainerStyles(logoWidth, ratio)}>
          <img src={logo} ref={logoRef} onLoad={getRatio} className='transition-all' style={getLogoStyles(logoWidth, ratio)}/>
        </div>
        :
        <div className='text-gray-300 border-2 border-current border-dashed rounded-lg py-4 px-2 filter drop-shadow'>
          {t('your_logo')}
        </div>
      }
    </div>
  );
};
