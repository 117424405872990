import React from 'react';
import { useTranslation } from "react-i18next";
import StatusBadge from './StatusBadge';
import OrderType from './OrderType';
import { awareDatetimeString } from 'logic/functions';
import OrderStatusHandler from './OrderDetailComponents/OrderStatusHandler';
import OrderClientInfo from './OrderDetailComponents/OrderClientInfo';
import DeliveryAddressInfo from './OrderDetailComponents/DeliveryAddressInfo';
import OrderProductsInfo from './OrderDetailComponents/OrderProductsInfo';
import OrderGeneralNotes from './OrderDetailComponents/OrderGeneralNotes';
import OrderTotals from './OrderDetailComponents/OrderTotals';
import OrderPaymentMethod from './OrderDetailComponents/OrderPaymentMethod';
import OrderBumerangQR from './OrderDetailComponents/OrderBumerangQR';
import OrderResendHandler from './OrderDetailComponents/OrderResendHandler';
import OrderRePrintHandler from './OrderDetailComponents/OrderRePrintHandler';

const OrderDetail = ({order, products, productsModifiers, modifiersModifiers, changeStatus, statusLoading, reSendOrder, rePrintOrder}) => {
  const { t } = useTranslation();

  const total = order.base_amount + order.tip + (order.delivery_fee_cents || 0) - ( order.credit > 0 ? 0 : order.discount ) - order.credit;

  return (
	<div style={{padding: '20px'}}>
		<div style={{display: 'flex', justifyContent: 'space-between'}}>
			<div style={{fontWeight: 500, fontSize: '22px', color: '#222222'}}>#{t('Order')}: <span style={{fontWeight: 700}}>{order.code}</span></div>
			<div style={{display: 'flex', gap: '12px', justifyContent: 'center', alignItems: 'center'}}>
				<div><OrderType type={order.type}/></div>
				<div><StatusBadge status={order.status} refunded={order.refunded} payment={order}/></div>
			</div>
		</div>
		<div style={{fontWeight: 500, marginTop: "5px"}}>{awareDatetimeString(order.created_at, "Europe/Madrid")}</div>
		<div style={{fontWeight: 400, marginTop: "5px"}}>{t('Table')}: <span style={{fontWeight: 600}}>{order.type === "table" ? order.tableName : "-"}</span></div>
		<OrderStatusHandler order={order} changeStatus={changeStatus} statusLoading={statusLoading}/>
		<OrderClientInfo order={order}/>
		{order.type === "delivery" && order.delivery_address &&
			<DeliveryAddressInfo order={order}/>
  	}
		<OrderProductsInfo
			products={products}
			products_modifiers={productsModifiers}
			modifiers_modifiers={modifiersModifiers}
		/>
		{order.comment &&
			<OrderGeneralNotes note={order.comment}/>
		}
		<div style={{ marginTop: "25px"}}>
			<OrderTotals text={"total_bill"} amount={total}/>
			<div style={{marginTop: "15px", display: 'flex', flexDirection: 'column', gap: "10px"}}>
				<OrderTotals text={"subtotal"} amount={order.base_amount} light={true}/>
				<OrderTotals text={"tip"} amount={order.tip} light={true}/>
				{order.delivery_fee_cents > 0 &&
					<OrderTotals text={"delivery_fee"} amount={order.delivery_fee_cents} light={true}/>
				}
				{order.discount > 0 && order.credit === 0 &&
					<OrderTotals text={"discounts"} amount={order.discount} light={true} negative={true}/>
				}
				{order.credit > 0 &&
					<OrderTotals text={order.payment_method === "wallet" ? "wallet" : "credit_cents"} amount={order.credit} light={true} negative={true}/>
				}
			</div>
		</div>
		{(order.payment_method && order.status !== "pending_cash") && 
			<OrderPaymentMethod method={order.payment_method} brand={order.card_brand} last4={order.card_last4}/>
		}
		{order.bumerang_qr && Boolean(order.restaurant_bumerang) && 
			<OrderBumerangQR qr_string={order.bumerang_qr}/>	
		}	
		{(order.allow_yumminn_reprint && order.status !== "pending_cash") &&
			<OrderRePrintHandler order={order} reSendOrder={rePrintOrder} reSendLoading={statusLoading}/>
		}
		{["error_sending_info_to_pos", "waiting_send_info_to_pos"].includes(order.status) &&
			<OrderResendHandler order={order} reSendOrder={reSendOrder} reSendLoading={statusLoading}/>
		}
	</div>
  );
};
export default OrderDetail;
