import React, { useState, useEffect } from 'react';
import { TicketsSoldTableContainer } from './TicketsContainers';
import { Trans, useTranslation } from 'react-i18next';
import { TicketsEmptyStateIcon } from 'common/EmptyStateIcons';
import EmptyState from 'common/EmptyState';
import Spinner from 'common/Spinner';
import { TicketSoldRow } from './TicketSoldRow';
import { Pagination } from 'common/Pagination';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { patchEventStock } from './EventApi';

export const TicketsSoldTable = ({soldTicketsList, setSelectedTicketPurchase, isLoading, mutate, page, setPage, isValidating}) => {

  const { t } = useTranslation();
  const [availableStock, setAvailableStock] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([])
  const [modifiedStocks, setModifiedStocks] = useState([]); // New state to track changes

  const handleSaveChanges  = () => {
    console.log("handleSaveChanges :", modifiedStocks);
    if(modifiedStocks.length === 0){
      return
    }
    patchEventStock(modifiedStocks, t, setLoading, setModifiedStocks, mutate);
  };

  useEffect(() => {
    const { count, page, total_pages, tickets = [] } = soldTicketsList;
    setModifiedStocks([])
    setTickets(tickets)

    if (tickets?.length > 0) {
      console.log("Contenido de tickets.ticket_type:", tickets.ticket_type);

      const initialStockState = tickets.flatMap((ticket) => {  
        return ticket.ticket_type.map((t) => {
          const stockEntry = {
            event_recurring_date_id: t.eventRecurringDateId || null,
            event_date_id: t.eventDateId || null,
            event_ticket_stock_id: t.eventTicketStockId || null,
            stock: t.availableTickets ?? null,
            event_ticket_id: t.eventTicketId
          };
          return stockEntry;
        });
      });
      
      setAvailableStock(initialStockState);
    }

  }, [soldTicketsList]);

  return (
    <TicketsSoldTableContainer>
      <div className="title">{t('tickets_sold')}</div>
      <div className="table">
        <div className="header">
          <div className="column">{t('Date and hour')}</div>
          <div className="column">{t('tickets')}</div>
          <div className="column">{t('available')}</div>
          <div className="column">{t('sold')}</div>
        </div>

        <div className="table_body">
          {!isLoading && (!tickets || tickets?.length === 0) && (
            <div className="empty_state">
              <EmptyState
                text={
                  <Trans i18nKey="no_tickets_yet">
                    <b>Aún no tienes entradas</b> para gestionar
                  </Trans>
                }
                icon={<TicketsEmptyStateIcon />}
              />
            </div>
          )}

          {!isLoading && tickets?.map((ticketPurchase) =>
            <TicketSoldRow
              className='row'
              key={ticketPurchase.ticket_purchase_id}
              purchaseTicket={ticketPurchase}
              onClick={setSelectedTicketPurchase}
              availableStock={availableStock}
              setAvailableStock={setAvailableStock}
              setModifiedStocks={setModifiedStocks}
              loading={loading}
            />
          )}
          <div className='flex justify-between align-center pt-2'>
            <Pagination
              page={page}
              numOfPages={soldTicketsList.total_pages}
              numOfEntries={soldTicketsList.count}
              entryName='dates'
              className='pagination'
              setPage={setPage}
              disabled={isValidating}
            />
            <Button 
              size={BUTTON_SIZES.LG}
              variant={BUTTON_VARIANTS.PRIMARY}
              onClick={() => handleSaveChanges()}
              label={'save'}
              disabled={loading || modifiedStocks.length === 0}
              loading={loading}
          />
          </div>
          {isLoading && (
            <div className="spinner_container">
              <Spinner className="big_spinner" />
            </div>
          )}
        </div>
      </div>
    </TicketsSoldTableContainer>
  );
};

