import React, { useCallback, useRef } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { RefreshIcon } from '@heroicons/react/outline';
import { InfoLabel } from 'components/yumminn/InfoLabel';
import { getContrastRatio } from 'styles/colors';
import { theme_select_styles } from 'logic/defaults';
import { mockThemes } from 'components/previews/constants';
import { Tooltip } from 'common/Tooltip';
import { ThemeSwatch } from '../ThemeSwatch';
import { FilePond } from 'react-filepond';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { Icon, IconType } from 'common/Icon';

export const MIN_CONTRAST = 2.39; // actually, is 3, but our brand color (#5FB894) won't pass xD
const RECOMMENDED_CONTRAST = 4.5;

const themeOptions = Object.keys(mockThemes).map(theme => ({ value: theme, label: theme }));

export const ColorSettings = ({ colors=null, theme=null, setColors=null, setTheme=null, backgroundImage=null, setBackgroundImage=null }) => {
  const { t } = useTranslation();

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };


  const changeImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fd = new FormData();
      const url = file ? URL.createObjectURL(file) : null;
      file && fd.append("background_image", file);
      setBackgroundImage({file, fd, url});
    }
  };


  const onThemeChange = themeOption => setTheme(themeOption.value);

  const onColorChange = color => setColors(prev => ({...prev, ...color}));

  const getContrastInfo = useCallback((color) => {
    if (!color) return;
    const bgColor = mockThemes[theme].background_rised;
    
    const contrast = getContrastRatio(color, bgColor);

    if (contrast >= RECOMMENDED_CONTRAST) {
      return {
        type: 'info',
        message: 'contrast_good',
      };
    }

    if (contrast >= MIN_CONTRAST) {
      return {
        type: 'warning',
        message: 'contrast_minimum',
      };
    }

    return {
      type: 'error',
      message: 'contrast_bad',
    };
  }, [theme]);

  const getBorderColor = (color) => {
    const COLORS = {
      info: 'border-gray-300',
      warning: 'border-amber-300',
      error: 'border-red-300',
    };

    return COLORS[getContrastInfo(color)?.type] || 'border-gray-300';
  };

  const containerStyles = 'flex flex-col gap-2';
  const titleStyles = 'font-semibold leading-tight w-32';
  const getLabelStyles = (color) => `flex justify-between items-center w-min h-10 px-2 bg-white border-2 ${getBorderColor(color)} rounded-lg cursor-pointer z-10`;
  const inputStyles = 'absolute opacity-0 w-0 pointer-events-none';
  const swatchStyles = 'w-6 h-6 shadow-md rounded-full ml-2';

  return (
    <div className='flex flex-col gap-8 items-start my-5'>
      <div className={containerStyles}>
        <h3 className={'font-semibold leading-tight'}>
          {t('theme')}
        </h3>
        <Select
          value={themeOptions.find(themeOption => themeOption.value === theme)}
          options={themeOptions}
          onChange={onThemeChange}
          styles={theme_select_styles}
          components={{SingleValue, Option}}
          className='w-48 absolute'
        />
      </div>
      {setColors && 
      <div className='flex flex-col justify-start gap-8'>
        <div className={containerStyles}>
          <h3 className={titleStyles}>
            {t('color_primary')}
          </h3>
          <div className='flex gap-3 relative z-0'>
            <label className={getLabelStyles(colors.primary)}>
              <span className='w-[7.5ch]'>{colors.primary?.toUpperCase()}</span>
              <span className={swatchStyles} style={{backgroundColor: colors.primary}}/>
              <input
                type='color'
                defaultValue={colors?.primary_color}
                onInput={event => onColorChange({primary: event.target.value})}
                className={inputStyles}
              />
            </label>
            <InfoLabel {...getContrastInfo(colors.primary)} isVisible/>
          </div>
        </div>
        <div className={containerStyles}>
          <h3 className={titleStyles}>
            {t('color_secondary')}
          </h3>
          <div className='flex gap-3 relative z-0'>
            <label className={getLabelStyles(colors.tertiary)}>
              <span className='w-[7.5ch]'>{colors.tertiary?.toUpperCase() || colors.primary?.toUpperCase()}</span>
              <span className={swatchStyles} style={{backgroundColor: colors.tertiary || colors.primary}}/>
              <input
                type='color'
                defaultValue={colors?.teriary_color || colors?.primary_color}
                onInput={event => onColorChange({tertiary: event.target.value})}
                className={inputStyles}
              />
            </label>
            <div className={`-ml-4 flex bg-principal p-2 pl-4 rounded-r-lg z-0 transition-all`}>
              <button
                type='button'
                onClick={() => onColorChange({tertiary: null})}
                className='transition-all focus:outline-none'
              >
                <RefreshIcon className='h-6 w-6 text-white transform hover:rotate-45 transition-transform'/>
                <Tooltip bgColor='#FFFFFF' position='top'>
                  {t('copy_button_color')}
                </Tooltip>
              </button>
            </div>
            <InfoLabel {...getContrastInfo(colors.tertiary)} isVisible={!!colors.tertiary}/>
          </div>
        </div>
      </div>}
      {setBackgroundImage &&
      <div className='flex flex-col w-full justify-center gap-5'>
        <div className='flex gap-4 items-center text'>
          <Button
            onClick={handleButtonClick}
            label={backgroundImage ? "swap_photo" : "add_photo"}
            variant={BUTTON_VARIANTS[backgroundImage ? "SECONDARY" : "PRIMARY"]}
          />
          {backgroundImage &&
            <>
              <Icon type={IconType.OPEN} onClick={() => window.open(backgroundImage.url,"_blank")} size={23}/>
              <Icon type={IconType.TRASH} onClick={() => setBackgroundImage(null)} size={23}/>
            </>
          }
        </div>

        {/* HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
        <input
          type="file"
          accept="image/*" // Limit to image files
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={changeImage}
        />
        <p><span className='font-semibold'>Sugerencias:</span> En color blanco y sin fondo (png) de 2MB máx.</p>
      </div>}
    </div>
  );
};

const SingleValue = props => {
  const { t } = useTranslation();

  return (
    <components.SingleValue {...props}>
      <div className='flex justify-between items-center gap-4 w-full'>
        <span className='overflow-hidden overflow-ellipsis flex-1 w-0'>
          {t(props.data.label)}
        </span>
        <ThemeSwatch theme={props.data.value}/>
      </div>
    </components.SingleValue>
  );
};

const Option = props => {
  const { t } = useTranslation();

  return (
    <components.Option {...props}>
      <div className='flex justify-between items-center gap-4'>
        <span>{t(props.label)}</span>
        <ThemeSwatch theme={props.value}/>
      </div>
    </components.Option>
  );
};
