import React, { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { mockThemes } from '../constants';

export const RestaurantGroupPreview = ({themeName, logo, title, subtitle, backgroundImage, restaurants}) => {
  const { t } = useTranslation();
  const [ratio, setRatio] = useState(0);
  const logoRef = useRef(null);

  const theme = {
    ...mockThemes[themeName],
  };

  const getRatio = () => {
    const logo = logoRef.current;
    const logoHeight = logo?.naturalHeight;
    const logoWidth = logo?.naturalWidth;
    setRatio(logoWidth/logoHeight);
  };

  const getContainerStyles = (ratio) => ({
    height: '20%',
    width: (ratio >= 1 ? '50%' : '30%'),
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
    display: 'flex',
  })

  const logoStyles = {
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
    borderRadius: '4px',
    margin: 'auto',
  }

  return (
    <div className='flex flex-col items-center w-full h-full transition-colors pt-2 gap-2' style={{backgroundColor: theme.background, backgroundImage: backgroundImage ? `url(${ backgroundImage })` : "", backgroundSize: "cover", backgroundPosition: "center center"}}>
      {logo &&
        <div className='transition-all' style={getContainerStyles(ratio)}>
          <img src={logo} ref={logoRef} onLoad={getRatio} className='transition-all' style={logoStyles}/>
        </div>
      }
      <div>
        {title &&
          <p style={{textAlign: 'center', color: theme.text}} className='text-md font-semibold mx-2'>{title}</p>
        }
        {subtitle &&
          <p style={{textAlign: 'center', color: theme.text}} className='text-[10px] mx-2'>{subtitle}</p>
        }
      </div>
      {restaurants &&
      <div className='grid grid-cols-2 gap-2'>
        {restaurants.map((option, index) => (
          <div style={{backgroundColor: theme.background_rised, color: theme.text, borderColor: theme.border}}  className="flex flex-col rounded-lg overflow-hidden w-[75px] shadow border-2 rounded-lg">
          {option.image?.url &&
            <img src={option.image?.url} className="object-cover min-h-[65px] w-full" alt='card_product' />
          }
          <div className="flex flex-col justify-center min-h-[20px] p-1 h-full">
            <p style={{fontSize: "8px", fontWeight: 600, margin: 0}}>{option.title || option.restaurant.name}</p>
            {subtitle && 
              <p style={{fontSize: "6px", margin: 0}}>{option.description}</p>
            }
          </div>
        </div>
        ))
        }
      </div>}
    </div>
  );
};
