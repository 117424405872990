import React, { useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";
import './customDatePickerStyle.css';
import { EventImage } from "./EventImage";
import { NumberInput } from 'common/Input';
import { calculateCents, calculateEuros } from "services/payments";
import { Tooltip } from "common/Tooltip";

export const TicketSpecs = ({
  ticketDraft,
  editTicket, 
  ticketToEdit
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(ticketDraft.name);
  const [description, setDescription] = useState(ticketDraft.description);
  const [charCount, setCharCount] = useState(description ? description?.length : 0);
  const priceString = calculateEuros(ticketDraft.price_cents)
  const priceEuros = parseFloat(priceString.replace(/,/g, '.'));
  const [priceCents, setPriceCents] = useState(priceEuros);
  const initialStock = ticketDraft.stocks && ticketDraft.stocks.length > 0 ? ticketDraft.stocks[0].initial_stock : null;
  const [stock, setStock] = useState(initialStock)

  const maxCharCount = 150;

  const handleDescriptionChange = (description) => {
    if (description.length <= maxCharCount) {
      setDescription(description);
      setCharCount(description.length);
      editTicket({ description });
    }
  };

  const handlePriceCentsChange = (price) => {
    const price_cents = Number(calculateCents(price))
    setPriceCents(price);
    editTicket({ price_cents });
  };

  const handleNameChange = (name) => {
    setName(name);
    editTicket({ name });
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };

  const handleFileSelect = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      editTicket({ image: imageUrl, imageFile: selectedFile });
    } 
  };

  const handleStock = (e) => {
    const newStock = e.target.value
    editTicket({ stock: newStock });
    setStock(newStock);
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0]; 
    if (file && file.type.startsWith("image/")) {
      handleFileSelect({ target: { files: [file] } }); 
    }
  };

  return (
    <div className="flex gap-8 mb-4">
    
      <div className="cursor-pointer flex flex-col items-center justify-center mt-4 px-10">

        <div style={{textAlign: 'center'}} className='pb-6 px-12'>
          <Trans i18nkey="explanation_ticket_image">
            <span className='font-bold'>Por defecto</span>, en la webapp se verá un <span className='font-bold'>icono de entrada</span>, pero puedes añadir una imagen aquí.
          </Trans>
        </div>

        <div 
          colSpan={1} 
          className='bg-[#EDEDED] rounded-3xl relative min-w-[300px] min-h-[230px] items-center flex flex-col shadow mb-4 border-dashed border-4 border-[#BEBEBE]' 
          onClick={handleButtonClick}
          onDragOver={(e) => e.preventDefault()} 
          onDrop={handleDrop} 
        >
     
          <EventImage object={ticketDraft} type='ticket'/>

          {/* HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
          <input
            type="file"
            accept="image/*" // Limit to image files
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileSelect}
          />

        </div>
        {/*HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
        <div style={{textAlign: 'center', padding: "15px 20px"}}>
          <Trans i18nkey="suggestions_ticket">
            <b>Sugerencias</b>: en color blanco y sin fondo (png) de 2MB máx.
          </Trans>
        </div>

      </div>


      <div className="flex flex-col gap-4 w-full py-4 pr-12">

        <div className="flex flex-col justify-between gap-2 items-start w-full text-base">
          <label className="whitespace-nowrap" htmlFor="name">
            {t("name of the ticket")}
          </label>
          <input
            id="name"
            className="input font-medium rounded-[10px] input-bordered h-14 w-full border-2 border-[#D4D4D8] placeholder-gray-400"
            value={name}
            placeholder={ticketDraft.name || t('Ticket')}
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </div>

        <div className="flex flex-col justify-between gap-2 items-start w-full relative">
          <label
            className="whitespace-nowrap self-start mt-2 text-base"
            htmlFor="description"
          >
            {t("Description")} ({t('optional')})
          </label>
          <textarea
            id="description"
            className="input input-bordered rounded-[10px] border-2 border-[#D4D4D8] font-medium w-full h-[120px]"
            value={description}
            placeholder={ticketDraft.description || t('write here')}
            onChange={(e) => handleDescriptionChange(e.target.value)}
          ></textarea>
          <div style={{position: 'absolute', bottom: "12px", right: "10px"}} className="self-end text-sm text-gray-500">
            {charCount}/{maxCharCount}
          </div>
        </div>

        <div className="flex flex-col justify-between gap-2 items-start max-w-[200px]">
          <NumberInput
            id="price"
            type="number"
            pattern="[0-9]*[.,]?[0-9]+"
            min={0}
            step={0.01}
            name='price'
            label={t('price')}
            className='w-full'
            value={priceCents}
            unit={'€'}
            onChange={(e) => handlePriceCentsChange(e.target.value)}
          /> 
        </div>

        <div className="flex flex-col justify-between gap-2 items-start max-w-[200px]">
          <NumberInput 
            id="stock"
            type='number' 
            className='with_arrows w-full'
            min={0}
            step={1}
            name='price'
            label={t('amount of tickets available')}
            value={stock}
            onChange={(e) => handleStock(e)}
          />
          <Tooltip position='bottom' interactive left="107px" size={"23rem"}>
            <p>{!ticketToEdit ? t('when_sold_out_explanation') : t('initial_stock_explanation')}</p>
          </Tooltip>
        </div>


      </div>
    </div>
  );
};
