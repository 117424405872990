import React, { forwardRef } from 'react';
import Select from 'react-select';
import { InputContainer } from './InputContainers';
import { useTranslation } from 'react-i18next';
import { getTextColor } from 'styles/colors';

export const SelectInput = forwardRef(({
  label,
  loading = false,
  disabled = false,
  error,
  styles = {},
  className = '',
  ...selectProps
}, ref) => {
  const { t } = useTranslation();

  return (
    <InputContainer className={`select_input ${className}`}>
      <label>
        {label && typeof label === 'string' ? <h6>{t(label)}</h6> : label}
        <Select
          {...selectProps}
          isLoading={loading}
          isDisabled={disabled}
          styles={{...defaultStyles, ...styles}}
          placeholder={selectProps.placeholder && typeof selectProps.placeholder === 'string' ? t(selectProps.placeholder) : selectProps.placeholder ? selectProps.placeholder : ''}
          menuPlacement={selectProps.menuPlacement || 'auto'}
          ref={ref}
        />
      </label>
      <div className='input_info'>
        {error &&
          <i className='error'>{t(error)}</i>
        }
      </div>
    </InputContainer>
  );
});

const defaultStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '1rem',
    height: '56px',
    borderColor: (state.isSelected) ? '#094553' : '#D4D4D8',
    borderWidth: '2px',
    borderRadius: '10px',
    backgroundColor: state.isDisabled ? '#F6F6F6' : '#FFF',
    opacity: state.isDisabled ? '0.7' : '1',
    boxShadow: 'none',
    outline: state.isFocused ? '2px solid #094553' : 'none',
    outlineOffset: '2px',

    '&:hover': {
      color: '#094553',
      borderColor: state.isFocused ? '#094553' : '#D4D4D8',
    }
  }),
  input: (base) => ({
    ...base,
    fontSize: '1rem',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 99999999,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = '#094553';  //true ?  '#26DFA833' : 
    const colorfocus = '#26DFA833';   //true ? '#F8F8F8' : 
    return {
      ...styles,
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? color
        : isFocused
        ? colorfocus
        : null,
    };
  },
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    color: '#40404080',
    '&:hover': {
      color: '#094553',
    },
    '&:svg': {
      color: '#094553',
    }
  }),
  multiValue: (baseStyles, { isDisabled, data }) => ({
    ...baseStyles,
    display: 'flex',
    alignItems: 'center',
    minHeight: '36px',
    height: 'calc(100% - 12px)',
    gap: '4px',
    padding: '6px',
    fontSize: '1rem',
    fontWeight: '500',
    paddingRight: isDisabled ? '3px' : '',
    flexShrink: 0,
    borderRadius: '2em',
    color: data?.color ? getTextColor(data.color) : '#222',
    backgroundColor: data?.color || '#DDD',
    '&>div:first-of-type': {
      color: 'inherit',
    },
  }),
  multiValueRemove: (baseStyles, { isDisabled }) => ({
    ...baseStyles,
    display: isDisabled ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#404040',
    backgroundColor: '#FFF',
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '100%',
    flexShrink: 0,
    '&>svg': {
      height: '100%',
      width: '100%',
    },
  }),
  valueContainer: (baseStyles) => ({
  ...baseStyles,
    height: '100%',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  }),
  dropdownIndicator: (styles, { isFocused }) => ({
    ...styles,
    color: 'inherit',
    fill: 'currentColor',
    rotate: isFocused ? '180deg' : '0deg',
    transition: '0.15s ease-in-out',
  }),
};