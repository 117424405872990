import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputContainer } from './InputContainers';
import { Icon, IconType } from '../Icon';

export const TextInput = forwardRef(({
  label,
  password,
  leftIcon,
  rightIcon,
  copy,
  error,
  className = '',
  onKeyDown = null,
  autoFocus = false,
  subtitle=null,
  ...inputProps
}, ref) => {
  const { t } = useTranslation();

  const [hidden, setHidden] = useState(password);
  const [copied, setCopied] = useState(false);

  const toggleVisibility = (event) => {
    event.preventDefault();
    setHidden(prev => !prev);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inputProps.value).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false) , 5000)
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <InputContainer className={className}>
      <label>
        {label && typeof label === 'string' ? <h6>{t(label)}</h6> : label}
        {subtitle && typeof subtitle === 'string' ? <p style={{fontSize: "14px", marginBottom: "10px", color: "#999999"}}>{t(subtitle)}</p> : subtitle}
        <div className='input_wrapper'>
          {leftIcon}
          <input
            {...inputProps}
            type={hidden ? 'password' : 'text'}
            placeholder={t(inputProps.placeholder || '')}
            readOnly={!inputProps.onChange}
            autoComplete={inputProps.autoComplete || 'off'}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
            ref={ref}
          />
          {rightIcon}
          {password &&
            <Icon
              type={IconType[hidden ? 'EYE' : 'EYE_CROSS']}
              onClick={toggleVisibility}
              aria-label={hidden ? t('show_password') : t('hide_password')}
            />
          }
          {copy &&
            <button onClick={copyToClipboard} className='focus:outline-none'>
              <div className='flex items-center gap-4'>
                <div className='h-5 border-l border-[#DCDCDC]'></div>
                <Icon type={IconType.COPY} />
                <u>{copied ? t('copied') : t('copy')}</u>
              </div>
            </button>
          }
        </div>
      </label>
      <div className='input_info'>
        {error &&
          <i className='error'>{t(error)}</i>
        }
        {inputProps.maxlength &&
          <span className={`max_length ${(inputProps.value?.length || 0) >= inputProps.maxlength ? 'reached' : ''}`}>
            {inputProps.value?.length || 0}/{inputProps.maxlength}
          </span>
        }
      </div>
    </InputContainer>
  );
});