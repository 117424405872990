export const STATUS_COLORS = {
  received: '#DDDDDD',
  validated: '#D4F8D3',
};

export const STATUS_KEYS = {
  success: 'received',
  validated: 'validated',
};

export const TYPE_OF_TICKET_EVENT_COLORS = {
  0: '#D4F8D3',
  1: '#D3F8F8',
  2: '#F1D3F8'
};