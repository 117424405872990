import { CustomGroupPreview } from 'components/previews';
import React, { useContext } from 'react';
import { CustomGroupPositionContainer } from './ProductHighlightContainers';
import { HalfMobileMockup } from 'components/previews/HalfMobileMockup';
import { ImageInput, SelectInput, TextInput } from 'common/Input';
import { Trans, useTranslation } from 'react-i18next';
import { Context } from 'data/authContext';

const EMPTY_POSITION = {
  restaurant: null,
  l1: null,
  l2: null,
  l3: null,
};

export const CustomGroupPosition = ({ customGroupDraft, catalog, editCustomGroup }) => {
	const { state: { selectedRestaurantId } } = useContext(Context);

  const { t } = useTranslation();

  const isBanner = customGroupDraft.custom_group_type === 'commercial';

  const options = [
    {
      label: t('catalog_start'),
      value: { restaurant: selectedRestaurantId }
    },
    {
      label: t('l1_start'),
      options: catalog?.supergroups?.map(l1 => ({
        value: { l1: l1.id },
        label: l1.visible_name || l1.name || l1.tpv_name,
      })) || [],
    },
    {
      label: t('l2_start'),
      options: catalog?.category_groups?.map(l2 => ({
        value: { l2: l2.id },
        label: l2.visible_name || l2.name || l2.tpv_name,
      })) || [],
    },
    {
      label: t('l3_start'),
      options: catalog?.product_categories.map(l3 => ({
        value: { l3: l3.id },
        label: l3.visible_name || l3.name || l3.tpv_name,
      })) || [],
    },
  ];

  const optionsFlat = options.map(option => option.options || option).flat();

  const value = optionsFlat.find(option => {
    const [level, id] = Object.entries(option.value)[0]
    return customGroupDraft[level] === id;
  });

  const selectPosition = position => {
    editCustomGroup({
      ...EMPTY_POSITION,
      ...position.value,
    });
  };

  return (
    <CustomGroupPositionContainer>
      <HalfMobileMockup>
        <CustomGroupPreview customGroupDraft={customGroupDraft} catalog={catalog}/>
      </HalfMobileMockup>
      <div>
        {isBanner &&
          <TextInput
            label='banner_internal_title'
            placeholder='banner_internal_title_placeholder'
            value={customGroupDraft.name}
            onChange={e => editCustomGroup({ name: e.target.value })}
            maxlength={50}
          />
        }
        {!isBanner &&
          <>
            <h3>{t('highlighted_position')}</h3>
            <p><b>{t('choose_stategic_position')}</b></p>
            <p>{t('examples') + ':'}</p>
            <ul>
              <li>
                <Trans
                  i18nKey='highlighted_position_suggestion_1'
                  default='Destaca los productos <b>más vendidos, nuevos o sugerencias del chef</b> al inicio de la carta.'
                  components={{b: <b/>}}
                />
              </li>
              <li>
                <Trans
                  i18nKey='highlighted_position_suggestion_2'
                  default='Sugiere tapas que maridan perfectamente con tus vinos en la <b>categoría de vinos</b>.'
                  components={{b: <b/>}}
                />
              </li>
            </ul>
          </>
        }
        <SelectInput
          label={isBanner ? 'banner_position' : 'highlighted_position'}
          value={value}
          options={options}
          onChange={selectPosition}
          placeholder='select'
          menuPortalTarget={document.body}
        />
        {isBanner &&
          <ImageInput
            image={customGroupDraft.image.url}
            onUpload={image => editCustomGroup({ image: image })}
            onDelete={() => editCustomGroup({ image: { url: null } })}
            className='image_input'
            info={
              <Trans
                i18nKey='banner_image_info'
                defaults='Especificaciones:<br/><b>Banner de 800x350 con un margen de seguridad de 50px (mínimo)</b> a cada lateral para la visibilidad completa en diferentes dispositivos.'
                components={{ br: <br/>, b: <b/> }}
              />
            }
          />
        }
      </div>
    </CustomGroupPositionContainer>
  );
};