import moment from 'moment';

export const generateRecurrenceDates = (startDate, recurrenceType, endDate) => {
    const recurrenceDates = [];
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
  
    switch (recurrenceType) {
      case 'each_day':
        // Every day recurrence until the end date, inclusive
        while (startMoment.isSameOrBefore(endMoment)) {
          recurrenceDates.push(startMoment.format('YYYY-MM-DD'));
          startMoment.add(1, 'day'); // Increment by 1 day
        }
        break;
  
      case 'every_month_the_date': {
        // Same date every month until the end date, skip months without the exact day
        const targetDay = startMoment.date(); // The day of the month (e.g., 31)
        while (startMoment.isSameOrBefore(endMoment)) {
          // Check if the current month has the target day
          const lastDayOfMonth = startMoment.clone().endOf('month').date();
          if (targetDay <= lastDayOfMonth) {
            startMoment.date(targetDay); // Adjust to the target day if valid
            recurrenceDates.push(startMoment.format('YYYY-MM-DD'));
          }
          startMoment.add(1, 'month').startOf('month'); // Move to the next month
        }
        break;
      }
  
      case 'every_month_the_weekday': {
        // Same weekday every month until the end date, inclusive
        const targetWeekday = startMoment.format('dddd'); // Get the weekday name of start date
  
        while (startMoment.isSameOrBefore(endMoment)) {
          if (startMoment.format('dddd') === targetWeekday) {
            recurrenceDates.push(startMoment.format('YYYY-MM-DD'));
          }
          startMoment.add(1, 'week'); // Increment by 1 week
        }
  
        // Check if the end date itself should be included
        if (
          endMoment.format('dddd') === targetWeekday &&
          !recurrenceDates.includes(endMoment.format('YYYY-MM-DD'))
        ) {
          recurrenceDates.push(endMoment.format('YYYY-MM-DD'));
        }
        break;
      }
  
      default:
        return [];
    }
  
    return recurrenceDates;
};
  

export const generateRandomId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
