import React, { useState, useEffect, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Context as AuthContext } from '../../../data/authContext';
import RestaurantSelect from "components/yumminn/RestaurantSelect";
import MenuHeader from "pages/home/components/overview/components/MenuHeader";
import { baseAuth } from "logic/api";
import { POS_LIST } from "data/posIntegrations";
import shareIcon from "../../../img/ShareIcon.svg"
import green_check from "../../../img/green_check.svg";
import warningIcon from "../../../img/warningIcon.png";
import logo from '../../../img/YumminnLogo.svg';
import Spinner from "common/Spinner";
import { Button } from "common/Button";
import { ConfirmClearAllPopup } from "./Lockers/LockerContainers";
import { Popup } from "common/Popup";

const ConfirmDisconnection = ({isOpen, confirm, cancel, posIntegration}) => {
  const { t } = useTranslation();

  return (
    <Popup isOpen={isOpen}>
      <div style={{maxWidth: "450px", textAlign: 'center'}}>
        <ConfirmClearAllPopup>
          <h3>{t('disconnect_yumminn_from')} {POS_LIST[posIntegration] ? POS_LIST[posIntegration]["label"] : "POS"}</h3>
          <p>
            <Trans i18nKey={'disconnect_yumminn_from_message'}>
              Your diners <b>will not be able to order or pay via Yumminn, and both the table and menu synchronization</b> will be lost.
            </Trans>
          </p>
          <footer>
            <button onClick={cancel}>
              {t('no_cancel')}
            </button>
            <button style={{backgroundColor: "#E35656", border: "none"}} onClick={confirm}>
              {t('yes_continue')}
            </button>
          </footer>
        </ConfirmClearAllPopup>
      </div>
    </Popup>
  );
};

const PosConnection = () => {
  const { t } = useTranslation();

  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status') || null;
  const failedConnection = status && status.toString() !== "200" ? true : false;

  const [posIntegration            , setPosIntegration]             = useState(null);
  const [connectionStatus          , setConnectionStatus]           = useState(null);
  const [squareConnectionUrl       , setSquareConnectionUrl]        = useState(null);
  const [loading                   , setLoading]                    = useState(false);
  const [showDisconnectionPopup    , setShowDisconnectionPopup]     = useState(false);

  const getConnectionStatus = () => {
    setLoading(true)
    const id = selectedRestaurant[0].value;
    let endpoint = `/restaurant/pos-connection/restaurant/${id}`;
    baseAuth
      .get(endpoint)
      .then(res => {
        const data = res.data
        const pos_integration = data.pos_integration
        const connection_status = data.connection_status
        const square_connection_url = data.square_connection_url
        setSquareConnectionUrl(square_connection_url)
        setPosIntegration(pos_integration)
        setConnectionStatus(connection_status)
      })
      .finally(() => {setLoading(false)})
    
  }

  useEffect(() => {
    getConnectionStatus();
  }, [selectedRestaurant[0].value]);

  const revokeSquareAuth = () => {
    setLoading(true)
    setShowDisconnectionPopup(false)
    const id = selectedRestaurant[0].value;
    let endpoint = `/payment/square-revoke-auth/${id}`;
    baseAuth
      .get(endpoint)
      .then(res => {
        const data = res.data
        const pos_integration = data.pos_integration
        const connection_status = data.connection_status
        const square_connection_url = data.square_connection_url
        setSquareConnectionUrl(square_connection_url)
        setPosIntegration(pos_integration)
        setConnectionStatus(connection_status)
      })
      .finally(() => {setLoading(false); getConnectionStatus()})
    
  }



  return (
    <>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
      <MenuHeader title={"POS Connection"}/>
        <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-[32px] gap-5 bg-white py-4`}>
          <div className={`flex flex-col lg:flex-row items-center w-full gap-5 bg-white`}>
            <RestaurantSelect />
          </div>
        </div>
        {loading ? (
                <div className="h-full w-full absolute z-9999 flex flex-col justify-center items-center">
                  <Spinner>{t("Loading")}</Spinner>
                </div>
        ) : 
        <div className={`flex-auto px-[32px] w-full mt-4`}>
          <div style={{height: "100%", display: 'flex', gap: '1%', zIndex: 0}}>
            <div style={{height: "100%", width: "100%", display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
              <div style={{display: 'flex', flexDirection: 'column', gap: "50px", padding: "50px", justifyContent: 'center', backgroundColor: 'white', borderRadius: '10px', width: '60%'}}>
                <p style={{fontSize: "28px", fontWeight: 600}}>{t("yumminn_connection_with")} {POS_LIST[posIntegration] ? POS_LIST[posIntegration]["label"] : "POS"}</p>
                <div style={{width: "100%", display: 'flex', justifyContent: 'center', gap: "30px"}}>
                  <img width="150px" src={POS_LIST[posIntegration] ?  POS_LIST[posIntegration]["icon"] : ""}></img>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{width: "7px", height: "7px", backgroundColor: 'black', borderRadius: "999px"}}></div>
                    <div style={{width: "70px", height: "2px", backgroundColor: 'black', marginRight: "-10px"}}></div>
                    <img style={{position: 'relative'}} src={failedConnection ? warningIcon : connectionStatus == "success" ? green_check : shareIcon}></img>
                    <div style={{width: "70px", height: "2px", backgroundColor: 'black', marginLeft: "-10px"}}></div>
                    <div style={{width: "7px", height: "7px", backgroundColor: 'black', borderRadius: "999px"}}></div>
                  </div>
                  <img  src={logo}></img>
                </div>
                {connectionStatus == "success" ?
                  <p>{t("successfully_connected")}</p>:
                  failedConnection && 
                  <div>
                    <p style={{color: "#2E3236", fontWeight: 600, fontSize: "24px"}}>{t("connection_failed")}</p>
                    <p>{t("try_again_or_contact_an_agent")} <a href="mailto:hola@yumminn.com">{t('here')}</a></p>
                  </div>
                }
                {posIntegration == "square" &&
                  connectionStatus == "success" ? 
                  <p
                    style={{color: "red", fontWeight: 600, textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => setShowDisconnectionPopup(true)}
                  >{t("disconnect")}</p>                  :
                  squareConnectionUrl &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Button
                    variant="primary"
                    label={t("connect")}
                    onClick={() => window.open(squareConnectionUrl,"_self")}
                  ></Button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      <ConfirmDisconnection
          isOpen={showDisconnectionPopup}
          confirm={revokeSquareAuth}
          cancel={() => setShowDisconnectionPopup(false)}
          posIntegration={posIntegration}
        />
    </>
  );
};

export default PosConnection;
