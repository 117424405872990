import styled, { css } from 'styled-components';

const fonts = css`
  & > h2, & > h3 {
    font-weight: 600;
    color: #094553;
  }

  & > h2 {
    font-size: 1.5rem;
  }

  & > h3 {
    font-size: 1.5rem;
  }
  
  & > p {
    font-size: 1rem;

    a {
      color: #094553;
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

const ButtonsFooter = styled.div`
    display: flex; 
    justify-content: space-between; 
    gap: '24px'; 
    margin-top: 20px;
`;

const ButtonsFooterOneButton = styled.div`
    display: flex; 
    justify-content: center; 
    margin-top: 20px;
`;

const ExplanationPricePerSector = styled.i` 
    font-size: 0.875rem;
    background-color: #FFF5DC;
    max-width: 50ch;
    font-weight: 600;
    text-align: center;
    margin: 20px;
    padding: 10px 20px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  header {
    display: flex; 
    justify-content: space-between; 
    gap: 50px;

    ${fonts}
  }
  
`;

const ColumnContainer = styled(RowContainer)`
  flex-direction: column;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: auto!important;
    margin: 0!important;
  }
`;



export {ButtonsFooter, ExplanationPricePerSector, ColumnContainer, ButtonsFooterOneButton}