import React, { useState, useContext, useEffect } from 'react';
import { TicketsSoldContainer } from './TicketsContainers';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import { useTickets } from './useTickets';
import { SelectInput } from 'common/Input';
import { TicketsSoldTable } from './TicketsSoldTable';
import DateRangeSelect from 'components/yumminn/DateRangeSelect';

export const TicketsSold = () => {

  const [page, setPage] = useState(1);
  const [event, setEvent] = useState(null);
  const [selectedTicketPurchase, setSelectedTicketPurchase] = useState(null);
  const [soldTicketList, setSoldTicketList] = useState([]);

  const {
    eventOptions,
    soldTickets,
    mutate,
    isLoading,
    isValidating,
  } = useTickets({page, event});

  useEffect(() => {
    setSoldTicketList(soldTickets);
  }, [soldTickets]);

  useEffect(() => {
    if(!event){
      setEvent(eventOptions[0])
    }
  }, [eventOptions])


  return (
    <TicketsSoldContainer>
      <MenuHeader title={"events"} route_group={'event_tickets'}/>
      <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-[32px] gap-5 bg-white py-4`}>
        <div style={{marginBottom: "20px"}} className={`selectors flex flex-col lg:flex-row items-center w-full gap-5 bg-white`}>
          <RestaurantSelect height={"56px"}/>
          <DateRangeSelect hourFormat={false} defaultRange='this_month' height={"56px"}/>
          <SelectInput
            id='ticket-event'
            label='event'
            placeholder='All'
            value={event}
            options={eventOptions}
            onChange={setEvent}
            isClearable
          />
        </div>
      </div>
      <div className="panels">
        <TicketsSoldTable   
          soldTicketsList={soldTicketList}
          selectedTicketPurchase={selectedTicketPurchase}
          setSelectedTicketPurchase={setSelectedTicketPurchase}
          isLoading={isLoading}
          mutate={mutate}
          page={page}
          setPage={setPage}
          isValidating={isValidating}
        />
      </div>
    </TicketsSoldContainer>
  );
};

