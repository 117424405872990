import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { ProductPopupMockup } from "components/previews/components/ProductPopupMockup";
import { orderAndPay } from "logic/api";
import { toast } from "react-toastify";
import { calculateCents } from "services/payments";
import { TextInput, NumberInput, SelectInput, TextArea, ImageInput } from "common/Input";

export const ProductSpecs = ({
  productDraft,
  editProduct,
  isYumminnProduct,
  categoriesOptions,
  category,
  handleCategoryChange,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(productDraft.name || productDraft.visible_name || productDraft.tpv_name || '');
  const [price, setPrice] = useState(productDraft.tpv_price_cents ? (productDraft.tpv_price_cents / 100).toFixed(2) : '');
  const [description, setDescription] = useState(productDraft.description || productDraft.translated_description || productDraft.tpv_description || "");

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
    editProduct({ description: event.target.value });
  };

  const handleNameChange = event => {
    setName(event.target.value);
    editProduct({ name: event.target.value });
  };

  const handlePriceChange = event => {
    if (!isYumminnProduct) return;

    const value = event.target.value;

    let priceString = value.replace(/[^\d.,]/g, '').replace(',', '.');
    priceString = priceString === '' ? '' : Number(priceString) < 0 ? '' : priceString;
    
    if (priceString.includes('.')) {
      const index = priceString.indexOf('.');
      const decimal = priceString.slice(index + 1).replace('.', '').slice(0, 2);
      priceString = priceString.slice(0, index + 1) + decimal;
    }
    
    const priceCents = Math.floor(calculateCents(priceString));
    
    setPrice(priceString);
    editProduct({ tpv_price_cents: priceCents });
  };

  const uploadImage = ({ url, file }) => {
    editProduct({ image: url, imageFile: file });
  };

  const deleteImage = () => {
    orderAndPay(`/delete_product_image/${productDraft.id}`, {method: "DELETE"}).then(() => {
      editProduct({ image: null });
    }).catch(error => {
      console.log(error);
      toast.error(`${t("Error deleting image")}`);
    });
  };

  return (
    <div className="flex gap-4 w-full overflow-auto py-6">
      <ProductPopupMockup product={productDraft} />
      <div className="flex flex-col gap-4 w-full max-w-[25rem]">
        {!isYumminnProduct &&
          <div className="flex justify-between gap-4">
            <TextInput
              label='id'
              value={productDraft.id}
              disabled
              className='flex-1'
            />
            <TextInput
              label='tpv_name'
              value={productDraft.tpv_name}
              disabled
              className='flex-1'
            />
          </div>
        }
        <TextInput
          label='name'
          value={name}
          placeholder={productDraft.tpv_name || 'Product'}
          onChange={handleNameChange}
        />
        
        {isYumminnProduct &&
          <div className="flex gap-4">
            <NumberInput
              label='Price'
              value={price}
              placeholder='0,00'
              unit={'€'}
              onChange={handlePriceChange}
              onBlur={() => setPrice(Number(price) ? Number(price).toFixed(2) : '')}
              className='flex-1'
            />
            <SelectInput
              label='category'
              isClearable={false}
              hideSelectedOptions
              placeholder='Select one option'
              value={category}
              options={categoriesOptions}
              onChange={handleCategoryChange}
              className='flex-1'
            />
          </div>
        }

        <TextArea
          label='Description'
          value={description}
          placeholder={productDraft.description}
          onChange={handleDescriptionChange}
        />
        <ImageInput
          image={productDraft.image}
          onUpload={uploadImage}
          onDelete={deleteImage}
        />
        <p className="text-base">
          <Trans i18nKey={'img_suggestions'}>
            <b className="font-semibold">Sugerencias:</b> png/jpg ou gif 400x400 pixels, entre 1MB et 2MB.
          </Trans>
        </p>
      </div>
    </div>
  );
};
