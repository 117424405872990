
import { eventUrl } from 'logic/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { parseISO } from 'date-fns';
import './custom-toastify.css';
import { SquareIcon } from 'common/Icon';
import { XIcon } from '@heroicons/react/outline';

export const ObjectTypes = {
    EVENT: {
      ENDPOINT: 'event',
      SUCCESS: 'event_created',
      UPDATE: 'event_updated',
      DELETE: 'event_deleted',
      ERROR_CREATE: 'Error_creating_event',
      ERROR_UPDATE: 'Error_updating_event',
      ERROR_DELETE: 'Error_deleting_event'
    },
    TICKET: {
      ENDPOINT: 'ticket',
      SUCCESS: 'ticket_created',
      UPDATE: 'ticket_updated',
      DELETE: 'ticket_deleted',
      ERROR_CREATE: 'Error_saving_ticket',
      ERROR_UPDATE: 'Error_updating_ticket',
      ERROR_DELETE: 'Error_deleting_ticket'
    }
  };

const CustomToast = ({ closeToast, type, t }) => (
  <div className="custom-toast flex items-center justify-center gap-4 w-[305px]">
    <SquareIcon>🎭</SquareIcon>
    <div className='flex flex-col'>
      <strong className='whitespace-nowrap'>{type === 'event' ? t('desactivated_event') : t('desactivated_ticket')}</strong>
      <div>{t('not_visible_at_webapp')}</div>
    </div>
    <button style={{margin: "0 15px", outline: 'none'}} onClick={closeToast}>
      <XIcon className={`w-[30px] text-gray-700 cursor-pointer`}/>
    </button>
  </div>
);

const notify = (type, t) => {
  toast(<CustomToast type={type} t={t}/>, {
    icon: '🎭', 
    autoClose: 5000,
    position: toast.POSITION.TOP_RIGHT,
    closeButton: false,
    hideProgressBar: true,
  });
};

export const setDate = (period) => {
  if (period) {
    const date = moment(parseISO(period));
    return date._d;
  }
  return null;
};

export const getDefaultDate = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1); 
  const tomorrow = new Date(now.setHours(12, 0, 0, 0))
  return tomorrow;
};

export const saveEventImage = async ({id, file, type, t}) => {
  let fd = new FormData();
  
  fd.append("image", file);
  fd.append("event_id", id);
  fd.append("type", type);

  return await eventUrl(`/save_event_image`, {
    method: "PATCH",
    "Content-Type": "multipart/form-data",
    data: fd,
  }).then(res => {
    return res;
  }).catch(error => {
    console.log(error);
    toast.error(`${t("Error_saving_image")}`);
  });
};

export const saveTicketImage = async ({id, file, type, t}) => {
  let fd = new FormData();
  
  fd.append("image", file);
  fd.append("ticket_id", id);
  fd.append("type", type);

  return await eventUrl(`/save_ticket_image`, {
    method: "PATCH",
    "Content-Type": "multipart/form-data",
    data: fd,
  }).then(res => {
    return res;
  }).catch(error => {
    console.log(error);
    toast.error(`${t("Error_saving_image")}`);
  });
};

export const postEventOrTicket = async ({ objectType, object, restaurant, t }) => {
  if (objectType && object) {
      const endpoint = `${objectType.ENDPOINT}`;
      object.restaurant = restaurant

      if (!object.datetime){
        object.datetime = getDefaultDate();
      }

      return await eventUrl.post(endpoint, object)
        .then((response) => {
          if (object.imageFile) {
            if(objectType.ENDPOINT === 'event'){
              saveEventImage({id:response?.data.id, file: object.imageFile, type: objectType.ENDPOINT, t});
            } else {
              saveTicketImage({id:response?.data.id, file: object.imageFile, type: objectType.ENDPOINT, t});
            }
          }
          toast.success(`${t(objectType.SUCCESS)}`) 
          
          return response;
        })
        .catch(error => {
          toast.error(`${t(objectType.ERROR)}`)
          console.log("🚀 postEventOrTicket error:", error)
          throw error;
        });
    }
}

export const patchEventOrTicket = async ({ objectType, object, restaurant, t }) => {
    if(!object) return null;
    object.restaurant = restaurant

    if (objectType) {
      return await eventUrl.patch(objectType.ENDPOINT, object)
        .then((response) => {
          if (object.imageFile) {
            if(objectType.ENDPOINT === 'event'){
              saveEventImage({id:object.id, file: object.imageFile, type: objectType.ENDPOINT, t});
            } else {
              saveTicketImage({id:object.id, file: object.imageFile, type: objectType.ENDPOINT, t});
            }
          }
          if(!object.active){
            notify(objectType.ENDPOINT, t);
          } else {
            toast.success(`${t(objectType.UPDATE)}`);
          }
          return response;
        })
        .catch(error => {
          toast.error(error.response.data.message ? t(error.response.data.message) : `${t(objectType.ERROR_UPDATE)}`);
          console.log("🚀 patchEventOrTicket error:", error)
        });
    }

   
}
  
export const deleteEventOrTicket = async ({ objectType, object, mutate, t }) => {
    if(!object) return null;

    if (objectType) {
      const body = {
        'id': object.id
      }
  
      return await eventUrl.delete(objectType.ENDPOINT, { data: body })
        .then(() => {
          toast.success(`${t(objectType.DELETE)}`);
          mutate();
        })
        .catch(error => {
          toast.error(`${t(objectType.ERROR_DELETE)}`);
          console.log("🚀 deleteEventOrTicket error:", error)
        });
    }
}

export const saveEventOrTicket = async ({
  restaurant,
  eventOrTicket,
  eventToEdit,
  ticketToEdit,
  object,
  setSelectedEventId,
  t
}) => {
  let newEventId;
  try {
    if (eventToEdit || ticketToEdit) { 
      const response = await patchEventOrTicket({ objectType: ObjectTypes[eventOrTicket], object, restaurant, t });  
      newEventId = await response?.data.ticket_id || await response?.data.id;
      object.id = newEventId;
      setSelectedEventId(newEventId);
    }  else {
      const response = await postEventOrTicket({ objectType: ObjectTypes[eventOrTicket], object, restaurant, t });
      newEventId = await response?.data.ticket_id || await response?.data.id;
      object.id = newEventId;
      setSelectedEventId(newEventId);
    }
   
  } catch (error) {
    console.log("🚀saveEventOrTicket error:", error);
    throw error;
  }
};


export const patchEventStock = async ( object, t, setLoading, setModifiedStocks, mutate ) => {
  if(!object) return null;
  const ENDPOINT = '/change_available_stock'
  const ERROR_UPDATE = 'Error_updating_stock'
  const SUCCESS_UPDATE = 'Ticket_stock_updated'
  setLoading(true)

  return await eventUrl.patch(ENDPOINT, object)
    .then((response) => {
      setLoading(false)
      setModifiedStocks([])
      toast.success(`${t(SUCCESS_UPDATE)}`);
      mutate();
      return response;
    })
    .catch(error => {
      toast.error(`${t(ERROR_UPDATE)}`);
      setLoading(false)
      console.log("🚀 patchEventStock error:", error)
  });
}

