import { PhotographIcon, SelectorIcon } from "@heroicons/react/outline";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { allergensList } from "data/allergens";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import pasta from '../../../img/pasta.svg';
import { DiscountBadge } from "./DiscountBadge";
import { calculateEuros } from "services/payments";
import { getImageUrl } from "logic/functions";
import { mockThemes } from "../constants";
import { generatePalette } from "styles/colors";

export const RestaurantGroupCardMockup = ({restaurantCard, draftTitle, draftSubtitle, draftImage, themeName }) => {
  const { t } = useTranslation();

  const theme = {
    ...mockThemes[themeName],
  };

  const title = draftTitle ? draftTitle : restaurantCard?.title ? restaurantCard.title : restaurantCard.restaurant.name
  const subtitle = draftSubtitle ? draftSubtitle : restaurantCard?.description ? restaurantCard.description : null

  return (
      <div style={{backgroundColor: theme.background_rised, color: theme.text}}  className="flex flex-col rounded-lg overflow-hidden w-[250px] h-full shadow border-2 border-gray-100 rounded-lg">
        {draftImage?.url &&
          <img src={draftImage?.url} className="object-cover h-[160px] w-full" alt='card_product' />
        }
        <div className="py-2">
          <p style={{fontSize: "17px", margin: "10px", fontWeight: 600}}>{title}</p>
          {subtitle && 
            <p style={{fontSize: "14px", margin: "0px 10px 10px 10px"}}>{subtitle}</p>
          }
        </div>
      </div>
  );
};
