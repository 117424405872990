import { Popup } from 'common/Popup';
import styled from 'styled-components';

export const ProductHighlightContainer = styled.div`
  .buttons {
    display: flex;
    gap: 24px;
  }
`;

export const ProductHighlightTableContainer = styled.div`
  width: 90%;
  align-self: center;
  margin-top: 32px;
  opacity: 1;
  background-color: #FFF;
  border-radius: 1rem;
  padding: 8px;
  width: 100%;
  border: 1px solid #E2E2E2;

  &:last-child {
    margin-bottom: 32px;
  }

  &.loading {
    opacity: 0.75;
    cursor: wait;
  }

  header {
    font-size: 1.125rem;
    font-weight: 500;
    text-align: center;
    color: #222222;
    background-color: #FCFCFC;
    border-bottom: 1px solid #E2E2E2;
    border-radius: calc(1rem - 8px) calc(1rem - 8px) 0 0;
    width: 100%;
    padding: 16px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  table {
    width: 100%;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #FFF;
    box-shadow: 0 1px 0 0 #E2E2E2;
  }

  th {
    font-size: 1.125rem;
    font-weight: 500;
    padding: 16px;

    h3 {
      line-height: 1.3;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  tbody {
    width: 100%;
    min-width: 800px;

    tr:not(:last-child) {
      border-bottom: 1px solid #E2E2E2;
      background-color: #FFF;
    }

    td {
      font-size: 1rem;
      font-weight: 500;

      &.col_name, &.col_content, &.col_schedules {
        padding: 16px;
      }
      
      &.col_type, &.col_status, &.col_icon {
        padding: 0 16px;
        
        & > * {
          margin: auto;
        }
      }

      &.col_icon {
        color: #094553;
        width: 64px;

        &:last-child svg {
          fill: #FFF0BB;
        }
      }

      p::first-letter {
        text-transform: capitalize;
      }
    }

    .type {
      padding: 4px 8px;
      border-radius: 6px;

      &.commercial {
        background-color: #CCE8FB;
      }

      &.individual {
        background-color: #FFF0BB;
      }

      &.multi {
        background-color: #EDD0FF;
      }
    }
  }
`;

export const CustomGroupPopup = styled(Popup)`
  > div {
    min-width: 54rem;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.3;
    }

    & > div {
      margin-left: auto;
    }
  }

  .product_hihglight_types {
    display: flex;
    justify-content: center;
    gap: 32px;
    padding-top: 32px;
    margin: 24px 0;
    border-top: 1px solid #DDD;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: min-content;
      gap: 16px;
      padding: 32px;
      border: 1px solid #DDD;
      border-radius: 24px;
      position: relative;
      transition: 0.15s ease-in-out;
      transition-property: color, background-color, border-color, translate, box-shadow;

      &.selected {
        border-color: #094553;
        background-color: #F7FFFC;

        svg {
          opacity: 1;
        }
      }

      &:hover, &:focus-visible {
        translate: 0 -4px;
        box-shadow: 0 4px 0 #0001;
      }

      &:focus {
        outline: none;
      }
      
      &:focus-visible {
        outline: 2px solid #094553;
        outline-offset: 2px;
      }

      &:active {
        translate: 0 4px;
        box-shadow: 0 0 0 #0001, inset 0 4px 0 #0001;
      }
    }

    img {
      min-height: 215px;
      min-width: 158px;
      object-fit: contain;
    }

    span {
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
      line-height: 1;
    }

    svg {
      position: absolute;
      top: 8px;
      left: 8px;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    border-top: 1px solid #DDD;
    padding-top: 24px;
    margin-top: 32px;

    &:not(:first-of-type) {
      display: none;
    }
  }

  .schedule {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin: 24px;
  
    & > div {
      min-width: 24rem;
    }
  }
`;

export const BaseCustomGroupSettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  margin: 32px;

  & > div:first-child {
    scale: 1.3;
    margin: 42px 34px;
  }

  & > div:last-child {
    max-width: 26rem;
    flex: 1;

    h6 {
      font-size: 1rem;
      font-weight: 300;
      font-weight: 400;
  
      &::first-letter {
        text-transform: capitalize;
      }
      
      b {
        font-weight: 500;
      }
    }
  }
`;

export const CustomGroupPositionContainer = styled(BaseCustomGroupSettingsContainer)`
  & > div:last-child {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.125rem;
      font-weight: 400;
    }

    > p {
      margin: 0;
    }
    
    > ul {
      list-style: disc;
      padding-left: 16px;
    }

    b {
      font-weight: 600;
    }
  }

  .select_input {
    margin: 16px 0;
  }

  .image_input {
    margin-top: auto;
  }
`;

export const CustomGroupProductsContainer = styled(BaseCustomGroupSettingsContainer)`
  & > div:last-child {
    display: flex;
    flex-direction: column;

    h6 {
      font-size: 1rem;
      font-weight: 300;
      font-weight: 400;
      margin: 0 0 8px;

      &::first-letter {
        text-transform: capitalize;
      }
    }
    
    p {
      margin: 16px 0;
    }

    b {
      font-weight: 600;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1 1 0;
      overflow-y: auto;
      list-style: none;
    }

    li {
      display: flex;
      align-items: center;
      gap: 16px;
      background-color: #F8F8F8;
      border-radius: 0.5rem;
      padding: 8px 16px;
      user-select: none;

      svg {
        height: 0.875rem;
        width: 0.875rem;
      }

      button {
        margin-left: auto;
      }
    }
  }
`;

export const CustomGroupTextsContainer = styled(BaseCustomGroupSettingsContainer)`
  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const CustomGroupSchedulesContainer = styled.div`
  margin: 32px;
  min-height: 22.75rem;

  h4 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
  }
  
  ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 50%;
  }
  
  li {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 1rem;

    & > div {
      border-radius: 10px;
      border: 1px solid #D9D9D9;
      padding: 16px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  h6 {
    font-size: 1rem;
    font-weight: 300;
    font-weight: 400;
    text-wrap: balance;
    max-width: 30ch;
    margin: 0 0 8px;

    &::first-letter {
      text-transform: capitalize;
    }
  }
  b {
      font-weight: 600;
    }

  .empty_state {
    margin: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    
    strong, p {
      max-width: 32ch;
      text-align: center;
    }
    
    strong {
      font-size: 1rem;
      font-weight: 600;
    }

    p {
      margin: 0;
    }
  }
`;

export const CustomGroupCustomizationContainer = styled(BaseCustomGroupSettingsContainer)`
  margin: 32px 0;

  & > div:last-child {
    max-width: unset;
    width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .input_wrapper {
    max-width: 26rem;
  }

  .background_colors {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
`;

export const CustomGroupFinishedContainer = styled(BaseCustomGroupSettingsContainer)`
  & > div:first-child {
    scale: 1;
    margin: 0;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.5rem;
      font-weight: 600;

      svg {
        height: 3.5rem;
        width: 3.5rem;
      }
    }
  
    p {
      font-size: 1.125rem;
      text-align: center;
      margin: 24px 0;
    }
  
    b {
      font-weight: 600;
    }
  }
`;