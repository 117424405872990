import React from 'react';
import MultiTypeCustomGroup from "../../../../img/MultiTypeCustomGroup.svg";
import IndividualTypeCustomGroup from "../../../../img/IndividualCustomGroupType.svg";
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { Icon, IconType } from "common/Icon";
import { CustomGroupPopup } from './ProductHighlightContainers';
import { SameSizeButtonGroup } from 'common/SameSizeButtonGroup';

export const ChooseProductHihglightType = ({type, editCustomGroup, next, close}) => {
  const { t } = useTranslation();

  return (
    <CustomGroupPopup isOpen>
      <header>
        <h2>{t('choose_product_hihglight_title')}</h2>
      </header>
      <div className='product_hihglight_types'>
        <button
          className={type === 'individual' ? 'selected' : ''}
          onClick={() => editCustomGroup({custom_group_type: 'individual'})}
        >
          <img src={IndividualTypeCustomGroup} alt={t('one_product')}/>
          <span>{t('one_product')}</span>
          <Icon type={IconType.VERIFIED}/>
        </button>
        <button
          className={type === 'multi' ? 'selected' : ''}
          onClick={() => editCustomGroup({custom_group_type: 'multi'})}
        >
          <img src={MultiTypeCustomGroup} alt={t('multiple_products')}/>
          <span>{t('multiple_products')}</span>
          <Icon type={IconType.VERIFIED}/>
        </button>
      </div>
      <SameSizeButtonGroup as='footer'>
        <Button
          label='cancel'
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={close}
        />
        <Button
          label='create'
          onClick={next}
          disabled={!type}
        />
      </SameSizeButtonGroup>
    </CustomGroupPopup>
  );
};
