import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTextColor } from 'styles/colors';
import { ButtonMockup, FooterMockup, HeaderMockup } from '../components';
import { mockThemes } from '../constants';

export const DeliveryTakeawayHomePreview = ({ themeName, colors, logo, homeImage, title }) => {
  const { t } = useTranslation();

  const logoRef = useRef(null);
  const [ratio, setRatio] = useState(0);

  const theme = {
    ...mockThemes[themeName],
  };

  const getRatio = () => {
    const logo = logoRef.current;
    const logoHeight = logo?.naturalHeight;
    const logoWidth = logo?.naturalWidth;
    setRatio(logoWidth/logoHeight);
  };

  const getContainerStyles = (ratio) => ({
    height: ratio >= 1 ? '30%' : '50%',
    width: ratio >= 1 ? '70%' : '30%',
    maxHeight: '100%',
    maxWidth: ratio > 1 ? '100%' : '50%',
    objectFit: 'contain',
    display: 'flex',
  })

  const getLogoStyles = (ratio) => ({
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: ratio > 1 ? '100%' : '75%',
    objectFit: 'contain',
    borderRadius: '4px',
    margin: 'auto',
  })

  return (
    <div className='flex flex-col h-full'>
      <HeaderMockup themeName={themeName}/>
      <div className='flex-1 bg-cover bg-center relative' style={{backgroundImage: `url(${homeImage})`, backgroundColor: theme.background}}>
        <div
          className={`absolute inset-0 h-full w-full transition-opacity opacity-100`}
          style={{backgroundImage: 'linear-gradient(0deg, #0003, #000A)'}}
        />
        <div className='absolute inset-0 h-full w-full flex justify-center items-center pb-9'>
          {logo ?
            <div className='transition-all' style={getContainerStyles(ratio)}>
              <img src={logo} ref={logoRef} onLoad={getRatio} className='transition-all' style={getLogoStyles(ratio)}/>
            </div>
            :
            <div className='text-gray-300 border-2 border-current border-dashed rounded-lg py-4 px-2 filter drop-shadow'>
              {t('your_logo')}
            </div>
          }
        </div>
        <div
          style={{bottom: 0}}
          className='absolute flex flex-col gap-2 p-3 w-full mt-[-65px]'
        >
          {title && <p style={{color: theme.text, fontSize: "9px", fontWeight: 500, textAlign: 'center'}}>{title}</p>}
          <ButtonMockup label={t('takeaway')} theme={theme} style={{backgroundColor: colors.takeaway, color: getTextColor(colors.takeaway), borderColor: "rgba(33, 33, 33, 0.25)"}}/>
          <ButtonMockup label={t('delivery')} theme={theme} style={{backgroundColor: colors.delivery, color: getTextColor(colors.delivery), borderColor: "rgba(33, 33, 33, 0.25)"}}/>
        </div>
      </div>
      <FooterMockup theme={theme}/>
    </div>
  );
};
